import { Component, OnInit, Inject } from '@angular/core';
import {
    MatDialogRef, MAT_DIALOG_DATA, MAT_DATE_LOCALE,
    DateAdapter, MAT_DATE_FORMATS, MatSnackBar
} from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMAT } from 'src/environments/environment';


@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.less'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT }
    ]
})
export class ConfirmationDialogComponent implements OnInit {

    text;
    customInputs = [];
    result = {};
    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (typeof data === 'string') {
            this.text = data;
        } else {
            this.text = data.message;
            if (data.inputs !== undefined) {
                this.customInputs = data.inputs;
            }
        }
    }

    onNoClick(): void {
        // TODO CHECK FERMETURE
        this.dialogRef.close();
    }

    confirm(): void {
        let isValid = true;
        this.customInputs.forEach(input => {
            if (input.required === true && this.result[input.id] === undefined) {
                isValid = false;
            }
        });
        if (isValid) {
            this.dialogRef.close(this.result);
        } else {
            this.snackBar.open('La saisie n\'est pas valide, veuillez recommencer', null, {
                duration: 3000,
                panelClass: 'error'
            });
        }
    }

    ngOnInit() {
    }

}
