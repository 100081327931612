import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { FaqComponent } from './faq/faq.component';
import { MessagingComponent } from './messaging/messaging.component';
import { MailThemeCorrespondenceComponent } from './mail-theme-correspondence/mail-theme-correspondence.component';
import { DistributionPointComponent } from './distribution-point/distribution-point.component';
import { DistributionPointProductComponent } from './distribution-point-product/distribution-point-product.component';
import { DistributionPointFunctionnalityComponent } from './distribution-point-functionnality/distribution-point-functionnality.component';
import { HistoryExtracationComponent } from './history-extracation/history-extracation.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { SalesJournalComponent } from './sales-journal/sales-journal.component';
import { ReceiptReissueComponent } from './receipt-reissue/receipt-reissue.component';
import { SupportManagementComponent } from './support-management/support-management.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
    { path: '', component: LoginComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'faq', component: FaqComponent, canActivate: [AuthGuard] },
    { path: 'messaging', component: MessagingComponent, canActivate: [AuthGuard] },
    { path: 'distribution-point', component: DistributionPointComponent, canActivate: [AuthGuard] },
    { path: 'distribution-point-product', component: DistributionPointProductComponent, canActivate: [AuthGuard] },
    { path: 'distribution-point-functionnality', component: DistributionPointFunctionnalityComponent, canActivate: [AuthGuard] },
    { path: 'mail-theme-correspondence', component: MailThemeCorrespondenceComponent, canActivate: [AuthGuard] },
    { path: 'history-extraction', component: HistoryExtracationComponent, canActivate: [AuthGuard] },
    { path: 'general-settings', component: GeneralSettingsComponent, canActivate: [AuthGuard] },
    { path: 'user-management', component: UserManagementComponent, canActivate: [AuthGuard] },
    { path: 'sales-journal', component: SalesJournalComponent, canActivate: [AuthGuard] },
    { path: 'receipt-reissue', component: ReceiptReissueComponent, canActivate: [AuthGuard] },
    { path: 'support-management', component: SupportManagementComponent, canActivate: [AuthGuard] },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
