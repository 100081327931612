import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DistributionPointDatabaseService, FlatNode, NestedNode } from 'src/app/_service/distribution-point-database.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material';
import { NodeJsInteractionService } from 'src/app/_service/nodejs-interaction.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
    selector: 'app-distribution-point-dialog',
    templateUrl: './distribution-point-dialog.component.html',
    styleUrls: ['./distribution-point-dialog.component.less']
})
export class DistributionPointDialogComponent implements OnInit, OnDestroy {

    constructor(
        public dialogRef: MatDialogRef<DistributionPointDialogComponent>,
        private nodeJsInteractionService: NodeJsInteractionService,
        public database: DistributionPointDatabaseService,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren as any);
        this.treeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.database.setNestedNodeKey('ID');
        this.database.setNestedNodeLibelle(this.nestedNodeLibelle);
        this.database.setFlatNodeKey('ID');
        this.database.setFlatNodeNestedKey('Idtypepointdistribution');
        this.database.setFlatNodeLibelle(this.flatNodeLibelle);

        this.database.dataChange.subscribe(result => {
            this.dataSource.data = result;
        });
    }

    flatNodeMap = new Map<FlatNode, NestedNode>();
    nestedNodeMap = new Map<NestedNode, FlatNode>();
    selectedParent: FlatNode | null = null;
    treeControl: FlatTreeControl<FlatNode>;
    treeFlattener: MatTreeFlattener<NestedNode, FlatNode>;
    dataSource: MatTreeFlatDataSource<NestedNode, FlatNode>;
    checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);


    private nestedNodeLibelle = ['Libelle', 'Canal'];
    private flatNodeLibelle = ['Libelle', 'NomResponsable'];

    private getDistributionPointTypeListObservable;
    private getDistributionPointListObservable;
    getLevel = (node: FlatNode) => node.level;
    isExpandable = (node: FlatNode) => node.expandable;
    getChildren = (node: NestedNode): FlatNode[] => node.children;
    getChildrenNumber = (node: any): any => this.treeControl.getDescendants(node).length;
    hasChild = (_: number, nodeData: FlatNode) => nodeData.expandable;
    hasNoContent = (_: number, nodeData: FlatNode) => nodeData.Libelle === '';
    transformer = (node: NestedNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.Libelle === node.Libelle
            ? existingNode
            : new FlatNode();
        flatNode.Libelle = node.Libelle;
        flatNode.ID = node.ID;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }

    ngOnInit() {

        this.nodeJsInteractionService.getDistributionPointTypeList();
        this.getDistributionPointTypeListObservable = this.nodeJsInteractionService
            .getDistributionPointTypeListObservable.subscribe(data => {
                const response = data as any;
                const typePointDistributionList = (Array.isArray(response.TypePointDistribution)) ?
                    response.TypePointDistribution : [response.TypePointDistribution];
                this.database.setNestedNodeList(typePointDistributionList.filter(e => e.TypePoint === 'FrontOffice'));
                this.nodeJsInteractionService.getDistributionPointList();
            });

        this.getDistributionPointListObservable = this.nodeJsInteractionService
            .getDistributionPointListObservable.subscribe(data => {
                const response = data as any;
                const pointDistributionList = (Array.isArray(response.PointDistribution)) ?
                    response.PointDistribution : [response.PointDistribution];
                const flatNodeList = pointDistributionList.filter(point =>
                    this.database.getNestedNodeList().some((type) => type.ID === point.Idtypepointdistribution)
                );
                const filterNestedNodeList = this.database.getNestedNodeList().filter(type =>
                    flatNodeList.some((point) => type.ID === point.Idtypepointdistribution)
                );
                this.database.setNestedNodeList(filterNestedNodeList);
                this.database.setFlatNodeList(flatNodeList);
                this.database.initialize();
            });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: FlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        let descAllSelected = null;
        if (descendants) {
            descAllSelected = descendants.every(child =>
                this.checklistSelection.isSelected(child)
            );
        }
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: FlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggle(node: FlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        this.checkAllParentsSelection(node);
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggle(node: FlatNode): void {
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: FlatNode): void {
        let parent: FlatNode | null = this.getParentNode(node);
        while (parent) {
            // this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }
    /* Get the parent node of a node */
    getParentNode(node: FlatNode): FlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    saveSelectionnedSubjects() {
        this.dialogRef.close(this.checklistSelection.selected);
    }

    ngOnDestroy() {
        this.getDistributionPointTypeListObservable.unsubscribe();
        this.getDistributionPointListObservable.unsubscribe();
    }
}

