import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER, Injectable } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginComponent } from "./login/login.component";
import { HomeComponent } from "./home/home.component";
import {
  FaqComponent,
  CreateCategoryDialogComponent,
} from "./faq/faq.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatButtonModule,
  MatInputModule,
  MatCheckboxModule,
  MatGridListModule,
  MatIconModule,
  MatTabsModule,
  MatRadioModule,
  MatSliderModule,
  MatCardModule,
  MatProgressBarModule,
  MatDialogModule,
  MatMenuModule,
  MatBadgeModule,
  MatDividerModule,
  MatOptionModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatExpansionModule,
  MatListModule,
  MatTooltipModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatTreeModule,
  MatTableModule,
  MatPaginatorModule,
  MatAutocompleteModule,
  MatSortModule,
} from "@angular/material";
import { SocketIoModule } from "ngx-socket-io";

import { HttpClientModule, HttpClient } from "@angular/common/http";
import { AngularEditorModule } from "@kolkov/angular-editor";
import {
  MessagingComponent,
  ReadMessageComponent,
} from "./messaging/messaging.component";
import {
  MailThemeCorrespondenceComponent,
  MessageThematicCreateDialogComponent,
} from "./mail-theme-correspondence/mail-theme-correspondence.component";
import { ProgressContainerComponent } from "./_ui/progress-container/progress-container.component";
import { ConfirmationDialogComponent } from "./_ui/confirmation-dialog/confirmation-dialog.component";
import {
  DistributionPointComponent,
  DistributionPointTypeCreateDialogComponent,
  DistributionPointCreateDialogComponent,
  WorkstationCreateDialogComponent,
} from "./distribution-point/distribution-point.component";
import { DistributionPointProductComponent } from "./distribution-point-product/distribution-point-product.component";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { DistributionPointFunctionnalityComponent } from "./distribution-point-functionnality/distribution-point-functionnality.component";
import { HistoryExtracationComponent } from "./history-extracation/history-extracation.component";
import { DistributionPointDialogComponent } from "./_ui/distribution-point-dialog/distribution-point-dialog.component";
import {
  GeneralSettingsComponent,
  GeneralSettingsConfigurationCreatePosteDialogComponent,
  GeneralSettingsConfigurationCreatePointDialogComponent,
  GeneralSettingsConfigurationSystemListingDialogComponent,
  GeneralSettingsApiManagementFormDialogComponent,
} from "./general-settings/general-settings.component";
import { UserDialogComponent } from "./_ui/user-dialog/user-dialog.component";
import { MenuComponent } from "./menu/menu.component";
import {
  UserManagementComponent,
  UserCreateDialogComponent,
} from "./user-management/user-management.component";
import { SalesJournalComponent } from "./sales-journal/sales-journal.component";
import { ReceiptReissueComponent } from "./receipt-reissue/receipt-reissue.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { AppConfigService } from "./_service/app-config.service";
import {
  SupportManagementComponent,
  SupportCreateDialogComponent,
} from "./support-management/support-management.component";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { RightlessDialogComponent } from "./_ui/rightless-dialog/rightless-dialog.component";

const appInitializerFn = (
  appConfig: AppConfigService,
  keycloak: KeycloakService
) => {
  return () => {
    return Promise.all([
      appConfig.loadAppConfig().then(() =>
        keycloak.init({
          config: {
            url: appConfig.config.iamUrl,
            realm: appConfig.config.iamRealm,
            clientId: appConfig.config.iamClient,
          },
        })
      ),
    ]);
  };
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    FaqComponent,
    MessagingComponent,
    MailThemeCorrespondenceComponent,
    ProgressContainerComponent,
    CreateCategoryDialogComponent,
    ConfirmationDialogComponent,
    DistributionPointComponent,
    DistributionPointProductComponent,
    DistributionPointTypeCreateDialogComponent,
    DistributionPointCreateDialogComponent,
    WorkstationCreateDialogComponent,
    DistributionPointFunctionnalityComponent,
    HistoryExtracationComponent,
    DistributionPointDialogComponent,
    GeneralSettingsComponent,
    GeneralSettingsConfigurationCreatePosteDialogComponent,
    GeneralSettingsConfigurationCreatePointDialogComponent,
    GeneralSettingsConfigurationSystemListingDialogComponent,
    GeneralSettingsApiManagementFormDialogComponent,
    ReadMessageComponent,
    MessageThematicCreateDialogComponent,
    UserDialogComponent,
    MenuComponent,
    UserManagementComponent,
    UserCreateDialogComponent,
    SalesJournalComponent,
    ReceiptReissueComponent,
    SupportManagementComponent,
    SupportCreateDialogComponent,
    RightlessDialogComponent,
  ],
  imports: [
    KeycloakAngularModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatBadgeModule,
    MatMenuModule,
    MatDividerModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatGridListModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatRadioModule,
    MatSliderModule,
    MatCardModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatExpansionModule,
    MatListModule,
    MatTooltipModule,
    MatTreeModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    HttpClientModule,
    AngularEditorModule,
    NgxMaterialTimepickerModule,
    SocketIoModule.forRoot(null),
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService, KeycloakService, HttpClient],
    },
  ],
  entryComponents: [
    ProgressContainerComponent,
    MailThemeCorrespondenceComponent,
    CreateCategoryDialogComponent,
    ConfirmationDialogComponent,
    DistributionPointTypeCreateDialogComponent,
    DistributionPointCreateDialogComponent,
    WorkstationCreateDialogComponent,
    DistributionPointDialogComponent,
    ReadMessageComponent,
    GeneralSettingsConfigurationCreatePosteDialogComponent,
    GeneralSettingsConfigurationCreatePointDialogComponent,
    GeneralSettingsConfigurationSystemListingDialogComponent,
    GeneralSettingsApiManagementFormDialogComponent,
    MessageThematicCreateDialogComponent,
    UserDialogComponent,
    UserCreateDialogComponent,
    SupportCreateDialogComponent,
    RightlessDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
