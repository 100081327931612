import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { TitleService } from '../_service/title.service';
import { NodeJsInteractionService } from '../_service/nodejs-interaction.service';
import { Overlay } from '@angular/cdk/overlay';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ComponentPortal } from '@angular/cdk/portal';
import { ProgressContainerComponent } from '../_ui/progress-container/progress-container.component';
import { ConfirmationDialogComponent } from '../_ui/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'support-create-dialog',
    templateUrl: 'support-create-dialog.html'
})
export class SupportCreateDialogComponent {
    form: FormGroup;
    initCodeOperateur;
    initCodeTarif;
    initCodeSupport;
    constructor(
        public fb: FormBuilder,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<SupportCreateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.initCodeOperateur = data.CodeOperateur;
        this.initCodeTarif = data.CodeTarif;
        this.initCodeSupport = data.CodeSupport;
        this.form = fb.group({
            CodeOperateur: [data.CodeOperateur, Validators.required],
            CodeTarif: [data.CodeTarif, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
            LibelleProduit: [data.LibelleProduit, Validators.required],
            CodeSupport: [data.CodeSupport, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
            LibelleSupport: [data.LibelleSupport, Validators.required],
            TarifSupport: [data.TarifSupport, [Validators.required, Validators.pattern(/^[0-9]+(.[0-9]{0,2})?$/)]],
            TauxTva: [data.TauxTva, Validators.required],
        });
    }
    save() {

        let errorMsg = '';
        Object.keys(this.form.controls).forEach(key => {
            if (this.form.controls[key].invalid) {
                errorMsg += 'Le champs \'' + key + '\' n\'est pas valide. ';
            }
        });

        if (this.data.supportList.find(
            e => e.CodeOperateur === this.form.value.CodeOperateur &&
                e.CodeOperateur !== this.initCodeOperateur &&
                e.CodeTarif === this.form.value.CodeTarif &&
                e.CodeTarif !== this.initCodeTarif) !== undefined) {
            this.snackBar.open('Un couple code opérateur / code tarif existe déjà avec ces valeurs', null, {
                duration: 4000,
                panelClass: 'error'
            });
            return;
        }
        if (this.data.supportList.find(e => e.CodeSupport === this.form.value.CodeSupport
            && e.CodeSupport !== this.initCodeSupport) !== undefined) {
            this.snackBar.open('Ce code support existe déjà dans la liste des supports', null, {
                duration: 4000,
                panelClass: 'error'
            });
            return;
        }

        if (this.form.invalid) {
            this.snackBar.open(errorMsg, null, {
                duration: 4000,
                panelClass: 'error'
            });
        } else {
            this.dialogRef.close(this.form.value);
        }
    }
    onNoClick(): void { this.dialogRef.close(); }
}

@Component({
    selector: 'app-support-management',
    templateUrl: './support-management.component.html',
    styleUrls: ['./support-management.component.less']
})
export class SupportManagementComponent implements OnInit, OnDestroy {

    constructor(
        private titleService: TitleService,
        private nodeJsInteractionService: NodeJsInteractionService,
        private dialog: MatDialog,
        private overlay: Overlay,
        private snackBar: MatSnackBar,
        private router: Router
    ) {
        this.titleService.setTitle('Gestion des supports');
        this.titleService.setEcranNumber(12);
    }

    private overlayRef;
    supportList = [];
    private supportToDelete = null;

    private getSupportListObservable;
    private supportCreateObservable;
    private supportUpdateObservable;
    private supportDeleteObservable;
    attachOverlay() { this.overlayRef.attach(new ComponentPortal(ProgressContainerComponent)); }
    detachOverlay() { setTimeout(() => { this.overlayRef.detach(); }, 200); }
    ngOnInit() {
        // if (!this.nodeJsInteractionService.functionnalityAccess.checkAccess('DISTRIBUTION_GERER_POINTS_DISTRIBUTION')) {
        //     this.router.navigate(['/home']);
        // }

        this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });

        this.nodeJsInteractionService.getSupportList();
        this.getSupportListObservable = this.nodeJsInteractionService.getSupportListObservable.subscribe(data => {
            const response = data as any;
            this.supportList = response.Support;
        });

        this.supportCreateObservable = this.nodeJsInteractionService.supportCreateObservable.subscribe(data => {
            const response = data as any;
            if (response.CodeErreur !== 0) {
                this.snackBar.open('Une erreur s\'est produite lors de la création du support.', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
                return false;
            }
            this.snackBar.open('Le support a bien été créé.', null, {
                duration: 3000,
                panelClass: 'success'
            });

            this.supportList.push(response);
        });

        this.supportUpdateObservable = this.nodeJsInteractionService.supportUpdateObservable.subscribe(data => {
            const response = data as any;
            if (response.CodeErreur !== 0) {
                this.snackBar.open('Une erreur s\'est produite lors de la mise à jour du support.', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
                return false;
            }
            this.snackBar.open('Le support a bien été mis à jour.', null, {
                duration: 3000,
                panelClass: 'success'
            });

            const indexOf = this.supportList.findIndex(e => e.ID === response.ID);
            this.supportList[indexOf] = response;
        });

        this.supportDeleteObservable = this.nodeJsInteractionService.supportDeleteObservable.subscribe(data => {
            const response = data as any;
            if (!response) {
                this.snackBar.open('Une erreur s\'est produite lors de la suppression du support.', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
                this.supportToDelete = null;
                return false;
            }
            this.snackBar.open('Le support à bien été supprimé.', null, {
                duration: 3000,
                panelClass: 'success'
            });

            this.supportList = this.supportList.filter(e => e.ID !== this.supportToDelete.ID);
            this.supportToDelete = null;
        });
    }

    ngOnDestroy() {
        this.getSupportListObservable.unsubscribe();
        this.supportCreateObservable.unsubscribe();
        this.supportUpdateObservable.unsubscribe();
        this.supportDeleteObservable.unsubscribe();
    }

    openDialogSupport(support): void {
        if (this.supportToDelete !== null) {
            return;
        }
        let canvas = {
            ID: 0, CodeOperateur: '', CodeTarif: '', LibelleProduit: '', CodeSupport: '',
            LibelleSupport: '', TarifSupport: '', TauxTva: ''
        } as any;
        if (support !== null) {
            canvas = support;
        }

        canvas.supportList = this.supportList;
        const dialogRef = this.dialog.open(SupportCreateDialogComponent, {
            width: '600px',
            data: canvas,
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== false && result !== undefined) {
                delete canvas.supportList;
                result.ID = canvas.ID.toString();
                result.CodeErreur = 0;
                result.CodeOperateur = result.CodeOperateur.toString();
                result.CodeTarif = result.CodeTarif.toString();
                result.CodeSupport = result.CodeSupport.toString();
                result.TarifSupport = result.TarifSupport.toString();
                result.TauxTva = result.TauxTva.toString();
                if (result.ID === '0') {
                    this.nodeJsInteractionService.supportCreate(result);
                } else {
                    this.nodeJsInteractionService.supportUpdate(result);
                }
            }
        });
    }

    openDeleteSupportConfirmationDialog(support): void {
        this.supportToDelete = support;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: '<h6>Êtes vous sur de vouloir supprimer ce support ? </h6>Celui-ci ne pourra plus être facturé lors de l\'achat. <br/>'
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.nodeJsInteractionService.supportDelete(support.ID);
            } else {
                this.supportToDelete = null;
            }
        });
    }
}
