import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { $ } from 'protractor';

export class NestedNode {
    ID: number;
    children: FlatNode[];
    Libelle: string;
}

export class FlatNode {
    ID: number;
    Libelle: string;
    level: number;
    expandable: boolean;
    expandableWithChild: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class DistributionPointDatabaseService {
    customData = null;
    nestedNodeList;
    nestedNodeKey;
    nestedNodeParentKey;
    nestedNodeLibelle;
    nestedNodeLibelleIdAssoc = {};

    flatNodeList;
    flatNodeKey;
    flatNodeNestedKey;
    flatNodeLibelle;

    dataChange = new BehaviorSubject<NestedNode[]>([]);

    get data(): NestedNode[] { return this.dataChange.value; }

    constructor() {
    }

    getNestedNode(key) { return this.nestedNodeList.find(element => element[this.nestedNodeKey] === key); }
    getNestedNodeList() { return this.nestedNodeList; }
    getNestedNodeKey() { return this.nestedNodeKey; }
    getNestedNodeParentKey() { return this.nestedNodeParentKey; }
    generateNestedNodeLibelle(node) {
        let libelle = '';
        if (Array.isArray(this.nestedNodeLibelle)) {
            this.nestedNodeLibelle.forEach(key => {
                libelle += node[key] + ' - ';
            });
            libelle = libelle.substr(0, libelle.length - 3);
        } else {
            libelle += node[this.nestedNodeLibelle];
        }
        return libelle;
    }
    setNestedNodeList(list) { this.nestedNodeList = list; }
    setNestedNodeKey(key) { this.nestedNodeKey = key; }
    setNestedNodeParentKey(key) { this.nestedNodeParentKey = key; }
    setNestedNodeLibelle(libelle) { this.nestedNodeLibelle = libelle; }

    getFlatNode(key) { return this.flatNodeList.find(element => element[this.flatNodeKey] === key); }
    getFlatNodeKey() { return this.flatNodeKey; }
    getFlatNodeList() { return this.flatNodeList; }
    getFlatNodeNestedKey() { return this.flatNodeNestedKey; }
    generateFlatNodeLibelle(node) {
        let libelle = '';
        if (Array.isArray(this.flatNodeLibelle)) {
            this.flatNodeLibelle.forEach(key => {
                libelle += node[key] + ' - ';
            });
            libelle = libelle.substr(0, libelle.length - 3);
        } else {
            libelle += node[this.flatNodeLibelle];
        }
        return libelle;
    }
    setFlatNodeList(list) { this.flatNodeList = list; }
    setFlatNodeKey(key) { this.flatNodeKey = key; }
    setFlatNodeNestedKey(key) { this.flatNodeNestedKey = key; }
    setFlatNodeLibelle(libelle) { this.flatNodeLibelle = libelle; }

    initialize() {
        this.assocNestedFlatNode();
        this.dataChange.next(this.customData);
    }

    initializeNested() {
        this.assocNestedFlatNodeNLevel();
        this.dataChange.next(this.customData);
    }

    createNestedNode(arr, parent, key, parentKey) {
        const out = [];
        for (const i in arr) {
            if (arr[i][parentKey] === parent) {
                const children = this.createNestedNode(arr, arr[i][key], key, parentKey);
                if (children.length) {
                    arr[i].children = children;
                }
                out.push(arr[i]);
            }
        }
        return out;
    }

    assocNestedFlatNodeNLevel() {
        const nestedTree = this.createNestedNode(this.nestedNodeList, 0, this.getNestedNodeKey(), this.getFlatNodeNestedKey());
        this.customData = nestedTree;
    }

    assocNestedFlatNode() {
        const nestedTree = {};
        this.nestedNodeList.forEach((nested) => {
            const libelle = this.generateNestedNodeLibelle(nested);
            this.nestedNodeLibelleIdAssoc[libelle] = nested.ID;
            nestedTree[libelle] = [];
            this.flatNodeList.forEach((flat) => {
                if (flat[this.flatNodeNestedKey] === nested[this.nestedNodeKey]) {
                    nestedTree[libelle].push(flat);
                }
            });
        });
        this.customData = this.buildFileTree(nestedTree, 0);
    }


    getFlatNodeAssoc(node: FlatNode) {
        const flatNode = this.flatNodeList.filter(
            element => element.ID === node.ID
        )[0];
        return flatNode;
    }

    findNode(currentNode, id) {
        const checkId = id || undefined;
        if (currentNode instanceof FlatNode) {
            if (currentNode.ID === checkId) {
                return currentNode;
            }
        } else {
            const list = (currentNode.children) ? currentNode.children : currentNode;
            for (const node of list) {
                const result = this.findNode(node, checkId);
                if (result !== false) {
                    return result;
                }
            }
        }
        return false;
    }

    /**d
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `NestedNode`.
     */
    buildFileTree(obj: { [key: string]: any }, level: number): NestedNode[] {
        return Object.keys(obj).reduce<NestedNode[]>((accumulator, key) => {
            const value = obj[key];
            const node: any = (typeof value === 'object' && value[this.flatNodeNestedKey] === undefined) ?
                new NestedNode() : new FlatNode();
            node.Libelle = key;

            if (value != null) {
                if (typeof value === 'object' && value[this.flatNodeNestedKey] === undefined) {
                    node.ID = this.nestedNodeLibelleIdAssoc[node.Libelle];
                    node.children = this.buildFileTree(value, level + 1);
                } else {
                    node.Libelle = '';
                    if (Array.isArray(this.flatNodeLibelle)) {
                        this.flatNodeLibelle.forEach(elem => {
                            node.Libelle += value[elem] + ' ';
                        });
                    } else {
                        node.Libelle += value[this.flatNodeLibelle];
                    }
                    node.ID = value[this.flatNodeKey];
                }
            }

            return accumulator.concat(node);
        }, []);
    }

    insertNestedIem(data) {
        const node = new NestedNode();
        node.Libelle = data.Libelle;
        node.children = [];
        this.data.push(node);

        this.dataChange.next(this.data);
    }

    insertFlatItem(parent: NestedNode, name: string) {
        const node = new FlatNode();
        node.Libelle = name;
        parent.children.push(node);
        this.dataChange.next(this.data);
    }

    updateFlatItem(node: FlatNode, name: string) {
        const updateNode = this.findNode(this.data, node.ID);
        updateNode.Libelle = name;
        this.dataChange.next(this.data);
    }

    removeNestedItem(parent: NestedNode) {
        const indexOf = this.data.indexOf(parent);
        this.data.splice(indexOf, 1);
        this.dataChange.next(this.data);
    }

    removeFlatItem(parent: NestedNode, node: FlatNode) {
        const childOfParent = parent.children.filter(
            element => element.ID === node.ID
        )[0];
        const indexOf = parent.children.indexOf(childOfParent);
        parent.children.splice(indexOf, 1);
        this.dataChange.next(this.data);
    }
}
