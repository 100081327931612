import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FlatNode, NestedNode, DistributionPointDatabaseService } from '../_service/distribution-point-database.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTreeFlatDataSource, MatTreeFlattener, MatSnackBar } from '@angular/material';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NodeJsInteractionService } from '../_service/nodejs-interaction.service';
import { TitleService } from '../_service/title.service';
import { Overlay } from '@angular/cdk/overlay';
import { ProgressContainerComponent } from '../_ui/progress-container/progress-container.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { Router } from '@angular/router';


@Component({
    selector: 'app-distribution-point-product',
    templateUrl: './distribution-point-product.component.html',
    styleUrls: ['./distribution-point-product.component.less'],
    providers: [
        { provide: 'instance1', useClass: DistributionPointDatabaseService },
        { provide: 'instance2', useClass: DistributionPointDatabaseService },
        { provide: 'instance3', useClass: DistributionPointDatabaseService }
    ]
})

export class DistributionPointProductComponent implements OnInit, OnDestroy {


    constructor(
        public titleService: TitleService,
        @Inject('instance1') public database: DistributionPointDatabaseService,
        @Inject('instance2') public databaseBackOffice: DistributionPointDatabaseService,
        @Inject('instance3') public databaseProduct: DistributionPointDatabaseService,
        public nodeJsInteractionService: NodeJsInteractionService,
        public overlay: Overlay,
        public snackBar: MatSnackBar,
        public router: Router
    ) {
        this.titleService.setTitle('Types de point de distribution - Gestion des gammes tarifaires');
        this.titleService.setEcranNumber(3);

        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren as any);
        this.treeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.treeFlattenerBackOffice = new MatTreeFlattener(
            this.transformerBackOffice, this.getLevel, this.isExpandable, this.getChildren as any
        );
        this.treeControlBackOffice = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.dataSourceBackOffice = new MatTreeFlatDataSource(this.treeControlBackOffice, this.treeFlattenerBackOffice);

        this.treeFlattenerProduct = new MatTreeFlattener(
            this.transformerProduct, this.getLevel, this.isExpandable, this.getChildren as any
        );
        this.treeControlProduct = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.dataSourceProduct = new MatTreeFlatDataSource(this.treeControlProduct, this.treeFlattenerProduct);

        this.database.setNestedNodeKey('ID');
        this.database.setNestedNodeLibelle(this.nestedNodeLibelle);
        database.dataChange.subscribe(data => {
            this.dataSource.data = data;
        });

        this.databaseBackOffice.setNestedNodeKey('ID');
        this.databaseBackOffice.setNestedNodeLibelle(this.nestedNodeLibelle);
        databaseBackOffice.dataChange.subscribe(data => {
            this.dataSourceBackOffice.data = data;
        });

        this.databaseProduct.setNestedNodeKey('Libelle');
        this.databaseProduct.setNestedNodeLibelle('Libelle');
        this.databaseProduct.setFlatNodeKey('code');
        this.databaseProduct.setFlatNodeLibelle('label_svd');
        this.databaseProduct.setFlatNodeNestedKey('category'); /* lien entre branche et feuille */

        databaseProduct.dataChange.subscribe(data => {
            this.dataSourceProduct.data = data;
        });
    }
    private overlayRef;

    selectedList = 'front';
    nestedNodeLibelle = ['Libelle', 'Canal'];
    flatNodeMap = new Map<FlatNode, NestedNode>();
    nestedNodeMap = new Map<NestedNode, FlatNode>();
    selectedParent: FlatNode | null = null;
    newItemName = '';
    treeControl: FlatTreeControl<FlatNode>;
    treeFlattener: MatTreeFlattener<NestedNode, FlatNode>;
    dataSource: MatTreeFlatDataSource<NestedNode, FlatNode>;
    checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);

    flatNodeMapBackOffice = new Map<FlatNode, NestedNode>();
    nestedNodeMapBackOffice = new Map<NestedNode, FlatNode>();
    selectedParentBackOffice: FlatNode | null = null;
    treeControlBackOffice: FlatTreeControl<FlatNode>;
    treeFlattenerBackOffice: MatTreeFlattener<NestedNode, FlatNode>;
    dataSourceBackOffice: MatTreeFlatDataSource<NestedNode, FlatNode>;
    checklistSelectionBackOffice = new SelectionModel<FlatNode>(true /* multiple */);

    flatNodeMapProduct = new Map<FlatNode, NestedNode>();
    nestedNodeMapProduct = new Map<NestedNode, FlatNode>();
    selectedParentProduct: FlatNode | null = null;
    newItemNameProduct = '';
    treeControlProduct: FlatTreeControl<FlatNode>;
    treeFlattenerProduct: MatTreeFlattener<NestedNode, FlatNode>;
    dataSourceProduct: MatTreeFlatDataSource<NestedNode, FlatNode>;
    checklistSelectionProduct = new SelectionModel<FlatNode>(true, []);
    distributionPointType;

    private assocPointTypeProduct = [];
    private cptWsMaj = 0;

    private getDistributionPointTypeListObservable;
    private getGtListObservable;
    private getSvdProfilListObservable;
    private getDistributionPointTypeProductAssociationListObservable;
    private getDistributionPointTypeProfilAssociationListObservable;
    private distributionPointTypeProductAssociationCreateObservable;
    private distributionPointTypeProductAssociationDeleteObservable;
    private distributionPointTypeProfilAssociationDeleteObservable;
    private distributionPointTypeProfilAssociationCreateObservable;
    attachOverlay() { this.overlayRef.attach(new ComponentPortal(ProgressContainerComponent)); this.detachOverlay(); }
    detachOverlay() { setTimeout(() => { this.overlayRef.detach(); }, 1000); }
    getLevel = (node: FlatNode) => node.level;
    isExpandable = (node: FlatNode) => node.expandable;
    getChildren = (node: NestedNode): FlatNode[] => node.children;
    hasChild = (_: number, nodeData: FlatNode) => nodeData.expandable;
    hasNoContent = (_: number, nodeData: FlatNode) => nodeData.Libelle === '';
    transformer = (node: NestedNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.Libelle === node.Libelle
            ? existingNode
            : new FlatNode();
        flatNode.Libelle = node.Libelle;
        flatNode.ID = node.ID;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }
    transformerBackOffice = (node: NestedNode, level: number) => {
        const existingNode = this.nestedNodeMapBackOffice.get(node);
        const flatNode = existingNode && existingNode.Libelle === node.Libelle
            ? existingNode
            : new FlatNode();
        flatNode.Libelle = node.Libelle;
        flatNode.ID = node.ID;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMapBackOffice.set(flatNode, node);
        this.nestedNodeMapBackOffice.set(node, flatNode);
        return flatNode;
    }
    transformerProduct = (node: NestedNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.Libelle === node.Libelle
            ? existingNode
            : new FlatNode();
        flatNode.Libelle = node.Libelle;
        flatNode.ID = node.ID;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMapProduct.set(flatNode, node);
        this.nestedNodeMapProduct.set(node, flatNode);
        return flatNode;
    }
    ngOnInit() {
        if (!this.nodeJsInteractionService.functionnalityAccess.checkAccess('DISTRIBUTION_AFFECTER_TITRES_AU_POINT_VENTE')) {
            this.router.navigate(['/home']);
        }

        this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        this.nodeJsInteractionService.getDistributionPointTypeList();
        this.getDistributionPointTypeListObservable = this.nodeJsInteractionService
            .getDistributionPointTypeListObservable.subscribe(data => {
                const response = data as any;
                const typePointDistributionList = (Array.isArray(response.TypePointDistribution)) ?
                    response.TypePointDistribution : [response.TypePointDistribution];
                this.database.setNestedNodeList(typePointDistributionList.filter(e => e.TypePoint === 'FrontOffice'));
                this.database.setFlatNodeList([]);
                this.database.initialize();
                this.databaseBackOffice.setNestedNodeList(typePointDistributionList.filter(e => e.TypePoint === 'BackOffice'));
                this.databaseBackOffice.setFlatNodeList([]);
                this.databaseBackOffice.initialize();
            });

        this.getGtListObservable = this.nodeJsInteractionService.getGtListObservable.subscribe(data => {
            const response = data as any;
            const gtList = (response) ? (Array.isArray(response.PimProduct)) ? response.PimProduct : [response.PimProduct] : [];
            const gtListCtg = [];
            gtList.forEach(product => {
                const childOfParent = gtListCtg.filter(
                    element => element.Libelle === product.category
                )[0];
                if (childOfParent === undefined) {
                    gtListCtg.push({
                        ID: this.database.getNestedNodeList().reduce((max, ctg) =>
                            ctg.ID > max ? ctg.ID : max, this.database.getNestedNodeList()[0].ID
                        ) + 1,
                        Libelle: product.category
                    });
                }
            });

            const nestedNodeList = gtListCtg;
            const flatNodeList = gtList;
            this.databaseProduct.setNestedNodeList(nestedNodeList.filter(e => e !== undefined));
            this.databaseProduct.setFlatNodeList(flatNodeList.filter(e => e !== undefined));
            this.nodeJsInteractionService.getSvdProfilList(this.distributionPointType.Canal);
        });

        this.getSvdProfilListObservable = this.nodeJsInteractionService.getSvdProfilListObservable.subscribe(data => {
            const response = data as any;
            if (response !== null) {
                const profilList = (Array.isArray(response.PimProfile)) ? response.PimProfile : [response.PimProfile];
                profilList.forEach(e => {
                    e.category = 'Profils';
                    // label_svd n'existe pas dans les profils, on doit faire une affectation pour la gestion 
                    // de l'affichage des libellé (voir: "this.databaseProduct.setFlatNodeLibelle('label_svd');")
                    e.label_svd = e.label;
                    e.id = 'p_' + e.code;
                });
                const profilListCtg = [{
                    ID: this.database.getNestedNodeList().reduce((max, ctg) =>
                        ctg.ID > max ? ctg.ID : max, this.database.getNestedNodeList()[0].ID) + 1,
                    Libelle: 'Profils'
                }];

                const nestedNodeList = profilListCtg.concat(this.databaseProduct.getNestedNodeList());
                const flatNodeList = profilList.concat(this.databaseProduct.getFlatNodeList());
                this.databaseProduct.setNestedNodeList(nestedNodeList.filter(e => e !== undefined));
                this.databaseProduct.setFlatNodeList(flatNodeList.filter(e => e !== undefined));
            }
            this.databaseProduct.initialize();
            this.nodeJsInteractionService.getDistributionPointTypeProductAssociationList(this.checklistSelection.selected[0].ID);
            this.detachOverlay();
        });

        this.getDistributionPointTypeProductAssociationListObservable = this.nodeJsInteractionService
            .getDistributionPointTypeProductAssociationListObservable.subscribe(data => {
                const response = data as any;
                const productListId = (Array.isArray(response.int)) ? response.int : [response.int];
                this.selectAssocGt(productListId);
                this.nodeJsInteractionService.getDistributionPointTypeProfilAssociationList(this.checklistSelection.selected[0].ID);
            });

        this.getDistributionPointTypeProfilAssociationListObservable = this.nodeJsInteractionService
            .getDistributionPointTypeProfilAssociationListObservable.subscribe(data => {
                const response = data as any;
                const profilListId = (Array.isArray(response.int)) ? response.int : [response.int];
                this.selectAssocGt(profilListId);
                this.detachOverlay();
            });

        const successSnackBarRefresh = () => {
            this.nodeJsInteractionService.getDistributionPointTypeProfilAssociationList(this.checklistSelection.selected[0].ID)
            this.snackBar.open('La liste des produits a été enregistrée.', null, {
                duration: 3000,
                panelClass: 'success'
            });
        }
        this.distributionPointTypeProductAssociationCreateObservable = this.nodeJsInteractionService
            .distributionPointTypeProductAssociationCreateObservable.subscribe(data => {
                this.cptWsMaj--;
                if (this.cptWsMaj === 0) {
                    successSnackBarRefresh()
                }
            });

        this.distributionPointTypeProfilAssociationCreateObservable = this.nodeJsInteractionService
            .distributionPointTypeProfilAssociationCreateObservable.subscribe(data => {
                this.cptWsMaj--;
                if (this.cptWsMaj === 0) {
                    successSnackBarRefresh()
                }
            });

        this.distributionPointTypeProductAssociationDeleteObservable = this.nodeJsInteractionService
            .distributionPointTypeProductAssociationDeleteObservable.subscribe(data => {
                this.cptWsMaj--;
                if (this.cptWsMaj === 0) {
                    successSnackBarRefresh()
                }
            });

        this.distributionPointTypeProfilAssociationDeleteObservable = this.nodeJsInteractionService
            .distributionPointTypeProfilAssociationDeleteObservable.subscribe(data => {
                this.cptWsMaj--;
                if (this.cptWsMaj === 0) {
                    successSnackBarRefresh()
                }
            });
    }

    selectAssocGt(idList) {
        const flatNodeList = this.databaseProduct.getFlatNodeList();
        flatNodeList.forEach(node => {
            if (idList.includes(node[this.databaseProduct.getFlatNodeKey()])) {
                const assocNode = this.databaseProduct.findNode(
                    this.treeControlProduct.dataNodes,
                    node[this.databaseProduct.getFlatNodeKey()]
                );
                this.checklistSelectionProduct.select(assocNode);

                const parentNode = this.getParentNodeProduct(assocNode);
                const descendants = this.treeControlProduct.getDescendants(parentNode);
                if (descendants.every(child => this.checklistSelectionProduct.isSelected(child)) && parentNode) {
                    this.checklistSelectionProduct.select(parentNode);
                }

                this.treeControlProduct.expand(parentNode);
                this.assocPointTypeProduct.push(assocNode.ID);
            }
        });
    }

    ngOnDestroy() {
        this.getDistributionPointTypeListObservable.unsubscribe();
        this.getGtListObservable.unsubscribe();
        this.getSvdProfilListObservable.unsubscribe();
        this.getDistributionPointTypeProductAssociationListObservable.unsubscribe();
        this.getDistributionPointTypeProfilAssociationListObservable.unsubscribe();
        this.distributionPointTypeProductAssociationCreateObservable.unsubscribe();
        this.distributionPointTypeProductAssociationDeleteObservable.unsubscribe();
        this.distributionPointTypeProfilAssociationCreateObservable.unsubscribe();
        this.distributionPointTypeProfilAssociationDeleteObservable.unsubscribe();
    }

    setSelectedList(type) {
        this.selectedList = type;
        this.checklistSelection.clear();
        this.checklistSelectionBackOffice.clear();
    }

    getProductList(node: FlatNode) {
        const dataBase = (this.selectedList === 'back') ? this.databaseBackOffice : this.database;
        this.distributionPointType = dataBase.getNestedNode(node.ID);
        this.attachOverlay();
        this.assocPointTypeProduct = [];
        this.nodeJsInteractionService.getGtList(this.distributionPointType.Canal);
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: FlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: FlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggle(node: FlatNode): void {
        this.getProductList(node);
        this.checklistSelection.clear();
        this.checklistSelectionProduct.clear();
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggle(node: FlatNode): void {
        this.checklistSelection.toggle(node);
    }

    // =====================================================================
    // FUNCTION PRODUCT

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelectedProduct(node: FlatNode): boolean {
        const descendants = this.treeControlProduct.getDescendants(node);
        const descAllSelectedProduct = descendants.every(child =>
            this.checklistSelectionProduct.isSelected(child)
        );
        return descAllSelectedProduct;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelectedProduct(node: FlatNode): boolean {
        const descendants = this.treeControlProduct.getDescendants(node);
        const result = descendants.some(child => this.checklistSelectionProduct.isSelected(child));
        return result && !this.descendantsAllSelectedProduct(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggleProduct(node: FlatNode): void {
        this.checklistSelectionProduct.toggle(node);
        const descendants = this.treeControlProduct.getDescendants(node);
        this.checklistSelectionProduct.isSelected(node)
            ? this.checklistSelectionProduct.select(...descendants)
            : this.checklistSelectionProduct.deselect(...descendants);

        descendants.every(child =>
            this.checklistSelectionProduct.isSelected(child)
        );

        this.checkAllParentsSelectionProduct(node);
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggleProduct(node: FlatNode): void {
        this.checklistSelectionProduct.toggle(node);
        this.checkAllParentsSelectionProduct(node);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelectionProduct(node: FlatNode): void {
        let parent: FlatNode | null = this.getParentNodeProduct(node);

        while (parent) {
            this.checkRootNodeSelectionProduct(parent);
            parent = this.getParentNodeProduct(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelectionProduct(node: FlatNode): void {
        const nodeSelected = this.checklistSelectionProduct.isSelected(node);
        const descendants = this.treeControlProduct.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelectionProduct.isSelected(child)
        );

        if (nodeSelected && !descAllSelected) {
            this.checklistSelectionProduct.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelectionProduct.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNodeProduct(node: FlatNode): FlatNode | null {
        const currentLevel = this.getLevel(node);
        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControlProduct.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControlProduct.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    /** Select the category so we can insert the new item. */
    addNewItemProduct(node: FlatNode) {
        const parentNode = this.flatNodeMapProduct.get(node);
        this.databaseProduct.insertFlatItem(parentNode, '');
        this.treeControl.expand(node);
    }

    /** Save the node to database */
    saveNodeProduct() {
        this.attachOverlay();
        const idType = this.checklistSelection.selected[0].ID;
        const checklistSelectionProductSelected = this.checklistSelectionProduct.selected.filter(e => {
            const flatNode = this.databaseProduct.flatNodeList.find(p => p.code === e.ID && p.label_svd === e.Libelle)
            return flatNode && flatNode.id && flatNode.id.toString().substr(0, 2) !== 'p_' ? e : null
        });
        const checklistSelectionProfilSelected = this.checklistSelectionProduct.selected.filter(e => {
            const flatNode = this.databaseProduct.flatNodeList.find(p => p.code === e.ID && p.label === e.Libelle)
            return flatNode && flatNode.id && flatNode.id.toString().substr(0, 2) === 'p_' ? e : null
        });
        

        const deltaToDelete = this.assocPointTypeProduct.filter((i) => {
            return !checklistSelectionProductSelected.find(element => element.ID === i) &&
                !checklistSelectionProfilSelected.map(e => e.ID).includes(i);
        });
        const profilDeltaToDelete = this.assocPointTypeProduct.filter((i) => {
            return !checklistSelectionProfilSelected.find(element => element.ID === i) &&
                !checklistSelectionProductSelected.map(e => e.ID).includes(i);
        });
        
        this.cptWsMaj = this.checklistSelectionProduct.selected.filter(selected => selected.expandable === false).length + deltaToDelete.length + profilDeltaToDelete.length;

        checklistSelectionProductSelected.forEach(elem => {
            this.nodeJsInteractionService.distributionPointTypeProductAssociationCreate({
                pIdTypePointDistribution: idType.toString(),
                pCodeProduit: elem.ID.toString()
            });

        });
        checklistSelectionProfilSelected.forEach(elem => {
            this.nodeJsInteractionService.distributionPointTypeProfilAssociationCreate({
                pIdTypePointDistribution: idType.toString(),
                pCodeProduit: elem.ID.toString()
            });
        });

        deltaToDelete.forEach(elem => {
            this.nodeJsInteractionService.distributionPointTypeProductAssociationDelete({
                pIdTypePointDistribution: idType.toString(),
                pCodeProduit: elem.toString()
            });
        });
        profilDeltaToDelete.forEach(elem => {
            this.nodeJsInteractionService.distributionPointTypeProfilAssociationDelete({
                pIdTypePointDistribution: idType.toString(),
                pCodeProduit: elem.toString()
            });
        });
    }

    /** Remove the node from database */
    removeNodeProduct(node: FlatNode) {
        const parentNode = this.flatNodeMapProduct.get(this.getParentNodeProduct(node));
        const faqNode = this.databaseProduct.getFlatNodeAssoc(node);
        this.databaseProduct.removeFlatItem(parentNode, node);
        this.attachOverlay();
    }
}
