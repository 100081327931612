import { Component, OnInit } from '@angular/core';
import { TitleService } from '../_service/title.service';
import { NodeJsInteractionService } from '../_service/nodejs-interaction.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

    constructor(
        public titleService: TitleService,
        public nodeJsInteractionService: NodeJsInteractionService
    ) {
        this.titleService.setTitle('Extrapro - Back Office');
        this.titleService.setEcranNumber(1);
    }

    ngOnInit() {
    }

}
