import { Component, OnInit, NgZone, ViewChild, OnDestroy } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatTableDataSource, MatDialog, MatSnackBar, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { Overlay } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { TitleService } from '../_service/title.service';
import { NodeJsInteractionService } from '../_service/nodejs-interaction.service';
import { TableExportService } from '../_service/table-export.service';
import { ProgressContainerComponent } from '../_ui/progress-container/progress-container.component';
import { DistributionPointDialogComponent } from '../_ui/distribution-point-dialog/distribution-point-dialog.component';
import { MY_FORMAT } from 'src/environments/environment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

@Component({
    selector: 'app-receipt-reissue',
    templateUrl: './receipt-reissue.component.html',
    styleUrls: ['./receipt-reissue.component.less'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT }
    ]
})
export class ReceiptReissueComponent implements OnInit, OnDestroy {
    constructor(
        public titleService: TitleService,
        public nodeJsInteractionService: NodeJsInteractionService,
        public tableExportService: TableExportService,
        public overlay: Overlay,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public ngZone: NgZone,
        public router: Router
    ) {

        this.titleService.setTitle('Réedition ticket de caisse');
        this.titleService.setEcranNumber(12);
        this.dataSourceTable = new MatTableDataSource([]);
        this.currentDistributionPoint = JSON.parse(localStorage.getItem('currentDistributionPoint'));
        this.backClickedInitState = this.titleService.backClicked;
        this.titleService.backClicked = () => {
            if (this.displayTicket) {
                this.displayTicket = false;
                this.displaySearch = true;
            } else if (this.displaySearch) {
                this.displaySearch = false;
            } else {
                this.titleService.location.back();
            }
        };
    }
    private overlayRef;

    tableDisplayedColumns: string[] = ['ID', 'numero_commande', 'date_ticket', 'montant'];
    dataSourceTable: MatTableDataSource<any>;

    justifContent = null;
    justifDistributionPoint = null;
    searchFilter = {
        ListePointDistribution: [],
        NumeroSupport: '',
        DateDebut: '',
        DateFin: ''
    };

    displaySearch = false;
    displayDetailTicket = false;
    displayDetailTicketContent;
    titleList;
    sousTotals;
    currentDistributionPoint;
    selectedDistributionPointListId = [];
    selectedPointLibelle = '';
    backClickedInitState;

    @ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;

    private getReloadingSummaryListObservable;
    private getTicketObservable;
    private getDistributionPointObservable;
    displayTicket = false;
    attachOverlay() { this.overlayRef.attach(new ComponentPortal(ProgressContainerComponent)); }
    detachOverlay() { setTimeout(() => { this.overlayRef.detach(); }, 200); }

    triggerResize() {
        this.ngZone.onStable.subscribe(() => this.autosize.resizeToFitContent(true));
    }
    ngOnInit() {
        if (!this.nodeJsInteractionService.functionnalityAccess.checkAccess('HISTORIQUE_ACCEDER_HISTORIQUE_RECHARGEMENT_TOUS')) {
            this.router.navigate(['/home']);
        }

        this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });

        this.getReloadingSummaryListObservable = this.nodeJsInteractionService.getReloadingSummaryListObservable.subscribe(data => {
            const response = data as any;
            if (response.Liste !== '' && response.Liste.ResumeRechargement) {
                const list = (Array.isArray(response.Liste.ResumeRechargement)) ?
                    response.Liste.ResumeRechargement : [response.Liste.ResumeRechargement];
                list.sort((a, b) => new Date(b.date_heure_rec).getTime() - new Date(a.date_heure_rec).getTime());
                this.dataSourceTable = new MatTableDataSource(list);
                this.displaySearch = true;
            } else {
                this.snackBar.open('Aucun résultat avec ces informations', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
            }


            this.detachOverlay();
        });

        this.getTicketObservable = this.nodeJsInteractionService.getTicketObservable.subscribe(data => {
            const response = data as any;
            this.justifContent = response;
            this.nodeJsInteractionService.getDistributionPoint(response.IdPointDistribution);
            this.titleList = (Array.isArray(this.justifContent.Titres.TitreJustificatif)) ?
                this.justifContent.Titres.TitreJustificatif : (this.justifContent.Titres === '') ?
                    [] : [this.justifContent.Titres.TitreJustificatif];
            const sousTotalJustificatifList = (Array.isArray(this.justifContent.SousTotauxJustificatif.SousTotalJustificatif)) ?
            this.justifContent.SousTotauxJustificatif.SousTotalJustificatif : (this.justifContent.SousTotauxJustificatif === '') ?
                    [] : [this.justifContent.SousTotauxJustificatif.SousTotalJustificatif];
            this.sousTotals = sousTotalJustificatifList.reduce((totalsByTVA, currentTotal) => {
                let index = totalsByTVA.findIndex(t => t.TauxTva === currentTotal.TauxTva);
                if(index < 0) {
                    totalsByTVA.push(currentTotal);
                } else {
                    totalsByTVA[index] = {
                        "TotalHT": totalsByTVA[index].TotalHT + currentTotal.TotalHT,
                        "TotalTva": totalsByTVA[index].TotalTva + currentTotal.TotalTva,
                        "TotalTTC": totalsByTVA[index].TotalTTC + currentTotal.TotalTTC,
                        "TauxTva": totalsByTVA[index].TotalHT,
                        "Devise": totalsByTVA[index].Devise
                    }
                }
                return totalsByTVA;
            }
            ,[])
        });

        this.getDistributionPointObservable = this.nodeJsInteractionService.getDistributionPointObservable.subscribe(data => {
            const response = data as any;
            this.justifDistributionPoint = response;
            this.displayTicket = true;
        });

    }

    ngOnDestroy() {
        this.getReloadingSummaryListObservable.unsubscribe();
        this.getTicketObservable.unsubscribe();
        this.getDistributionPointObservable.unsubscribe();
        this.titleService.backClicked = this.backClickedInitState;
    }

    openDistributionPointListDialog(): void {
        const dialogRef = this.dialog.open(DistributionPointDialogComponent, {
            width: '600px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const distributionPointList = result.filter(element => element.expandable === false);
                this.selectedDistributionPointListId = [];
                this.selectedPointLibelle = '';
                distributionPointList.forEach(element => {
                    this.selectedDistributionPointListId.push(element.ID);
                    this.selectedPointLibelle += element.Libelle + ', ';
                });
            }
        });
    }

    launchSearch() {
        if (this.searchFilter.NumeroSupport !== '') {
            this.attachOverlay();
            this.dataSourceTable.data = [];
            this.dataSourceTable._updateChangeSubscription();
            this.searchFilter.ListePointDistribution = this.selectedDistributionPointListId;

            const dateDebut = this.searchFilter.DateDebut as any;
            const dateFin = this.searchFilter.DateFin as any;
            this.searchFilter.DateDebut = (typeof dateDebut === 'string') ?
                (dateDebut === '') ? '' : dateDebut.substring(0, 10) :
                (!dateDebut) ? '' : dateDebut.format('DD/MM/YYYY');
            this.searchFilter.DateFin = (typeof dateFin === 'string') ?
                (dateFin === '') ? '' : dateFin.substring(0, 10) :
                (!dateFin) ? '' : dateFin.format('DD/MM/YYYY');

            this.nodeJsInteractionService.getReloadingSummaryList(this.searchFilter);
        } else {
            this.snackBar.open('Veuillez renseigner au minimum un numéro de support avant d\'effectuer une recherche.', null, {
                duration: 3000,
                panelClass: 'infos'
            });
        }
    }

    leaveDisplayDetail() {
        this.displayTicket = false;
        this.displayDetailTicket = false;
        this.titleList = [];
        this.sousTotals = [];
        this.justifContent = null;
        this.justifDistributionPoint = null;
    }

    displayDetail(element) {
        this.displayDetailTicket = true;
        this.displayDetailTicketContent = element;
        this.nodeJsInteractionService.getTicket(element.ID.toString());
    }

    exportToExcel() { this.tableExportService.exportToExcel('receipt-reissue-content-table', 'Ticket de caisse'); }
}
