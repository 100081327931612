import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource, MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { NodeJsInteractionService } from '../_service/nodejs-interaction.service';
import { TitleService } from '../_service/title.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ProgressContainerComponent } from '../_ui/progress-container/progress-container.component';
import { ConfirmationDialogComponent } from '../_ui/confirmation-dialog/confirmation-dialog.component';
import { FaqDatabaseService, FaqFlatNode, CategoryNode } from '../_service/faq-database.service';
import { Router } from '@angular/router';


@Component({
    selector: 'dialog-overview-example-dialog',
    template: `
    <div class='purple-cyan-theme'>
        <h1 mat-dialog-title class='dialog-ilevia-entete background-warn'>Renseignez le libellé de la nouvelle catégorie</h1>
        <mat-dialog-content>
            <mat-form-field class='w-100'>
                <input matInput [(ngModel)]="data.Libelle" placeholder='Libellé...'>
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions align='end'>
            <button mat-raised-button color='warn' (click)="onNoClick()">Annuler</button>
            <button mat-raised-button color='warn' [mat-dialog-close]="data.Libelle" cdkFocusInitial>Sauvegarder</button>
        </mat-dialog-actions>
    </div>`,
})

export class CreateCategoryDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<CreateCategoryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { Libelle: string }) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.less'],
    providers: [FaqDatabaseService]
})
export class FaqComponent implements OnInit, OnDestroy {

    constructor(
        public titleService: TitleService,
        public database: FaqDatabaseService,
        public nodeJsInteractionService: NodeJsInteractionService,
        public overlay: Overlay,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public router: Router
    ) {
        this.titleService.setTitle('FAQ - Gestion des catégories et questions');
        this.titleService.setEcranNumber(11);
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren as any);
        this.treeControl = new FlatTreeControl<FaqFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        database.dataChange.subscribe(data => {
            this.dataSource.data = data;
        });
    }

    private overlayRef;

    cptWs = 0;
    selectedFaq = { ID: null, IdCategorie: null, Question: null, Reponse: null, CodeErreur: null };

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        maxHeight: 'auto',
        width: 'auto',
        toolbarHiddenButtons: [
            ['link', 'unlink'],
            ['toggleEditorMode']
        ]
    };

    /** Map from flat node to nested node. This helps us finding the nested node to be modified */
    flatNodeMap = new Map<FaqFlatNode, CategoryNode>();
    /** Map from nested node to flattened node. This helps us to keep the same object for selection */
    nestedNodeMap = new Map<CategoryNode, FaqFlatNode>();
    /** A selected parent node to be inserted */
    selectedParent: FaqFlatNode | null = null;
    /** The new item's name */
    newItemName = '';
    treeControl: FlatTreeControl<FaqFlatNode>;
    treeFlattener: MatTreeFlattener<CategoryNode, FaqFlatNode>;
    dataSource: MatTreeFlatDataSource<CategoryNode, FaqFlatNode>;
    /** The selection for checklist */
    checklistSelection = new SelectionModel<FaqFlatNode>(true /* multiple */);

    private faqCreateObservable;
    private faqUpdateObservable;
    private faqDeleteObservable;
    private ctgFaqCreateObservable;
    private ctgFaqUpdateObservable;
    private ctgFaqDeleteObservable;
    private distributionPointTypeFaqAssociationCreateObservable;
    private distributionPointTypeFaqAssociationDeleteObservable;
    attachOverlay() { this.overlayRef.attach(new ComponentPortal(ProgressContainerComponent)); }
    detachOverlay() { setTimeout(() => { this.overlayRef.detach(); }, 200); }

    openDialogCtg(node): void {
        const nodeData = node || null;
        let canvas = { ID: 0, Libelle: '' };
        if (nodeData !== null) {
            canvas = node;
        }
        const dialogRef = this.dialog.open(CreateCategoryDialogComponent, {
            width: '500px',
            data: canvas,
            panelClass: 'faqCtgDialog'
        });


        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined && result !== '') {
                const ctg = {
                    ID: canvas.ID,
                    Libelle: result,
                    CodeErreur: 0
                };
                if (ctg.ID !== 0) {
                    this.nodeJsInteractionService.ctgFaqUpdate(ctg);
                } else {
                    this.nodeJsInteractionService.ctgFaqCreate(ctg);
                }
            }
        });
    }

    openDeleteCtgConfirmationDialog(node: FaqFlatNode): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: `
                <h6>Êtes vous sur de vouloir supprimer la catégorie ? </h6>
                L\'ensemble des questions associées à celle-ci seront également supprimées. <br/>
            `
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.removeCtgNode(node);
            }
        });
    }


    openDeleteQuestionConfirmationDialog(node: FaqFlatNode): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: `
            <h6>Êtes vous sur de vouloir supprimer la question ? </h6>
            L\'ensemble des questions associées à celle-ci seront également supprimées. <br/>
            `
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.removeNode(node);
            }
        });
    }
    ngOnInit() {
        if (!this.nodeJsInteractionService.functionnalityAccess.checkAccess('FAQ_GERER')) {
            this.router.navigate(['/home']);
        }

        this.editorConfig.height = (document.getElementById('editor-scroll').offsetHeight - 280) + 'px';
        this.editorConfig.maxHeight = (document.getElementById('editor-scroll').offsetHeight - 280) + 'px';
        this.database.initialize();

        this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });

        this.faqCreateObservable = this.nodeJsInteractionService.faqCreateObservable.subscribe(data => {
            this.selectedFaq = data as any;
            this.detachOverlay();
        });

        this.faqUpdateObservable = this.nodeJsInteractionService.faqUpdateObservable.subscribe(data => {
            this.database.updateItem(this.selectedFaq as any, data);
            this.detachOverlay();
        });

        this.faqDeleteObservable = this.nodeJsInteractionService.faqDeleteObservable.subscribe(data => {
            this.detachOverlay();
        });

        this.ctgFaqUpdateObservable = this.nodeJsInteractionService.ctgFaqUpdateObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            if (response.CodeErreur !== 0) {
                this.snackBar.open('Une erreur est survenue lors de l\'enregistrement de la catégorie.', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
                return;
            }
            this.snackBar.open('La catégorie a bien été enregistrée.', null, {
                duration: 3000,
                panelClass: 'success'
            });
        });

        this.ctgFaqCreateObservable = this.nodeJsInteractionService.ctgFaqCreateObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            if (response.CodeErreur !== 0) {
                this.snackBar.open('Une erreur est survenue lors de l\'enregistrement de la catégorie.', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
                return;
            }
            this.database.insertCtgItem(data);
            this.snackBar.open('La catégorie a bien été enregistrée.', null, {
                duration: 3000,
                panelClass: 'success'
            });
        });

        this.ctgFaqDeleteObservable = this.nodeJsInteractionService.ctgFaqDeleteObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            if (response !== true) {
                this.snackBar.open('Une erreur est survenue lors de la suppression de la catégorie.', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
                return;
            }
            this.snackBar.open('La catégorie a bien été supprimée.', null, {
                duration: 3000,
                panelClass: 'success'
            });
        });

        this.distributionPointTypeFaqAssociationCreateObservable = this.nodeJsInteractionService
            .distributionPointTypeFaqAssociationCreateObservable.subscribe(data => {
                const response = data as any;
                this.cptWs--;
                if (this.cptWs === 0) {
                    this.detachOverlay();
                    if (response === true) {
                        this.snackBar.open('Les données ont bien été sauvegardées.', null, {
                            duration: 3000,
                            panelClass: 'success'
                        });
                    }
                }
            });
        this.distributionPointTypeFaqAssociationDeleteObservable = this.nodeJsInteractionService
            .distributionPointTypeFaqAssociationDeleteObservable.subscribe(data => {
                const response = data as any;
                this.cptWs--;
                if (this.cptWs === 0) {
                    this.detachOverlay();
                    if (response === true) {
                        this.snackBar.open('Les données ont bien été sauvegardées.', null, {
                            duration: 3000,
                            panelClass: 'success'
                        });
                    }
                }
            });
    }

    ngOnDestroy() {
        this.faqCreateObservable.unsubscribe();
        this.faqUpdateObservable.unsubscribe();
        this.faqDeleteObservable.unsubscribe();
        this.ctgFaqCreateObservable.unsubscribe();
        this.ctgFaqUpdateObservable.unsubscribe();
        this.ctgFaqDeleteObservable.unsubscribe();
        this.distributionPointTypeFaqAssociationCreateObservable.unsubscribe();
        this.distributionPointTypeFaqAssociationDeleteObservable.unsubscribe();
        this.database.destroy();
    }


    getLevel = (node: FaqFlatNode) => node.level;
    isExpandable = (node: FaqFlatNode) => node.expandable;
    getChildren = (node: CategoryNode): FaqFlatNode[] => node.children;
    hasChild = (_: number, nodeData: FaqFlatNode) => nodeData.expandable;
    hasNoContent = (_: number, nodeData: FaqFlatNode) => nodeData.Libelle === '';

    unescapeHtml(safe) {
        return safe.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"').replace(/&#039;/g, '\'');
    }
    escapeHtml(unsafe) {
        return unsafe.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;');
    }

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: CategoryNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.Libelle === node.Libelle
            ? existingNode
            : new FaqFlatNode();
        flatNode.Libelle = node.Libelle;
        flatNode.ID = node.ID;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: FaqFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: FaqFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggle(node: FaqFlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        this.checkAllParentsSelection(node);
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggle(node: FaqFlatNode): void {
        this.checklistSelection.clear();
        this.selectedFaq = this.database.getFaqAssoc(node);
        this.checkDisitributionTypePoint(node.ID);
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: FaqFlatNode): void {
        let parent: FaqFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: FaqFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: FaqFlatNode): FaqFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    /** Select the category so we can insert the new item. */
    addNewItem(node: FaqFlatNode) {
        const parentNode = this.flatNodeMap.get(node);
        this.database.insertItem(parentNode, '');
        this.treeControl.expand(node);
    }

    /** Save the node to database */
    saveNode(node: FaqFlatNode, itemValue: string) {
        if (itemValue === '') {
            return false;
        }
        const nestedNode = this.flatNodeMap.get(node) as any;
        const parentNode = this.getParentNode(node);

        const faqNode = {
            ID: 0,
            IdCategorie: 0,
            Question: itemValue,
            Reponse: '',
            CodeErreur: 0
        };
        const parentCtg = this.database.getCtg(parentNode);
        faqNode.IdCategorie = parentCtg.ID;
        this.database.updateItem(nestedNode, faqNode);
        this.selectedFaq = faqNode;
        this.attachOverlay();
        this.nodeJsInteractionService.faqCreate(faqNode);
    }

    removeCtgNode(node: FaqFlatNode) {
        const parentNode = this.flatNodeMap.get(node);
        const ctgFaqNode = this.database.getCtg(node);
        this.database.removeCtgItem(parentNode);
        this.attachOverlay();
        this.nodeJsInteractionService.ctgFaqDelete(ctgFaqNode.ID);
    }

    removeNode(node: FaqFlatNode) {
        const parentNode = this.flatNodeMap.get(this.getParentNode(node));
        const faqNode = this.database.getFaqAssoc(node);
        this.database.removeItem(parentNode, node);
        this.attachOverlay();
        this.nodeJsInteractionService.faqDelete(faqNode.ID);
    }

    checkDisitributionTypePoint(idFaq) {
        const keyList = Object.keys(this.database.typeDistributionPointChecked);
        keyList.forEach(key => {
            const list = this.database.typeDistributionPointFaqAssociation[key];
            if (list !== undefined) {
                this.database.typeDistributionPointChecked[key] = list.includes(idFaq);
            }
        });

        if (Object.values(this.database.typeDistributionPointChecked).every(e => e === false)) {
            Object.keys(this.database.typeDistributionPointChecked).forEach(k => this.database.typeDistributionPointChecked[k] = true);
        }
    }

    faqUpdate() {
        if (this.selectedFaq.ID !== null && this.selectedFaq.IdCategorie != null && this.selectedFaq.Reponse != null) {
            this.attachOverlay();
            this.selectedFaq.Reponse = this.escapeHtml(this.selectedFaq.Reponse);
            this.nodeJsInteractionService.faqUpdate(this.selectedFaq);
            this.cptWs = 0;
            Object.keys(this.database.typeDistributionPointChecked).forEach(key => {
                if (this.database.typeDistributionPointChecked[key] === true) {
                    if (!this.database.typeDistributionPointFaqAssociation[key].includes(this.selectedFaq.ID)) {
                        this.database.typeDistributionPointFaqAssociation[key].push(this.selectedFaq.ID);
                    }
                    this.cptWs++;
                    this.nodeJsInteractionService.distributionPointTypeFaqAssociationCreate(
                        { pIdTypePointDistribution: key, pIdFaq: this.selectedFaq.ID })
                        ;
                } else {
                    if (this.database.typeDistributionPointFaqAssociation[key].includes(this.selectedFaq.ID)) {
                        this.cptWs++;
                        this.database.typeDistributionPointFaqAssociation[key].splice(
                            this.database.typeDistributionPointFaqAssociation[key].indexOf(this.selectedFaq.ID), 1
                        );
                        this.nodeJsInteractionService.distributionPointTypeFaqAssociationDelete(
                            { pIdTypePointDistribution: key, pIdFaq: this.selectedFaq.ID }
                        );
                    }
                }
            });
            this.selectedFaq.Reponse = this.unescapeHtml(this.selectedFaq.Reponse);
        } else {
            this.snackBar.open('Veuillez sélectionner une question dans la liste à gauche avant de sauvegarder.', null, {
                duration: 3000
            });
        }
    }

}
