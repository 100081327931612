import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material';
import { NodeJsInteractionService } from '../_service/nodejs-interaction.service';
import { TitleService } from '../_service/title.service';

export class User {
    username: string;
    password: string;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit, OnDestroy {
    user: User = new User();
    loading = false;
    loginForm: FormGroup;
    errors: { [key: string]: string } = {
        username: 'L\'identifiant est requis',
        password: 'Le mot de passe est requis'
    };

    currentDistributionPointId;
    isConnection;
    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        public matIconRegistry: MatIconRegistry,
        public titleService: TitleService,
        public nodeJsInteractionService: NodeJsInteractionService
    ) {
        this.titleService.hideMenu();
    }

    authentificationObservable;
    getDistributionPointListObservable;
    getFunctionnalityListObservable;
    ngOnInit() {
        this.isConnection = !localStorage.getItem('userId')
        this.titleService.currentUserLibelle = '';
        this.loginForm = this.formBuilder.group({
            userSelect: Validators.required
        });

        this.loading = true;
        this.nodeJsInteractionService.authentification(localStorage.getItem('userId'));
        this.authentificationObservable = this.nodeJsInteractionService.authentificationObservable.subscribe(data => {
            let result: any;
            result = data;
            if (result !== '' && result !== undefined) {
                this.nodeJsInteractionService.getUser(localStorage.getItem('userId'));
            }
        }, error => {
            this.loading = false;
        }
        );

        this.nodeJsInteractionService.getUserObservable.subscribe(data => {
            const response = data as any;
            localStorage.setItem('currentUser', JSON.stringify(response));
            this.nodeJsInteractionService.getDistributionPointList();
            this.currentDistributionPointId = response.id_point_distribution;
        });

        this.getDistributionPointListObservable = this.nodeJsInteractionService.getDistributionPointListObservable.subscribe(data => {
            const response = data as any;
            const currentDistributionPoint = response.PointDistribution.find(elem => elem.ID === this.currentDistributionPointId);
            localStorage.setItem('currentDistributionPoint', JSON.stringify(currentDistributionPoint));
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            this.titleService.currentUserLibelle = currentUser.nom + ' ' + currentUser.prenom + ' - '
                + currentUser.type_utilisateur + ' - ' + currentDistributionPoint.Libelle;
            this.nodeJsInteractionService.getFunctionnalityList(currentDistributionPoint.Idtypepointdistribution);
        });
        this.getFunctionnalityListObservable = this.nodeJsInteractionService.getFunctionnalityListObservable.subscribe(data => {
            const response = data as any;
            const accessList = (Array.isArray(response.string)) ? response.string : (response[0] === undefined) ? [] : [response.string];
            this.nodeJsInteractionService.functionnalityAccess.setAccess(accessList);
            this.loading = false;
            this.router.navigate(['/home']);
        });
    }

    ngOnDestroy() {
        this.authentificationObservable.unsubscribe();
        this.getDistributionPointListObservable.unsubscribe();
        this.getFunctionnalityListObservable.unsubscribe();
        this.titleService.showMenu();
    }
}
