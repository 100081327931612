import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class FunctionnalityAccessService {

    getFunctionnalityListeObservable;
    functionnalityList = {
        MESSAGERIE_ENVOYER_MESSAGE_VERS_POINTS_DISTRIBUTION : false, // ok
        EXTRACTION_EXTRAIRE_RECHARGEMENT_POINT_DISTRIBUTION : false,
        EXTRACTION_EXTRAIRE_RECHARGEMENT_TOUS_POINTS_DISTRIBUTION : false, // ok
        DISTRIBUTION_AFFECTER_TITRES_AU_POINT_VENTE : false, // ok
        DISTRIBUTION_AFFECTER_PROFILS_AU_POINT_VENTE : false,
        DISTRIBUTION_GERER_TYPE_POINTS_DISTRIBUTION : false, // ok
        DISTRIBUTION_GERER_POINTS_DISTRIBUTION : false, // ok
        ENROLEMENT_GERER : false, // ok
        ENROLEMENT_FICHE : false,
        ENROLEMENT_SUSPENSION_POSTE : false,
        UTILISATEURS_GERER : false, // ok
        HISTORIQUE_ACCEDER_HISTORIQUE_RECHARGEMENT_TOUS : false, // ok
        RGPD_EXTRAIRE_INFORMATIONS : false,
        RGPD_ANONYMISER : false, // ok
        FAQ_GERER : false, // ok
        PARAMETRAGE_SYSTEME : false,
        PARAMETRAGE_POINT_DISTRIBUTION : false,
        PARAMETRAGE_POSTE_DISTRIBUTION : false,
        GESTION_THEMATIQUE : false,
        GESTION_FONCTIONNALITE : false


        // "MESSAGERIE_ENVOYER_MESSAGE_VERS_BACKOFFICE" : false,
        // "JUSTIFICATIF_IMPRIMER_A4" : false,
        // "JUSTIFICATIF_IMPRIMER_TICKET" : false,
        // "JUSTIFICATIF_REIMPRIMER_TICKET" : false,
        // "UTILISATEURS_CREER_UN_INTERVENANT" : false,
        // "CYCLEVENTE_ACCEDER_CONTENU_CARTE" : false,
        // "CYCLEVENTE_IMPRIMER_CONTENU_CARTE" : false,
        // "CYCLEVENTE_DISTRIBUER_TITRES" : false,
        // "CYCLEVENTE_DISTRIBUER_FORMULES" : false,
        // "CYCLEVENTE_REMBOURSER_TITRE" : false,
        // "CYCLEVENTE_DISTRIBUER_ABONNEMENT" : false,
        // "CYCLEVENTE_ANNULER_UN_TITRE" : false,
        // "HISTORIQUE_ACCEDER_HISTORIQUE_RECHARGEMENT" : false,
        // "FAQ_ACCEDER" : false, // ok

    };

    constructor(
        public router: Router
    ) {
        // Redirect to login if access has been reset
        if (JSON.parse(localStorage.getItem('functionnalityAccess')) === null) {
            this.router.navigate(['/login']);
        }
    }

    setAccess(accessList) {
        Object.keys(this.functionnalityList).forEach(key => {
            this.functionnalityList[key] = false;
        });
        accessList.forEach(functionnality => {
            if (this.functionnalityList[functionnality] !== undefined) {
                this.functionnalityList[functionnality] = true;
            }
        });
        localStorage.setItem('functionnalityAccess', JSON.stringify(this.functionnalityList));
    }

    checkAccess(functionnality) {
        const functionnalityAccessList = JSON.parse(localStorage.getItem('functionnalityAccess'));
        return (functionnalityAccessList) ? functionnalityAccessList[functionnality] : false;
    }
}
