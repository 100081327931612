import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
    providedIn: 'root'
})
export class TableExportService {

    constructor() { }

    exportToExcel(tableId: string, name?: string) {
        const timeSpan = new Date().toISOString();
        const prefix = name || 'Export';
        const fileName = `${prefix}-${timeSpan}`;
        const targetTableElm = document.getElementById(tableId);
        const ws = XLSX.utils.table_to_sheet(targetTableElm, { dateNF: 'dd/MM/yyyy' });
        const jsonData = XLSX.utils.sheet_to_json(ws, { raw: false }) as any;
        jsonData.forEach(data => {
            delete data.Actions;
            Object.keys(data).forEach(key => {
                data[key] = data[key].replace('.', ',');
                if (key.trim() === '') {
                    delete data[key];
                }
            });
        });
        const finalWs = XLSX.utils.json_to_sheet(jsonData, { dateNF: 'dd/MM/yyyy' });
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, finalWs, name);
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    }
}
