import { Component, OnInit } from '@angular/core';
import { NodeJsInteractionService } from '../_service/nodejs-interaction.service';
import { TitleService } from '../_service/title.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { KeycloakService } from 'keycloak-angular';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {

    constructor(
        public nodeJsInteractionService: NodeJsInteractionService,
        public titleService: TitleService,
        public router: Router,
        private location: Location,
        private keycloakService: KeycloakService
    ) { }

    ngOnInit() {
    }

    logout() {
        this.router.navigate(['/']).then(()=>{
            this.keycloakService.logout()});
    }

    isHomeRouteActivated(): boolean {
        return this.location.path().indexOf('/home') > -1;
    }
}
