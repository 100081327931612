import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FlatNode, NestedNode, DistributionPointDatabaseService } from '../_service/distribution-point-database.service';
import { SelectionModel } from '@angular/cdk/collections';

import {
    MatTreeFlatDataSource, MatTreeFlattener, MatDialog, MatSnackBar,
    MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource
} from '@angular/material';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NodeJsInteractionService } from '../_service/nodejs-interaction.service';
import { TitleService } from '../_service/title.service';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ProgressContainerComponent } from '../_ui/progress-container/progress-container.component';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationDialogComponent } from '../_ui/confirmation-dialog/confirmation-dialog.component';
import { TableExportService } from '../_service/table-export.service';
import { Router } from '@angular/router';
import { AppConfigService } from '../_service/app-config.service';

@Component({
    selector: 'user-create-dialog',
    templateUrl: 'user-create-dialog.html',
    providers: [
    ]
})
export class UserCreateDialogComponent {
    distributionPointList;
    form: FormGroup;
    constructor(
        public fb: FormBuilder,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<UserCreateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public appConfigService: AppConfigService
    ) {
        this.distributionPointList = data.distributionPointList;
        this.form = fb.group({
            id_point_distribution: [data.id_point_distribution, Validators.required],
            type_utilisateur: [data.type_utilisateur, Validators.required],
            nom: [data.nom, [Validators.required, Validators.maxLength(150)]],
            prenom: [data.prenom, [Validators.required, Validators.maxLength(150)]],
            pMotDePasse: [data.pMotDePasse, (data.ID === 0) ? Validators.required : null],
            statut: [(data.statut === 'Actif')]
        });
    }
    save() {
        if (this.form.invalid) {
            this.snackBar.open('La saisie n\'est pas valide, veuillez recommencer', null, {
                duration: 3000,
                panelClass: 'error'
            });
            this.dialogRef.close(false);
        } else {
            this.form.value.statut = (this.form.value.statut) ? 'Actif' : 'Suspendu';
            this.dialogRef.close(this.form.value);
        }
    }
    onNoClick(): void { this.dialogRef.close(); }
}

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.less'],
    providers: [
        { provide: 'instance1', useClass: DistributionPointDatabaseService },
        { provide: 'instance2', useClass: DistributionPointDatabaseService },
    ]
})
export class UserManagementComponent implements OnInit, OnDestroy {

    constructor(
        public titleService: TitleService,
        @Inject('instance1') public database: DistributionPointDatabaseService,
        @Inject('instance2') public databaseBackOffice: DistributionPointDatabaseService,
        public nodeJsInteractionService: NodeJsInteractionService,
        public overlay: Overlay,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public tableExportService: TableExportService,
        public router: Router
    ) {
        this.titleService.setTitle('Gestions des utilisateurs');
        this.titleService.setEcranNumber(7);
        if (!this.nodeJsInteractionService.functionnalityAccess.checkAccess('UTILISATEURS_GERER')) {
            this.router.navigate(['/home']);
        }

        this.dataSourceTable = new MatTableDataSource([]);
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren as any);
        this.treeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.treeFlattenerBackOffice = new MatTreeFlattener(
            this.transformerBackOffice, this.getLevel, this.isExpandable, this.getChildren as any
        );
        this.treeControlBackOffice = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.dataSourceBackOffice = new MatTreeFlatDataSource(this.treeControlBackOffice, this.treeFlattenerBackOffice);

        this.database.setNestedNodeKey('ID');
        this.database.setNestedNodeLibelle(this.nestedNodeLibelle);
        this.database.setFlatNodeKey('ID');
        this.database.setFlatNodeNestedKey('Idtypepointdistribution');
        this.database.setFlatNodeLibelle(this.flatNodeLibelle);
        database.dataChange.subscribe(data => {
            this.dataSource.data = data;
        });

        this.databaseBackOffice.setNestedNodeKey('ID');
        this.databaseBackOffice.setNestedNodeLibelle(this.nestedNodeLibelle);
        this.databaseBackOffice.setFlatNodeKey('ID');
        this.databaseBackOffice.setFlatNodeNestedKey('Idtypepointdistribution');
        this.databaseBackOffice.setFlatNodeLibelle(this.flatNodeLibelle);
        databaseBackOffice.dataChange.subscribe(data => {
            this.dataSourceBackOffice.data = data;
        });
    }
    selectedUser;
    flatNodeMap = new Map<FlatNode, NestedNode>();
    nestedNodeMap = new Map<NestedNode, FlatNode>();
    selectedParent: FlatNode | null = null;
    treeControl: FlatTreeControl<FlatNode>;
    treeFlattener: MatTreeFlattener<NestedNode, FlatNode>;
    dataSource: MatTreeFlatDataSource<NestedNode, FlatNode>;
    checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);

    flatNodeMapBackOffice = new Map<FlatNode, NestedNode>();
    nestedNodeMapBackOffice = new Map<NestedNode, FlatNode>();
    selectedParentBackOffice: FlatNode | null = null;
    treeControlBackOffice: FlatTreeControl<FlatNode>;
    treeFlattenerBackOffice: MatTreeFlattener<NestedNode, FlatNode>;
    dataSourceBackOffice: MatTreeFlatDataSource<NestedNode, FlatNode>;
    checklistSelectionBackOffice = new SelectionModel<FlatNode>(true /* multiple */);

    private overlayRef;
    dataSourceTable: MatTableDataSource<any>;
    dataSourceTableSelection = new SelectionModel<FlatNode>(true /* multiple */);

    nestedNodeLibelle = ['Libelle', 'Canal'];
    flatNodeLibelle = ['Libelle', 'NomResponsable'];
    tableDisplayedColumns: string[] = ['actions_user', 'TypeUtilisateur', 'IdExtrapro', 'Login', 'Nom', 'Prenom', 'Active'];

    private getDistributionPointTypeListObservable;
    private getDistributionPointListObservable;
    private selectedPoint;
    private getUserListObservable;
    private userUpdateObservable;
    private userCreateObservable;
    private userDeleteObservable;
    getLevel = (node: FlatNode) => node.level;
    isExpandable = (node: FlatNode) => node.expandable;
    getChildren = (node: NestedNode): FlatNode[] => node.children;
    hasChild = (_: number, nodeData: FlatNode) => nodeData.expandable;
    hasNoContent = (_: number, nodeData: FlatNode) => nodeData.Libelle === '';
    transformer = (node: NestedNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.Libelle === node.Libelle
            ? existingNode
            : new FlatNode();
        flatNode.Libelle = node.Libelle;
        flatNode.ID = node.ID;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }
    transformerBackOffice = (node: NestedNode, level: number) => {
        const existingNode = this.nestedNodeMapBackOffice.get(node);
        const flatNode = existingNode && existingNode.Libelle === node.Libelle
            ? existingNode
            : new FlatNode();
        flatNode.Libelle = node.Libelle;
        flatNode.ID = node.ID;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMapBackOffice.set(flatNode, node);
        this.nestedNodeMapBackOffice.set(node, flatNode);
        return flatNode;
    }
    attachOverlay() { this.overlayRef.attach(new ComponentPortal(ProgressContainerComponent)); }
    detachOverlay() { setTimeout(() => { this.overlayRef.detach(); }, 200); }
    ngOnInit() {
        this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });


        this.nodeJsInteractionService.getDistributionPointTypeList();
        this.getDistributionPointTypeListObservable = this.nodeJsInteractionService
            .getDistributionPointTypeListObservable.subscribe(data => {
                const response = data as any;
                const typePointDistributionList = (Array.isArray(response.TypePointDistribution)) ?
                    response.TypePointDistribution : [response.TypePointDistribution];
                this.database.setNestedNodeList(typePointDistributionList.filter(e => e.TypePoint === 'FrontOffice'));
                this.databaseBackOffice.setNestedNodeList(typePointDistributionList.filter(e => e.TypePoint === 'BackOffice'));
                this.nodeJsInteractionService.getDistributionPointList();
            });
        this.getDistributionPointListObservable = this.nodeJsInteractionService.getDistributionPointListObservable.subscribe(data => {
            const response = data as any;
            const pointDistributionList = (Array.isArray(response.PointDistribution)) ?
                response.PointDistribution : [response.PointDistribution];
            const flatNodeList = pointDistributionList.filter(point =>
                this.database.getNestedNodeList().some((type) => type.ID === point.Idtypepointdistribution)
            );
            const flatNodeListBackOffice = pointDistributionList.filter(point =>
                this.databaseBackOffice.getNestedNodeList().some((type) => type.ID === point.Idtypepointdistribution)
            );
            this.database.setFlatNodeList(flatNodeList);
            this.databaseBackOffice.setFlatNodeList(flatNodeListBackOffice);
            this.database.initialize();
            this.databaseBackOffice.initialize();
        });

        this.getUserListObservable = this.nodeJsInteractionService.getUserListObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            const dataSource = (Array.isArray(response.Utilisateur)) ? response.Utilisateur : [response.Utilisateur];
            this.dataSourceTable = new MatTableDataSource(dataSource.filter(elem => elem.id_point_distribution === this.selectedPoint.ID));
            this.dataSourceTable._updateChangeSubscription();
        });

        this.userUpdateObservable = this.nodeJsInteractionService.userUpdateObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            if (response.CodeErreur !== 0) {
                this.snackBar.open('Une erreur s\'est produite lors de la mise à jour de l\'utlisateur.', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
                return false;
            }
            this.snackBar.open('L\'utilisateur à bien été enregistré.', null, {
                duration: 3000,
                panelClass: 'success'
            });

            const updateRowIndex = this.dataSourceTable.data.findIndex(e => e.ID === response.ID);
            this.dataSourceTable.data[updateRowIndex] = response;
            this.dataSourceTable._updateChangeSubscription();
        });


        this.userCreateObservable = this.nodeJsInteractionService.userCreateObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            if (response.CodeErreur !== 0) {
                this.snackBar.open('Une erreur s\'est produite lors de la création de l\'utlisateur.', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
                return false;
            }
            this.snackBar.open('L\'utilisateur à bien été enregistré.', null, {
                duration: 3000,
                panelClass: 'success'
            });

            this.dataSourceTable.data.push(response);
            this.dataSourceTable._updateChangeSubscription();
        });

        this.userDeleteObservable = this.nodeJsInteractionService.userDeleteObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            if (response !== true) {
                this.snackBar.open('Une erreur s\'est produite lors de la suppression de l\'utlisateur.', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
                return false;
            }
            this.snackBar.open('L\'utilisateur à bien été supprimé.', null, {
                duration: 3000,
                panelClass: 'success'
            });

            this.dataSourceTable.data = this.dataSourceTable.data.filter((value) => {
                return value.ID !== this.selectedUser.ID;
            });
            this.dataSourceTable._updateChangeSubscription();
            this.selectedUser = null;
        });
    }

    ngOnDestroy() {
        this.getDistributionPointListObservable.unsubscribe();
        this.getDistributionPointTypeListObservable.unsubscribe();
        this.getUserListObservable.unsubscribe();
        this.userUpdateObservable.unsubscribe();
        this.userCreateObservable.unsubscribe();
        this.userDeleteObservable.unsubscribe();
    }

    exportToExcel() { this.tableExportService.exportToExcel('users-list-content-table', 'Utilisateurs'); }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.dataSourceTableSelection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }



    openUserCreateDialog(element): void {
        if (this.checklistSelection.selected.length === 0) {
            this.snackBar.open('Veuillez sélectionner un point de distribution à gauche de l\'écran avant d\'ajouter un utilisateur.',
                null, {
                duration: 3000,
                panelClass: 'infos'
            });
            return;
        }

        const elementData = element || null;
        let canvas = {
            ID: 0, id_point_distribution: this.checklistSelection.selected[0].ID, type_utilisateur: '',
            nom: '', prenom: '', pMotDePasse: '', statut: '', id_iam: '', distributionPointList: ''
        };

        if (elementData !== null) {
            const nestedElement = this.dataSourceTable.data.find(e => e.ID === element.ID);
            canvas = nestedElement;
        }

        canvas.distributionPointList = this.checklistSelection.selected as any;
        const dialogRef = this.dialog.open(UserCreateDialogComponent, {
            width: '600px',
            data: canvas,
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== false && result !== undefined) {
                result.ID = canvas.ID;
                result.id_iam = canvas.id_iam;
                result.supprime = (result.supprime) ? 1 : 0;
                result.token = '';
                result.token_date_expiration = '9999-12-31';
                result.CodeErreur = 0;
                result.login = '';
                this.attachOverlay();
                if (result.ID !== 0) {
                    delete result.pMotDePasse;
                    this.nodeJsInteractionService.userUpdate(result);
                } else {
                    this.nodeJsInteractionService.userCreate(result);
                }
            }
        });
    }

    openDeleteUserConfirmationDialog(element): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: '<h6>Êtes vous sur de vouloir supprimer cet utilisateur ? </h6>' +
                'L\'ensemble des éléments associés à celui-ci seront également supprimés. <br/>'
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.attachOverlay();
                this.selectedUser = element;
                this.nodeJsInteractionService.userDelete(element.ID);
            }
        });
    }

    todoLeafItemSelectionToggle(node: FlatNode): void {
        this.attachOverlay();
        this.nodeJsInteractionService.getUserList();
        this.selectedPoint = node;
        this.checklistSelection.clear();
        this.checklistSelectionBackOffice.clear();
        this.checklistSelection.select(node);
    }

}
