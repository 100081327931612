import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NodeJsInteractionService } from './nodejs-interaction.service';

export class CategoryNode {
    ID: number;
    children: FaqFlatNode[];
    Libelle: string;
}

export class FaqFlatNode {
    ID: number;
    Libelle: string;
    level: number;
    expandable = false;
}

@Injectable({
    providedIn: 'root'
})
export class FaqDatabaseService {
    faqData = null;
    ctgFaqList;
    faqList;
    cptDistributionPöintType;
    typeDistributionPoint = [];
    typeDistributionPointChecked = {};
    typeDistributionPointFaqAssociation = {};

    dataChange = new BehaviorSubject<CategoryNode[]>([]);

    get data(): CategoryNode[] { return this.dataChange.value; }

    constructor(public nodeJsInteractionService: NodeJsInteractionService) {
    }

    getCtgFaqListObservable;
    getFaqListObservable;
    getDistributionPointTypeFaqAssociationListObservable;
    faqCreateObservable;
    getDistributionPointTypeListObservable;
    initialize() {
        const self = this;
        this.nodeJsInteractionService.getCtgFaqList();
        this.nodeJsInteractionService.getDistributionPointTypeList();

        this.getCtgFaqListObservable = this.nodeJsInteractionService.getCtgFaqListObservable.subscribe(data => {
            const response = data as any;
            this.ctgFaqList = response.CategorieFaq;
            this.nodeJsInteractionService.getFaqList();
        });

        this.getFaqListObservable = this.nodeJsInteractionService.getFaqListObservable.subscribe(data => {
            const response = data as any;
            this.faqList = response.Faq;
            this.assocFaqCtg();
            this.dataChange.next(this.faqData);
        });

        this.getDistributionPointTypeFaqAssociationListObservable = this.nodeJsInteractionService
            .getDistributionPointTypeFaqAssociationListObservable.subscribe(data => {
                const response = data as any;
                const listKey = Object.keys(this.typeDistributionPointChecked);
                const currentKey = listKey[this.cptDistributionPöintType];
                const nextKey = listKey[this.cptDistributionPöintType + 1];
                const listAssoc = (Array.isArray(response.int)) ? response.int : [response.int];
                this.typeDistributionPointFaqAssociation[currentKey] = listAssoc;
                this.cptDistributionPöintType++;
                if (nextKey !== undefined) {
                    this.nodeJsInteractionService.getDistributionPointTypeFaqAssociationList(nextKey);
                }
            });

        this.faqCreateObservable = this.nodeJsInteractionService.faqCreateObservable.subscribe(data => {
            const response = data as any;
            this.faqList.push(response);
            const newFaqNode = this.findNode(this.data, undefined);
            newFaqNode.ID = response.ID;
            this.dataChange.next(this.data);
        });


        this.getDistributionPointTypeListObservable = this.nodeJsInteractionService
            .getDistributionPointTypeListObservable.subscribe(data => {
                const response = data as any;
                this.typeDistributionPoint = (Array.isArray(response.TypePointDistribution)) ?
                    response.TypePointDistribution : [response.TypePointDistribution];
                this.typeDistributionPoint.forEach((t) => {
                    self.typeDistributionPointChecked[t.ID] = false;
                });

                this.cptDistributionPöintType = 0;
                this.nodeJsInteractionService.getDistributionPointTypeFaqAssociationList(Object.keys(this.typeDistributionPointChecked)[0]);
            });
    }

    destroy() {
        this.getCtgFaqListObservable.unsubscribe();
        this.getFaqListObservable.unsubscribe();
        this.getDistributionPointTypeFaqAssociationListObservable.unsubscribe();
        this.faqCreateObservable.unsubscribe();
        this.getDistributionPointTypeListObservable.unsubscribe();
    }

    assocFaqCtg() {
        const self = this;
        const ctgTree = {};
        this.ctgFaqList.forEach((ctg) => {
            ctgTree[ctg.Libelle] = [];
            self.faqList.forEach((faq) => {
                if (faq.IdCategorie.toString() === ctg.ID.toString()) {
                    ctgTree[ctg.Libelle].push(faq);
                }
            });
        });

        this.faqData = this.buildFileTree(ctgTree, 0);
    }

    findNode(currentNode, id) {
        const checkId = id || undefined;
        if (currentNode instanceof FaqFlatNode) {
            if (currentNode.ID === checkId) {
                return currentNode;
            }
        } else {
            const list = (currentNode.children) ? currentNode.children : currentNode;
            for (const node of list) {
                const result = this.findNode(node, checkId);
                if (result !== false) {
                    return result;
                }
            }
        }
        return false;
    }

    getFaqAssoc(node) {
        const assocFaq = this.faqList.filter(
            element => element.ID === node.ID
        )[0];
        return assocFaq;
    }


    getCtg(node) {
        const ctg = this.ctgFaqList.filter(
            element => element.Libelle === node.Libelle
        )[0];
        return ctg;
    }

    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `CategoryNode`.
     */
    buildFileTree(obj: { [key: string]: any }, level: number): CategoryNode[] {
        const self = this;
        return Object.keys(obj).reduce<CategoryNode[]>((accumulator, key) => {
            const value = obj[key];
            const node: any = (typeof value === 'object' && value.IdCategorie === undefined) ? new CategoryNode() : new FaqFlatNode();
            node.Libelle = key;
            if (value != null) {
                if (typeof value === 'object' && value.IdCategorie === undefined) {
                    node.ID = this.ctgFaqList.find(e => e.Libelle === key).ID;
                    node.children = this.buildFileTree(value, level + 1);
                } else {
                    node.Libelle = value.Question;
                    node.ID = value.ID;
                }
            }

            return accumulator.concat(node);
        }, []);
    }

    insertCtgItem(data) {
        const node = new CategoryNode();
        node.Libelle = data.Libelle;
        node.children = [];
        this.data.push(node);
        this.ctgFaqList.push(data);
        this.dataChange.next(this.data);
    }

    insertItem(parent: CategoryNode, name: string) {
        if (parent.children) {
            const node = new FaqFlatNode();
            node.Libelle = name;
            parent.children.push(node);
            this.dataChange.next(this.data);
        }
    }

    updateCtgItem(node: CategoryNode) {

    }

    updateItem(node: FaqFlatNode, faqNode: any) {
        const updateNode = this.findNode(this.data, node.ID);
        updateNode.Libelle = faqNode.Question;
        this.dataChange.next(this.data);
    }

    removeCtgItem(parent: CategoryNode) {
        const indexOf = this.data.indexOf(parent);
        this.data.splice(indexOf, 1);
        this.dataChange.next(this.data);
    }

    removeItem(parent: CategoryNode, node: FaqFlatNode) {
        const childOfPrent = parent.children.filter(
            element => element.Libelle === node.Libelle
        )[0];
        const indexOf = parent.children.indexOf(childOfPrent);

        const faqIndexOf = this.faqList.indexOf(this.getFaqAssoc(childOfPrent));
        this.faqList.splice(faqIndexOf, 1);
        parent.children.splice(indexOf, 1);
        this.dataChange.next(this.data);
    }
}
