import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FunctionnalityAccessService } from './functionnality-access.service';
import { SocketService } from '../socket.service';

@Injectable({
    providedIn: 'root'
})
export class NodeJsInteractionService {

    constructor(
        public socket: SocketService,
        public router: Router,
        public dialog: MatDialog,
        public functionnalityAccess: FunctionnalityAccessService
    ) {
    }

    // ======================================================================
    // DISTRIBUTION POINT OBSERVABLE
    getDistributionPointObservable = this.socket.fromEvent<object>('getDistributionPoint');
    getDistributionPointListObservable = this.socket.fromEvent<object>('getDistributionPointList');
    distributionPointCreateObservable = this.socket.fromEvent<object>('distributionPointCreate');
    distributionPointUpdateObservable = this.socket.fromEvent<object>('distributionPointUpdate');
    distributionPointDeleteObservable = this.socket.fromEvent<object>('distributionPointDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // DISTRIBUTION POINT PARAMETRAGE OBSERVABLE
    getDistributionPointSettingObservable = this.socket.fromEvent<object>('getDistributionPointSetting');
    getDistributionPointSettingListObservable = this.socket.fromEvent<object>('getDistributionPointSettingList');
    distributionPointSettingCreateObservable = this.socket.fromEvent<object>('distributionPointSettingCreate');
    distributionPointSettingUpdateObservable = this.socket.fromEvent<object>('distributionPointSettingUpdate');
    distributionPointSettingDeleteObservable = this.socket.fromEvent<object>('distributionPointSettingDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // DISTRIBUTION POINT TYPE OBSERVABLE
    getDistributionPointTypeObservable = this.socket.fromEvent<object>('getDistributionPointType');
    getDistributionPointTypeListObservable = this.socket.fromEvent<object>('getDistributionPointTypeList');
    distributionPointTypeCreateObservable = this.socket.fromEvent<object>('distributionPointTypeCreate');
    distributionPointTypeUpdateObservable = this.socket.fromEvent<object>('distributionPointTypeUpdate');
    distributionPointTypeDeleteObservable = this.socket.fromEvent<object>('distributionPointTypeDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // DISTRIBUTION POINT TYPE FAQ ASSOCIATION OBSERVABLE
    getDistributionPointTypeFaqAssociationListObservable = this.socket.fromEvent<object>('getDistributionPointTypeFaqAssociationList');
    distributionPointTypeFaqAssociationCreateObservable = this.socket.fromEvent<object>('distributionPointTypeFaqAssociationCreate');
    distributionPointTypeFaqAssociationDeleteObservable = this.socket.fromEvent<object>('distributionPointTypeFaqAssociationDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // DISTRIBUTION POINT TYPE FUNCTIONALITY ASSOCIATION OBSERVABLE
    getDistributionPointTypeFunctionalityAssociationListObservable =
        this.socket.fromEvent<object>('getDistributionPointTypeFunctionalityAssociationList');
    distributionPointTypeFunctionalityAssociationCreateObservable =
        this.socket.fromEvent<object>('distributionPointTypeFunctionalityAssociationCreate');
    distributionPointTypeFunctionalityAssociationDeleteObservable =
        this.socket.fromEvent<object>('distributionPointTypeFunctionalityAssociationDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // DISTRIBUTION POINT TYPE PRODUCT ASSOCIATION OBSERVABLE
    getDistributionPointTypeProductAssociationListObservable =
        this.socket.fromEvent<object>('getDistributionPointTypeProductAssociationList');
    distributionPointTypeProductAssociationCreateObservable =
        this.socket.fromEvent<object>('distributionPointTypeProductAssociationCreate');
    distributionPointTypeProductAssociationDeleteObservable =
        this.socket.fromEvent<object>('distributionPointTypeProductAssociationDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // DISTRIBUTION POINT TYPE PROFIL ASSOCIATION OBSERVABLE
    getDistributionPointTypeProfilAssociationListObservable =
        this.socket.fromEvent<object>('getDistributionPointTypeProfilAssociationList');
    distributionPointTypeProfilAssociationCreateObservable =
        this.socket.fromEvent<object>('distributionPointTypeProfilAssociationCreate');
    distributionPointTypeProfilAssociationDeleteObservable =
        this.socket.fromEvent<object>('distributionPointTypeProfilAssociationDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // FAQ CRUD + CTG FAQ OBSERVABLE
    getFaqObservable = this.socket.fromEvent<object>('getFaq');
    getFaqListObservable = this.socket.fromEvent<object>('getFaqList');
    faqCreateObservable = this.socket.fromEvent<object>('faqCreate');
    faqUpdateObservable = this.socket.fromEvent<object>('faqUpdate');
    faqDeleteObservable = this.socket.fromEvent<object>('faqDelete');

    getCtgFaqObservable = this.socket.fromEvent<object>('getCtgFaq');
    getCtgFaqListObservable = this.socket.fromEvent<object>('getCtgFaqList');
    ctgFaqCreateObservable = this.socket.fromEvent<object>('ctgFaqCreate');
    ctgFaqUpdateObservable = this.socket.fromEvent<object>('ctgFaqUpdate');
    ctgFaqDeleteObservable = this.socket.fromEvent<object>('ctgFaqDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // FUNCTIONALITY OBSERVABLE
    getFunctionalityObservable = this.socket.fromEvent<object>('getFunctionality');
    getFunctionalityListObservable = this.socket.fromEvent<object>('getFunctionalityList');
    functionalityCreateObservable = this.socket.fromEvent<object>('functionalityCreate');
    functionalityUpdateObservable = this.socket.fromEvent<object>('functionalityUpdate');
    functionalityDeleteObservable = this.socket.fromEvent<object>('functionalityDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // MESSAGE OBSERVABLE
    getMessageObservable = this.socket.fromEvent<object>('getMessage');
    getMessageListObservable = this.socket.fromEvent<object>('getMessageList');
    getMessageListByUserObservable = this.socket.fromEvent<object>('getMessageListByUser');
    messageCreateObservable = this.socket.fromEvent<object>('messageCreate');
    messageCreateWithAssociationObservable = this.socket.fromEvent<object>('messageCreateWithAssociation');
    messageUpdateObservable = this.socket.fromEvent<object>('messageUpdate');
    messageDeleteObservable = this.socket.fromEvent<object>('messageDelete');
    messageUpdateStatusObservable = this.socket.fromEvent<object>('messageUpdateStatus');
    // FIN
    // ======================================================================
    // ======================================================================
    // MESSAGE THEMATIC OBSERVABLE
    getThematicMessageObservable = this.socket.fromEvent<object>('getThematicMessage');
    getThematicMessageListObservable = this.socket.fromEvent<object>('getThematicMessageList');
    thematicMessageCreateObservable = this.socket.fromEvent<object>('thematicMessageCreate');
    thematicMessageUpdateObservable = this.socket.fromEvent<object>('thematicMessageUpdate');
    thematicMessageDeleteObservable = this.socket.fromEvent<object>('thematicMessageDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // PAYMENT TYPECRUD OBSERVABLE
    getPaymentTypeObservable = this.socket.fromEvent<object>('getPaymentType');
    getPaymentTypeListObservable = this.socket.fromEvent<object>('getPaymentTypeList');
    paymentTypeCreateObservable = this.socket.fromEvent<object>('paymentTypeCreate');
    paymentTypeUpdateObservable = this.socket.fromEvent<object>('paymentTypeUpdate');
    paymentTypeDeleteObservable = this.socket.fromEvent<object>('paymentTypeDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // SYSTEM SETTINGS OBSERVABLE
    getSystemSettingObservable = this.socket.fromEvent<object>('getSystemSetting');
    getSystemSettingListObservable = this.socket.fromEvent<object>('getSystemSettingList');
    systemSettingCreateObservable = this.socket.fromEvent<object>('systemSettingCreate');
    systemSettingUpdateObservable = this.socket.fromEvent<object>('systemSettingUpdate');
    systemSettingDeleteObservable = this.socket.fromEvent<object>('systemSettingDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // OTHER LIST OBSERVABLE
    getCanalListObservable = this.socket.fromEvent<object>('getCanalList');
    getGtListObservable = this.socket.fromEvent<object>('getGtList');
    getReloadHistoryObservable = this.socket.fromEvent<object>('getReloadHistory');
    getSalesJournalObservable = this.socket.fromEvent<object>('getSalesJournal');
    getTicketObservable = this.socket.fromEvent<object>('getTicket');
    getCancelTicketObservable = this.socket.fromEvent<object>('getCancelTicket');
    getCancelTicketByNumOrderObservable = this.socket.fromEvent<object>('getCancelTicketByNumOrder');
    getSvdProfilListObservable = this.socket.fromEvent<object>('getSvdProfilList');
    getReloadingSummaryListObservable = this.socket.fromEvent<object>('getReloadingSummaryList');
    getSessionListSummaryObservable = this.socket.fromEvent<object>('getSessionListSummary');
    rgpdDiscretizationObservable = this.socket.fromEvent<object>('rgpdDiscretization');
    // FIN
    // ======================================================================
    // ======================================================================
    // USER OBSERVABLE
    getUserObservable = this.socket.fromEvent<object>('getUser');
    getUserListObservable = this.socket.fromEvent<object>('getUserList');
    userCreateObservable = this.socket.fromEvent<object>('userCreate');
    userUpdateObservable = this.socket.fromEvent<object>('userUpdate');
    userDeleteObservable = this.socket.fromEvent<object>('userDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // USER MESSAGE ASSOCIATION OBSERVABLE
    getUserMessageAssociationListObservable = this.socket.fromEvent<object>('getUserMessageAssociationList');
    getUserMessageAssociationListObjectObservable = this.socket.fromEvent<object>('getUserMessageAssociationListObject');
    userMessageAssociationCreateObservable = this.socket.fromEvent<object>('userMessageAssociationCreate');
    userMessageAssociationDeleteObservable = this.socket.fromEvent<object>('userMessageAssociationDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // USER THEMATIC MESSAGE ASSOCIATION OBSERVABLE
    getUserThematicMessageAssociationListObservable = this.socket.fromEvent<object>('getUserThematicMessageAssociationList');
    getUserThematicMessageAssociationListOfThematicMessageObservable =
        this.socket.fromEvent<object>('getUserThematicMessageAssociationListOfThematicMessage');
    getUserThematicMessageAssociationListOfUserObservable = this.socket.fromEvent<object>('getUserThematicMessageAssociationListOfUser');
    userThematicMessageAssociationCreateObservable = this.socket.fromEvent<object>('userThematicMessageAssociationCreate');
    userThematicMesasgeAssociationDeleteObservable = this.socket.fromEvent<object>('userThematicMesasgeAssociationDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // WORKSTATION OBSERVABLE
    getWorkstationObservable = this.socket.fromEvent<object>('getWorkstation');
    getWorkstationListObservable = this.socket.fromEvent<object>('getWorkstationList');
    workstationtCreateObservable = this.socket.fromEvent<object>('workstationtCreate');
    workstationtUpdateObservable = this.socket.fromEvent<object>('workstationtUpdate');
    workstationtDeleteObservable = this.socket.fromEvent<object>('workstationtDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // WORKSTATION SETTING OBSERVABLE
    getWorkstationSettingObservable = this.socket.fromEvent<object>('getWorkstationSetting');
    getWorkstationSettingListObservable = this.socket.fromEvent<object>('getWorkstationSettingList');
    workstationtSettingCreateObservable = this.socket.fromEvent<object>('workstationtSettingCreate');
    workstationtSettingUpdateObservable = this.socket.fromEvent<object>('workstationtSettingUpdate');
    workstationtSettingDeleteObservable = this.socket.fromEvent<object>('workstationtSettingDelete');
    // FIN
    // ======================================================================
    // ======================================================================
    // SUPPORT OBSERVABLE
    getSupportObservable = this.socket.fromEvent<object>('getSupport');
    getSupportListObservable = this.socket.fromEvent<object>('getSupportList');
    supportCreateObservable = this.socket.fromEvent<object>('supportCreate');
    supportUpdateObservable = this.socket.fromEvent<object>('supportUpdate');
    supportDeleteObservable = this.socket.fromEvent<object>('supportDelete');
    authentificationObservable = this.socket.fromEvent<object>('authentification');
    getFunctionnalityListObservable = this.socket.fromEvent<object>('getFunctionnalityList');
    svdConnexionTestObservable = this.socket.fromEvent<object>('svdConnexionTest');
    // FIN
    // ======================================================================
    // ======================================================================
    // HYBRIDE FICHIER API MANAGEMENT
    getApiManagementConfObservable = this.socket.fromEvent<object>('getApiManagementConf');
    // FIN
    // ======================================================================

    // ======================================================================
    // DISTRIBUTION POINT CRUD
    getDistributionPoint(pId) { this.socket.emit('getDistributionPoint', { pId }); }
    getDistributionPointList() { this.socket.emit('getDistributionPointList'); }
    distributionPointCreate(data) { this.socket.emit('distributionPointCreate', data); }
    distributionPointUpdate(data) { this.socket.emit('distributionPointUpdate', data); }
    distributionPointDelete(pId) { this.socket.emit('distributionPointDelete', { pId }); }
    // FIN
    // ======================================================================
    // ======================================================================
    // DISTRIBUTION POINT PARAMETRAGE CRUD
    getDistributionPointSetting(pId) { this.socket.emit('getDistributionPointSetting', { pId }); }
    getDistributionPointSettingList(pId) { this.socket.emit('getDistributionPointSettingList', { pId }); }
    distributionPointSettingCreate(data) { this.socket.emit('distributionPointSettingCreate', data); }
    distributionPointSettingUpdate(data) { this.socket.emit('distributionPointSettingUpdate', data); }
    distributionPointSettingDelete(pId) { this.socket.emit('distributionPointSettingDelete', { pId }); }
    // FIN
    // ======================================================================
    // ======================================================================
    // DISTRIBUTION POINT TYPE CRUD
    getDistributionPointType(pId) { this.socket.emit('getDistributionPointType', { pId }); }
    getDistributionPointTypeList() { this.socket.emit('getDistributionPointTypeList'); }
    distributionPointTypeCreate(data) { this.socket.emit('distributionPointTypeCreate', data); }
    distributionPointTypeUpdate(data) { this.socket.emit('distributionPointTypeUpdate', data); }
    distributionPointTypeDelete(pId) { this.socket.emit('distributionPointTypeDelete', { pId }); }
    // FIN
    // ======================================================================
    // ======================================================================
    // DISTRIBUTION POINT TYPE FAQ ASSOCIATION CRUD
    getDistributionPointTypeFaqAssociationList(id) {
        this.socket.emit('getDistributionPointTypeFaqAssociationList', { IdTypePointDistribution: id });
    }
    distributionPointTypeFaqAssociationCreate(data) { this.socket.emit('distributionPointTypeFaqAssociationCreate', data); }
    distributionPointTypeFaqAssociationDelete(data) { this.socket.emit('distributionPointTypeFaqAssociationDelete', data); }
    // FIN
    // ======================================================================
    // ======================================================================
    // DISTRIBUTION POINT TYPE FUNCTIONALITY ASSOCIATION CRUD
    getDistributionPointTypeFunctionalityAssociationList(idType) {
        this.socket.emit('getDistributionPointTypeFunctionalityAssociationList', { IdTypePointDistribution: idType });
    }
    distributionPointTypeFunctionalityAssociationCreate(data) {
        this.socket.emit('distributionPointTypeFunctionalityAssociationCreate', data);
    }
    distributionPointTypeFunctionalityAssociationDelete(data) {
        this.socket.emit('distributionPointTypeFunctionalityAssociationDelete', data);
    }
    // FIN
    // ======================================================================
    // ======================================================================
    // DISTRIBUTION POINT TYPE PRODUCT ASSOCIATION CRUD
    getDistributionPointTypeProductAssociationList(idType) {
        this.socket.emit('getDistributionPointTypeProductAssociationList', { IdTypePointDistribution: idType });
    }
    distributionPointTypeProductAssociationCreate(data) {
        this.socket.emit('distributionPointTypeProductAssociationCreate', data);
    }
    distributionPointTypeProductAssociationDelete(data) {
        this.socket.emit('distributionPointTypeProductAssociationDelete', data);
    }
    // FIN
    // ======================================================================
    // ======================================================================
    // DISTRIBUTION POINT TYPE PROFIL ASSOCIATION CRUD
    getDistributionPointTypeProfilAssociationList(idType) {
        this.socket.emit('getDistributionPointTypeProfilAssociationList', { IdTypePointDistribution: idType });
    }
    distributionPointTypeProfilAssociationCreate(data) {
        this.socket.emit('distributionPointTypeProfilAssociationCreate', data);
    }
    distributionPointTypeProfilAssociationDelete(data) {
        this.socket.emit('distributionPointTypeProfilAssociationDelete', data);
    }
    // FIN
    // ======================================================================
    // ======================================================================
    // FAQ CRUD + CTG FAQ CRUD
    getFaq(pId) { this.socket.emit('getFaq', { pId }); }
    getFaqList() { this.socket.emit('getFaqList'); }
    faqUpdate(data) { this.socket.emit('faqUpdate', data); }
    faqCreate(data) { this.socket.emit('faqCreate', data); }
    faqDelete(pId) { this.socket.emit('faqDelete', { pId }); }

    getCtgFaq(pId) { this.socket.emit('getCtgFaq', { pId }); }
    getCtgFaqList() { this.socket.emit('getCtgFaqList'); }
    ctgFaqUpdate(data) { this.socket.emit('ctgFaqUpdate', data); }
    ctgFaqCreate(data) { this.socket.emit('ctgFaqCreate', data); }
    ctgFaqDelete(pId) { this.socket.emit('ctgFaqDelete', { pId }); }
    // FIN
    // ======================================================================
    // ======================================================================
    // FUNCTIONALITY CRUD
    getFunctionality(pId) { this.socket.emit('getFunctionality', { pId }); }
    getFunctionalityList() { this.socket.emit('getFunctionalityList'); }
    functionalityCreate(data) { this.socket.emit('functionalityCreate', data); }
    functionalityUpdate(data) { this.socket.emit('functionalityUpdate', data); }
    functionalityDelete(pId) { this.socket.emit('functionalityDelete', { pId }); }
    // FIN
    // ======================================================================
    // ======================================================================
    // MESSAGE CRUD
    getMessage(pId) { this.socket.emit('getMessage', { pId }); }
    getMessageListByUser(pId) { this.socket.emit('getMessageListByUser', { pId }); }
    messageCreate(data) { this.socket.emit('messageCreate', data); }
    messageCreateWithAssociation(data) { this.socket.emit('messageCreateWithAssociation', data); }
    messageUpdate(data) { this.socket.emit('messageUpdate', data); }
    messageDelete(pId) { this.socket.emit('messageDelete', { pId }); }
    messageUpdateStatus(data) { this.socket.emit('messageUpdateStatus', data); }
    // FIN
    // ======================================================================
    // ======================================================================
    // MESSAGE THEMATIC CRUD
    getThematicMessage(pId) { this.socket.emit('getThematicMessage', { pId }); }
    getThematicMessageList() { this.socket.emit('getThematicMessageList'); }
    thematicMessageCreate(data) { this.socket.emit('thematicMessageCreate', data); }
    thematicMessageUpdate(data) { this.socket.emit('thematicMessageUpdate', data); }
    thematicMessageDelete(pId) { this.socket.emit('thematicMessageDelete', { pId }); }
    // FIN
    // ======================================================================
    // ======================================================================
    // PAYMENT TYPE CRUD
    getPaymentType(pId) { this.socket.emit('getPaymentType', { pId }); }
    getPaymentTypeList() { this.socket.emit('getPaymentTypeList'); }
    paymentTypeCreate(data) { this.socket.emit('paymentTypeCreate', data); }
    paymentTypeUpdate(data) { this.socket.emit('paymentTypeUpdate', data); }
    paymentTypeDelete(pId) { this.socket.emit('paymentTypeDelete', { pId }); }
    // FIN
    // ======================================================================
    // ======================================================================
    // OTHER LIST CRUD
    getCanalList() { this.socket.emit('getCanalList'); }
    getGtList(pCanal) { this.socket.emit('getGtList', { pCanal }); }
    getReloadHistory(data) { this.socket.emit('getReloadHistory', data); }
    getSalesJournal(pIdSession) { this.socket.emit('getSalesJournal', { pIdSession }); }
    getTicket(idRechargement) { this.socket.emit('getTicket', { IdRechargement: idRechargement }); }
    getCancelTicket(pIdRechargement) { this.socket.emit('getCancelTicket', { pIdRechargement }); }
    getCancelTicketByNumOrder(pNumeroCommande) { this.socket.emit('getCancelTicketByNumOrder', { pNumeroCommande }); }
    getSvdProfilList(pCanal) { this.socket.emit('getSvdProfilList', { pCanal }); }
    getReloadingSummaryList(data) { this.socket.emit('getReloadingSummaryList', data); }
    getSessionListSummary(data) { this.socket.emit('getSessionListSummary', data); }
    rgpdDiscretization(support) { this.socket.emit('rgpdDiscretization', { NumeroSupport: support.toString() }); }
    // FIN
    // ======================================================================
    // ======================================================================
    // SYSTEM SETTINGS CRUD
    getSystemSetting(pId) { this.socket.emit('getSystemSetting', { pId }); }
    getSystemSettingList() { this.socket.emit('getSystemSettingList'); }
    systemSettingCreate(data) { this.socket.emit('systemSettingCreate', data); }
    systemSettingUpdate(data) { this.socket.emit('systemSettingUpdate', data); }
    systemSettingDelete(pId) { this.socket.emit('systemSettingDelete', { pId }); }
    // FIN
    // ======================================================================
    // ======================================================================
    // USER CRUD
    getUser(pId) { this.socket.emit('getUser', { pId }); }
    getUserList() { this.socket.emit('getUserList'); }
    userCreate(data) { this.socket.emit('userCreate', data); }
    userUpdate(data) { this.socket.emit('userUpdate', data); }
    userDelete(pId) { this.socket.emit('userDelete', { pId }); }
    // FIN
    // ======================================================================
    // ======================================================================
    // USER MESSAGE ASSOCIATION CRUD
    getUserMessageAssociationList(pIdUtilisateur) { this.socket.emit('getUserMessageAssociationList', { pIdUtilisateur }); }
    getUserMessageAssociationListObject(IdUtilisateur) { this.socket.emit('getUserMessageAssociationListObject', { IdUtilisateur }); }
    userMessageAssociationCreate(data) { this.socket.emit('userMessageAssociationCreate', data); }
    userMessageAssociationDelete(data) { this.socket.emit('userMessageAssociationDelete', data); }
    // FIN
    // ======================================================================
    // ======================================================================
    // USER THEMATIC MESSAGE ASSOCIATION CRUD
    getUserThematicMessageAssociationList(IdUtilisateur) {
        this.socket.emit('getUserThematicMessageAssociationList', { IdUtilisateur });
    }
    getUserThematicMessageAssociationListOfThematicMessage(pIdUtilisateur) {
        this.socket.emit('getUserThematicMessageAssociationListOfThematicMessage', { pIdUtilisateur });
    }
    getUserThematicMessageAssociationListOfUser(pIdThematique) {
        this.socket.emit('getUserThematicMessageAssociationListOfUser', { pIdThematique });
    }
    userThematicMessageAssociationCreate(data) { this.socket.emit('userThematicMessageAssociationCreate', data); }
    userThematicMessageAssociationDelete(data) { this.socket.emit('userThematicMessageAssociationDelete', data); }
    // FIN
    // ======================================================================
    // ======================================================================
    // WORKSTATION CRUD
    getWorkstation(pId) { this.socket.emit('getWorkstation', { pId }); }
    getWorkstationList(pId) { this.socket.emit('getWorkstationList', { pId }); }
    workstationtCreate(data) { this.socket.emit('workstationtCreate', data); }
    workstationtUpdate(data) { this.socket.emit('workstationtUpdate', data); }
    workstationtDelete(pId) { this.socket.emit('workstationtDelete', { pId }); }
    // FIN
    // ======================================================================
    // ======================================================================
    // WORKSTATION SETTING CRUD
    getWorkstationSetting(pId) { this.socket.emit('getWorkstationSetting', { pId }); }
    getWorkstationSettingList(pId) { this.socket.emit('getWorkstationSettingList', { pId }); }
    workstationtSettingCreate(data) { this.socket.emit('workstationtSettingCreate', data); }
    workstationtSettingUpdate(data) { this.socket.emit('workstationtSettingUpdate', data); }
    workstationtSettingDelete(pId) { this.socket.emit('workstationtSettingDelete', { pId }); }
    // FIN
    // ======================================================================
    // ======================================================================
    // SUPPORT CRUD
    getSupport(pId) { this.socket.emit('getSupport', { pId }); }
    getSupportList() { this.socket.emit('getSupportList'); }
    supportCreate(data) { this.socket.emit('supportCreate', data); }
    supportUpdate(data) { this.socket.emit('supportUpdate', data); }
    supportDelete(pId) { this.socket.emit('supportDelete', { pId }); }
    // FIN
    // ======================================================================
    // ======================================================================
    // HYBRIDE FICHIER API MANAGEMENT
    getApiManagementConf(data) { this.socket.emit('getApiManagementConf', data); }
    // FIN
    // ======================================================================

    authentification(id: string) { this.socket.emit('authentification', { IdUtilisateur: id }); }
    getFunctionnalityList(distributionTypePointId) {
        this.socket.emit('getFunctionnalityList', { IdTypePointDistribution: distributionTypePointId });
    }
    svdConnexionTest(url: string) { this.socket.emit('svdConnexionTest', { pUrl: url }); }
}
