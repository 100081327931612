import { Component, OnInit, Inject, ViewChild, OnDestroy } from "@angular/core";
import {
  FlatNode,
  NestedNode,
  DistributionPointDatabaseService,
} from "../_service/distribution-point-database.service";
import { SelectionModel } from "@angular/cdk/collections";

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatDialog,
  MatSnackBar,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatTableDataSource,
  MatSort,
} from "@angular/material";
import { FlatTreeControl } from "@angular/cdk/tree";
import { NodeJsInteractionService } from "../_service/nodejs-interaction.service";
import { TitleService } from "../_service/title.service";
import { Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { ProgressContainerComponent } from "../_ui/progress-container/progress-container.component";
import {
  Validators,
  FormGroup,
  FormBuilder,
  FormControl,
} from "@angular/forms";
import { ConfirmationDialogComponent } from "../_ui/confirmation-dialog/confirmation-dialog.component";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { TableExportService } from "../_service/table-export.service";
import { MY_FORMAT } from "src/environments/environment";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AppConfigService } from "../_service/app-config.service";

// ==================================================================================================================================
// DEFINITION DIALOG
@Component({
  selector: "distribution-point-type-create-dialog",
  templateUrl: "distribution-point-type-create-dialog.html",
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "fr-FR" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
  ],
})
export class DistributionPointTypeCreateDialogComponent
  implements OnInit, OnDestroy
{
  constructor(
    public fb: FormBuilder,
    public nodeJsInteractionService: NodeJsInteractionService,
    public overlay: Overlay,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DistributionPointTypeCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = fb.group({
      TypePoint: [data.TypePoint, Validators.required],
      Libelle: [data.Libelle, [Validators.required, Validators.maxLength(150)]],
      Description: data.Description,
      UrlSvd: [
        data.UrlSvd,
        [Validators.pattern(this.urlReg), Validators.maxLength(250)],
      ],
      Canal: [data.Canal, Validators.required],
      Supprime: data.Supprime,
      DateDebut: this.formatDateFr(data.DateDebut),
      DateFin: this.formatDateFr(data.DateFin),
    });
  }

  private overlayRef;
  form: FormGroup;
  urlReg = /^(https?):\/\/[^\s$.?#].[^\s]*$/;

  getCanalListObservable;
  canalList;
  svdConnexionTestObservable;
  attachOverlay() {
    this.overlayRef.attach(new ComponentPortal(ProgressContainerComponent));
  }
  detachOverlay() {
    setTimeout(() => {
      this.overlayRef.detach();
    }, 200);
  }
  svdConnexionTest() {
    this.attachOverlay();
    this.nodeJsInteractionService.svdConnexionTest(this.form.value.UrlSvd);
  }
  pad(num) {
    return ("00" + num).slice(-2);
  }
  formatDateFr(str) {
    return str !== "" && str !== undefined
      ? new Date(
          str.substr(3, 2) + "/" + str.substr(0, 2) + "/" + str.substr(6, 4)
        )
      : "";
  }
  formatDateToBdd(date) {
    return date === "" || date === null
      ? ""
      : date.format !== undefined
      ? date.format("YYYY-MM-DD")
      : date.getFullYear() +
        "-" +
        this.pad(date.getMonth() + 1) +
        "-" +
        this.pad(date.getDate());
  }
  save() {
    let errorMsg = "";
    Object.keys(this.form.controls).forEach((key) => {
      if (this.form.controls[key].invalid) {
        errorMsg += "Le champs '" + key + "' n'est pas valide. ";
      }
    });

    const nestedList = this.data.pointTypeList[this.form.value.TypePoint];
    if (
      (this.data.ID === 0 &&
        nestedList.some((e) => e.Libelle === this.form.value.Libelle)) ||
      nestedList.some(
        (e) => e.Libelle === this.form.value.Libelle && e.ID !== this.data.ID
      )
    ) {
      errorMsg +=
        "Le libellé de type de point existe déjà, veuillez en choisir un autre";
    }

    if (this.form.invalid || errorMsg !== "") {
      this.snackBar.open(errorMsg, null, {
        duration: 4000,
        panelClass: "error",
      });
    } else {
      this.form.value.DateDebut = this.formatDateToBdd(
        this.form.value.DateDebut
      );
      this.form.value.DateFin = this.formatDateToBdd(this.form.value.DateFin);
      this.dialogRef.close(this.form.value);
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      hasBackdrop: true,
    });
    this.nodeJsInteractionService.getCanalList();
    this.getCanalListObservable =
      this.nodeJsInteractionService.getCanalListObservable.subscribe((data) => {
        const response = data as any;
        this.canalList = response.string;
      });

    this.svdConnexionTestObservable =
      this.nodeJsInteractionService.svdConnexionTestObservable.subscribe(
        (data) => {
          this.detachOverlay();
          const response = data as any;
          if (response === true) {
            this.snackBar.open("La connexion au SVD est OK", null, {
              duration: 4000,
              panelClass: "success",
            });
          } else {
            this.snackBar.open(
              "La connexion au SVD n'est pas correcte, veuillez changer l'url",
              null,
              {
                duration: 4000,
                panelClass: "error",
              }
            );
          }
        }
      );
  }

  ngOnDestroy() {
    this.getCanalListObservable.unsubscribe();
    this.svdConnexionTestObservable.unsubscribe();
  }
}

@Component({
  selector: "distribution-point-create-dialog",
  templateUrl: "distribution-point-create-dialog.html",
})
export class DistributionPointCreateDialogComponent {
  distributionPointTypeList;
  isSystem;
  form: FormGroup;
  constructor(
    public fb: FormBuilder,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DistributionPointCreateDialogComponent>,
    public nodeJsInteractionService: NodeJsInteractionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isSystem = data.Systeme;
    this.distributionPointTypeList = data.distributionPointTypeList;
    this.form = fb.group({
      Idtypepointdistribution: [
        data.Idtypepointdistribution,
        Validators.required,
      ],
      Libelle: [data.Libelle, [Validators.required, Validators.maxLength(250)]],
      NomResponsable: [
        data.NomResponsable,
        [Validators.required, Validators.maxLength(500)],
      ],
      AdresseNumero: [data.AdresseNumero, Validators.maxLength(10)],
      AdresseVoie: [data.AdresseVoie, Validators.maxLength(300)],
      AdresseComplement: [data.AdresseComplement, Validators.maxLength(500)],
      AdresseCodePostal: [data.AdresseCodePostal, Validators.maxLength(5)],
      AdresseVille: [data.AdresseVille, Validators.maxLength(100)],
      AdressePays: [data.AdressePays, Validators.maxLength(50)],
      CodeAgent: [
        data.CodeAgent.toString(),
        [Validators.required, Validators.maxLength(20)],
      ],
      MotPasseAgent: [
        data.MotPasseAgent,
        [Validators.required, Validators.maxLength(500)],
      ],
      SousCompteSumup: [data.SousCompteSumup, Validators.maxLength(255)],
      MotPasseSumup: [data.MotPasseSumup, Validators.maxLength(500)],
      Systeme: data.Systeme,
      Supprime: data.Supprime,
    });

    if (this.isSystem) {
      this.form.disable();
    }
  }
  save() {
    let errorMsg = "";
    Object.keys(this.form.controls).forEach((key) => {
      if (this.form.controls[key].invalid) {
        errorMsg += "Le champs '" + key + "' n'est pas valide. ";
      }
    });

    if (this.form.invalid) {
      this.snackBar.open(errorMsg, null, {
        duration: 4000,
        panelClass: "error",
      });
    } else {
      this.dialogRef.close(this.form.value);
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "workstation-create-dialog",
  templateUrl: "workstation-create-dialog.html",
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "fr-FR" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
  ],
})
export class WorkstationCreateDialogComponent implements OnDestroy {
  distributionPointList;
  typeDistributionPointList;
  distributionPointCtrl = new FormControl();
  filteredDistributionPoint: Observable<any[]>;
  selectAutocompPoint = null;
  getWorkstationSettingListObservable;
  settings = [];
  form: FormGroup;
  constructor(
    public fb: FormBuilder,
    public snackBar: MatSnackBar,
    public nodeJsInteractionService: NodeJsInteractionService,
    public dialogRef: MatDialogRef<WorkstationCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = fb.group({
      IdPointDistribution: [data.IdPointDistribution, Validators.required],
      Libelle: [data.Libelle, [Validators.required, Validators.maxLength(100)]],
      Description: [data.Description, Validators.maxLength(500)],
      Etat: [data.Etat, Validators.required],
      AdresseMac1: [
        data.AdresseMac1,
        [Validators.required, Validators.maxLength(17)],
      ],
      AdresseMac2: [data.AdresseMac2, Validators.maxLength(17)],
      TypeLecteurNFC: [data.TypeLecteurNFC],
      TypeImprimante: [data.TypeImprimante],
      AddressMacNfcReader: [data.AddressMacNfcReader],
      DateDemandeEnrollement: this.formatDateFr(data.DateDemandeEnrollement),
      DateValidationEnrollement: this.formatDateFr(
        data.DateValidationEnrollement
      ),
      DateSuspension: this.formatDateFr(data.DateSuspension),
      CommentaireSuspension: data.CommentaireSuspension,
    });
    this.selectAutocompPoint = null;
    this.typeDistributionPointList = data.typeDistributionPointList;

    if (this.data.ID !== 0) {
      this.nodeJsInteractionService.getWorkstationSettingList(this.data.ID);
    } else {
      this.settings = [];
      this.settings.push({
        CleParam: "HYBRIDE_LECTEUR_NFC",
        CodeErreur: 0,
        ID: 0,
        IdPosteDistribution: this.data.ID,
        Libelle: "",
        Thematique: "PARAM",
        Valeur: 0,
      });
      this.settings.push({
        CleParam: "HYBRIDE_IMPRIMANTE_TICKET",
        CodeErreur: 0,
        ID: 0,
        IdPosteDistribution: this.data.ID,
        Libelle: "",
        Thematique: "PARAM",
        Valeur: "",
      });
    }

    this.getWorkstationSettingListObservable =
      this.nodeJsInteractionService.getWorkstationSettingListObservable.subscribe(
        (result) => {
          const response = result as any;
          this.settings = Array.isArray(response.ParametragePoste)
            ? response.ParametragePoste
            : response.ParametragePoste !== "" &&
              response.ParametragePoste !== undefined
            ? [response.ParametragePoste]
            : [];
          if (
            this.settings.find((e) => e.CleParam === "HYBRIDE_LECTEUR_NFC") !==
            undefined
          ) {
            this.form.controls.TypeLecteurNFC.setValue(
              this.settings.find((e) => e.CleParam === "HYBRIDE_LECTEUR_NFC")
                .Valeur
            );
          } else {
            this.settings.push({
              CleParam: "HYBRIDE_LECTEUR_NFC",
              CodeErreur: 0,
              ID: 0,
              IdPosteDistribution: this.data.ID,
              Libelle: "",
              Thematique: "PARAM",
              Valeur: 0,
            });
          }
          if (
            this.settings.find(
              (e) => e.CleParam === "HYBRIDE_IMPRIMANTE_TICKET"
            ) !== undefined
          ) {
            this.form.controls.TypeImprimante.setValue(
              this.settings.find(
                (e) => e.CleParam === "HYBRIDE_IMPRIMANTE_TICKET"
              ).Valeur
            );
          } else {
            this.settings.push({
              CleParam: "HYBRIDE_IMPRIMANTE_TICKET",
              CodeErreur: 0,
              ID: 0,
              IdPosteDistribution: this.data.ID,
              Libelle: "",
              Thematique: "PARAM",
              Valeur: "",
            });
          }

          if (
            this.settings.find(
              (e) => e.CleParam === "HYBRIDE_ADRESSE_MAC_NFC"
            ) !== undefined
          ) {
            this.form.controls.AddressMacNfcReader.setValue(
              this.settings.find(
                (e) => e.CleParam === "HYBRIDE_ADRESSE_MAC_NFC"
              ).Valeur
            );
          } else {
            this.settings.push({
              CleParam: "HYBRIDE_ADRESSE_MAC_NFC",
              CodeErreur: 0,
              ID: 0,
              IdPosteDistribution: this.data.ID,
              Libelle: "",
              Thematique: "PARAM",
              Valeur: "",
            });
          }
        }
      );

    const cloneList = [];
    data.distributionPointList.forEach((elem) => {
      const type = this.typeDistributionPointList.find(
        (t) => t.ID === elem.Idtypepointdistribution
      );
      const newItem = { ...elem };
      newItem.Libelle =
        "[ " + type.Libelle + " - " + type.Canal + " ] " + elem.Libelle;
      cloneList.push(newItem);
    });
    this.distributionPointList = cloneList;
    this.filteredDistributionPoint =
      this.distributionPointCtrl.valueChanges.pipe(
        startWith(""),
        map((d) =>
          d
            ? this._filterDistributionPoint(d)
            : this.distributionPointList.slice()
        )
      );
  }

  private _filterDistributionPoint(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.distributionPointList.filter(
      (d) => d.Libelle.toLowerCase().indexOf(filterValue) !== -1
    );
  }
  autocompSelect(point) {
    this.selectAutocompPoint = point;
  }
  pad(num) {
    return ("00" + num).slice(-2);
  }
  formatDateFr(str) {
    return str !== "" && str !== undefined
      ? new Date(
          str.substr(3, 2) + "/" + str.substr(0, 2) + "/" + str.substr(6, 4)
        )
      : "";
  }
  formatDateToBdd(date) {
    return date === "" || date === null
      ? ""
      : date.format !== undefined
      ? date.format("YYYY-MM-DD")
      : date.getFullYear() +
        "-" +
        this.pad(date.getMonth() + 1) +
        "-" +
        this.pad(date.getDate());
  }
  save() {
    let errorMsg = "";
    Object.keys(this.form.controls).forEach((key) => {
      if (this.form.controls[key].invalid) {
        errorMsg += "Le champs '" + key + "' n'est pas valide. ";
      }
    });

    if (this.form.invalid) {
      this.snackBar.open(errorMsg, null, {
        duration: 4000,
        panelClass: "error",
      });
    } else {
      this.form.value.DateDemandeEnrollement = this.formatDateToBdd(
        this.form.value.DateDemandeEnrollement
      );
      this.form.value.DateValidationEnrollement = this.formatDateToBdd(
        this.form.value.DateValidationEnrollement
      );
      this.form.value.DateSuspension = this.formatDateToBdd(
        this.form.value.DateSuspension
      );
      this.form.value.AdresseMac1 =
        this.form.value.AdresseMac1.split("-").join(":");
      this.form.value.AdresseMac2 =
        this.form.value.AdresseMac2.split("-").join(":");
      if (this.selectAutocompPoint !== null) {
        this.form.value.IdPointDistribution = this.selectAutocompPoint.ID;
      }

      this.settings.find((e) => e.CleParam === "HYBRIDE_LECTEUR_NFC").Valeur =
        this.form.value.TypeLecteurNFC;
      this.settings.find(
        (e) => e.CleParam === "HYBRIDE_IMPRIMANTE_TICKET"
      ).Valeur = this.form.value.TypeImprimante;
      if (this.settings.find((e) => e.CleParam === "HYBRIDE_ADRESSE_MAC_NFC")) {
        this.settings.find(
          (e) => e.CleParam === "HYBRIDE_ADRESSE_MAC_NFC"
        ).Valeur = this.form.value.AddressMacNfcReader;
      }
      const resultValues = { ...this.form.value, settings: [...this.settings] };
      this.dialogRef.close(resultValues);
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnDestroy() {
    this.getWorkstationSettingListObservable.unsubscribe();
  }
}

@Component({
  selector: "app-distribution-point",
  templateUrl: "./distribution-point.component.html",
  styleUrls: ["./distribution-point.component.less"],
  providers: [
    { provide: "instance1", useClass: DistributionPointDatabaseService },
    { provide: "instance2", useClass: DistributionPointDatabaseService },
  ],
})
export class DistributionPointComponent implements OnInit, OnDestroy {
  newAccountPointTypeId = 0;
  newAccountPointId = 0;
  constructor(
    public titleService: TitleService,
    @Inject("instance1") public database: DistributionPointDatabaseService,
    @Inject("instance2")
    public databaseBackOffice: DistributionPointDatabaseService,
    public nodeJsInteractionService: NodeJsInteractionService,
    public tableExportService: TableExportService,
    public overlay: Overlay,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public router: Router,
    private appConfigService: AppConfigService
  ) {
    this.titleService.setTitle("Point de distribution - Gestion des points");
    this.titleService.setEcranNumber(2);
    this.dataSourceTable = new MatTableDataSource([]);
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren as any
    );
    this.treeControl = new FlatTreeControl<FlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );

    this.treeFlattenerBackOffice = new MatTreeFlattener(
      this.transformerBackOffice,
      this.getLevel,
      this.isExpandable,
      this.getChildren as any
    );
    this.treeControlBackOffice = new FlatTreeControl<FlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSourceBackOffice = new MatTreeFlatDataSource(
      this.treeControlBackOffice,
      this.treeFlattenerBackOffice
    );

    this.database.setNestedNodeKey("ID");
    this.database.setNestedNodeLibelle(this.nestedNodeLibelle);
    this.database.setFlatNodeKey("ID");
    this.database.setFlatNodeNestedKey("Idtypepointdistribution");
    this.database.setFlatNodeLibelle(this.flatNodeLibelle);
    database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });

    this.databaseBackOffice.setNestedNodeKey("ID");
    this.databaseBackOffice.setNestedNodeLibelle(this.nestedNodeLibelle);
    this.databaseBackOffice.setFlatNodeKey("ID");
    this.databaseBackOffice.setFlatNodeNestedKey("Idtypepointdistribution");
    this.databaseBackOffice.setFlatNodeLibelle(this.flatNodeLibelle);
    databaseBackOffice.dataChange.subscribe((data) => {
      this.dataSourceBackOffice.data = data;
    });

    this.newAccountPointTypeId = appConfigService.config.newAccountPointTypeId;
    this.newAccountPointId = appConfigService.config.newAccountPointId;
  }

  private settingsToSave = null;

  private overlayRef;

  flatNodeMap = new Map<FlatNode, NestedNode>();
  nestedNodeMap = new Map<NestedNode, FlatNode>();
  selectedParent: FlatNode | null = null;
  treeControl: FlatTreeControl<FlatNode>;
  treeFlattener: MatTreeFlattener<NestedNode, FlatNode>;
  dataSource: MatTreeFlatDataSource<NestedNode, FlatNode>;
  checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);

  flatNodeMapBackOffice = new Map<FlatNode, NestedNode>();
  nestedNodeMapBackOffice = new Map<NestedNode, FlatNode>();
  selectedParentBackOffice: FlatNode | null = null;
  treeControlBackOffice: FlatTreeControl<FlatNode>;
  treeFlattenerBackOffice: MatTreeFlattener<NestedNode, FlatNode>;
  dataSourceBackOffice: MatTreeFlatDataSource<NestedNode, FlatNode>;
  checklistSelectionBackOffice = new SelectionModel<FlatNode>(
    true /* multiple */
  );

  tableDisplayedColumns: string[] = [
    "actions",
    "ID",
    "Libelle",
    "Description",
    "AdresseMac1",
    "DateDemandeEnrollement",
    "DateValidationEnrollement",
    "Etat",
  ];
  dataSourceTable: MatTableDataSource<any>;
  nestedNodeLibelle = ["Libelle", "Canal"];
  flatNodeLibelle = ["Libelle", "NomResponsable"];
  selectedList = "front";
  selectedPoint;
  filterDistributionPointFrontText: string = "";
  filterDistributionPointBackText: string = "";

  private getDistributionPointTypeListObservable;
  private getDistributionPointListObservable;
  private distributionPointTypeCreateObservable;
  private distributionPointCreateObservable;
  private distributionPointDeleteObservable;
  private distributionPointUpdateObservable;
  private getWorkstationListObservable;
  private workstationCreateObservable;
  private workstationUpdateObservable;
  private workstationDeleteObservable;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  attachOverlay() {
    this.overlayRef.attach(new ComponentPortal(ProgressContainerComponent));
  }
  detachOverlay() {
    setTimeout(() => {
      this.overlayRef.detach();
    }, 200);
  }
  getLevel = (node: FlatNode) => node.level;
  isExpandable = (node: FlatNode) => node.expandable;
  getChildren = (node: NestedNode): FlatNode[] => node.children;
  hasChild = (_: number, nodeData: FlatNode) => nodeData.expandable;
  hasNoContent = (_: number, nodeData: FlatNode) => nodeData.Libelle === "";
  transformer = (node: NestedNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.Libelle === node.Libelle
        ? existingNode
        : new FlatNode();
    flatNode.Libelle = node.Libelle;
    flatNode.ID = node.ID;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };
  transformerBackOffice = (node: NestedNode, level: number) => {
    const existingNode = this.nestedNodeMapBackOffice.get(node);
    const flatNode =
      existingNode && existingNode.Libelle === node.Libelle
        ? existingNode
        : new FlatNode();
    flatNode.Libelle = node.Libelle;
    flatNode.ID = node.ID;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMapBackOffice.set(flatNode, node);
    this.nestedNodeMapBackOffice.set(node, flatNode);
    return flatNode;
  };
  ngOnInit() {
    if (
      !this.nodeJsInteractionService.functionnalityAccess.checkAccess(
        "DISTRIBUTION_GERER_POINTS_DISTRIBUTION"
      )
    ) {
      this.router.navigate(["/home"]);
    }
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      hasBackdrop: true,
    });

    this.nodeJsInteractionService.getDistributionPointTypeList();
    this.getDistributionPointTypeListObservable =
      this.nodeJsInteractionService.getDistributionPointTypeListObservable.subscribe(
        (data) => {
          const response = data as any;
          const typePointDistributionList = Array.isArray(
            response.TypePointDistribution
          )
            ? response.TypePointDistribution
            : [response.TypePointDistribution];
          const frontType = typePointDistributionList
            .filter((e) => e.TypePoint === "FrontOffice")
            .sort((a, b) => {
              const a1 = a.Libelle.toLowerCase();
              const b1 = b.Libelle.toLowerCase();
              return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
            });

          const backType = typePointDistributionList
            .filter((e) => e.TypePoint === "BackOffice")
            .sort((a, b) => {
              const a1 = a.Libelle.toLowerCase();
              const b1 = b.Libelle.toLowerCase();
              return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
            });
          this.database.setNestedNodeList(frontType);
          this.databaseBackOffice.setNestedNodeList(backType);
          this.nodeJsInteractionService.getDistributionPointList();
        }
      );
    this.getDistributionPointListObservable =
      this.nodeJsInteractionService.getDistributionPointListObservable.subscribe(
        (data) => {
          const response = data as any;
          const pointDistributionList = Array.isArray(
            response.PointDistribution
          )
            ? response.PointDistribution
            : [response.PointDistribution];
          const flatNodeList = pointDistributionList
            .filter((point) =>
              this.database
                .getNestedNodeList()
                .some((type) => type.ID === point.Idtypepointdistribution)
            )
            .sort((a, b) => {
              const a1 = a.Libelle.toLowerCase();
              const b1 = b.Libelle.toLowerCase();
              return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
            });
          const flatNodeListBackOffice = pointDistributionList
            .filter((point) =>
              this.databaseBackOffice
                .getNestedNodeList()
                .some((type) => type.ID === point.Idtypepointdistribution)
            )
            .sort((a, b) => {
              const a1 = a.Libelle.toLowerCase();
              const b1 = b.Libelle.toLowerCase();
              return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
            });

          if (
            flatNodeList.find((e) => e.ID === this.newAccountPointId) !==
            undefined
          ) {
            flatNodeList.find(
              (e) => e.ID === this.newAccountPointId
            ).NomResponsable = "";
          }
          this.database.setFlatNodeList(flatNodeList);
          this.databaseBackOffice.setFlatNodeList(flatNodeListBackOffice);
          this.database.initialize();
          this.databaseBackOffice.initialize();
        }
      );
    this.distributionPointTypeCreateObservable =
      this.nodeJsInteractionService.distributionPointTypeCreateObservable.subscribe(
        (data) => {
          const response = data as any;
          if (response.CodeErreur !== 0) {
            this.snackBar.open(
              "Une erreur s'est produite lors de le sauvegarde du type de point de distribution.",
              null,
              {
                duration: 3000,
                panelClass: "error",
              }
            );
            return false;
          }
          this.snackBar.open(
            "Le type de point de distribution à bien été enregistré.",
            null,
            {
              duration: 3000,
              panelClass: "success",
            }
          );

          let database = this.database;
          let nestedNodeMap = this.nestedNodeMap;
          let treeControl = this.treeControl;
          if (response.TypePoint === "BackOffice") {
            database = this.databaseBackOffice;
            nestedNodeMap = this.nestedNodeMapBackOffice;
            treeControl = this.treeControlBackOffice;
          }

          const distributionPointType = database.getNestedNode(response.ID);
          const distributionPointTypeList = database.getNestedNodeList();
          if (distributionPointType === undefined) {
            distributionPointTypeList.push(response);
            database.setNestedNodeList(distributionPointTypeList);
            database.initialize();
          } else {
            const indexOf = distributionPointTypeList.indexOf(
              distributionPointType
            );
            distributionPointTypeList[indexOf] = response;
            database.setNestedNodeList(distributionPointTypeList);
            const dataSourceNode = database.data.find(
              (element) =>
                element[database.getNestedNodeKey()] ===
                response[database.getNestedNodeKey()]
            );
            const flatNode = nestedNodeMap.get(dataSourceNode);
            dataSourceNode.Libelle =
              database.generateNestedNodeLibelle(response);
            database.dataChange.next(database.data);
            if (treeControl.isExpanded(flatNode)) {
              treeControl.expand(nestedNodeMap.get(dataSourceNode));
            }
          }

          this.detachOverlay();
        }
      );

    this.distributionPointCreateObservable =
      this.nodeJsInteractionService.distributionPointCreateObservable.subscribe(
        (data) => {
          const response = data as any;
          if (response.CodeErreur !== 0) {
            this.snackBar.open(
              "Une erreur s'est produite lors de la création du point de distribution.",
              null,
              {
                duration: 3000,
                panelClass: "error",
              }
            );
            return false;
          }
          this.snackBar.open(
            "Le point de distribution à bien été créé.",
            null,
            {
              duration: 3000,
              panelClass: "success",
            }
          );

          let database = this.database;
          let nestedNodeMap = this.nestedNodeMap;
          let treeControl = this.treeControl;
          if (this.selectedList === "back") {
            database = this.databaseBackOffice;
            nestedNodeMap = this.nestedNodeMapBackOffice;
            treeControl = this.treeControlBackOffice;
          }

          const distributionPointList = database.getFlatNodeList();
          distributionPointList.push(response);
          database.setFlatNodeList(distributionPointList);
          database.initialize();
          const dataSourceNode = database.findNode(database.data, response.ID);
          treeControl.expand(
            this.selectedList === "back"
              ? this.getParentNodeBackOffice(nestedNodeMap.get(dataSourceNode))
              : this.getParentNode(nestedNodeMap.get(dataSourceNode))
          );
          this.detachOverlay();
        }
      );

    this.distributionPointUpdateObservable =
      this.nodeJsInteractionService.distributionPointUpdateObservable.subscribe(
        (data) => {
          const response = data as any;
          if (response.CodeErreur !== 0) {
            this.snackBar.open(
              "Une erreur s'est produite lors de la mise à jour du point de distribution.",
              null,
              {
                duration: 3000,
                panelClass: "error",
              }
            );
            return false;
          }
          this.snackBar.open(
            "Le point de distribution à bien été mis à jour.",
            null,
            {
              duration: 3000,
              panelClass: "success",
            }
          );

          let database = this.database;
          let treeControl = this.treeControl;
          if (this.selectedList === "back") {
            database = this.databaseBackOffice;
            treeControl = this.treeControlBackOffice;
          }

          const libelle = database.generateFlatNodeLibelle(response);
          const flatNodeList = database.getFlatNodeList();
          const indexOf = flatNodeList.findIndex(
            (element) =>
              element[database.getFlatNodeKey()] ===
              response[database.getFlatNodeKey()]
          );
          flatNodeList[indexOf] = response;
          database.setFlatNodeList(flatNodeList);
          database.updateFlatItem(response as any, libelle);
          database.initialize();
          const flatNode = treeControl.dataNodes.find(
            (e) => e.ID === response.ID
          );
          treeControl.expand(
            this.selectedList === "back"
              ? this.getParentNodeBackOffice(flatNode)
              : this.getParentNode(flatNode)
          );
          this.detachOverlay();
        }
      );

    this.distributionPointDeleteObservable =
      this.nodeJsInteractionService.distributionPointDeleteObservable.subscribe(
        (data) => {
          this.detachOverlay();
        }
      );

    this.getWorkstationListObservable =
      this.nodeJsInteractionService.getWorkstationListObservable.subscribe(
        (data) => {
          const response = data as any;
          if (response !== "") {
            const dataSource = Array.isArray(response.PosteDistribution)
              ? response.PosteDistribution
              : [response.PosteDistribution];
            const dataSourceFilter = dataSource
              .filter(
                (elem) => elem.IdPointDistribution === this.selectedPoint.ID
              )
              .sort((a, b) => {
                const a1 = a.Libelle.toLowerCase();
                const b1 = b.Libelle.toLowerCase();
                return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
              });
            dataSourceFilter.forEach(
              (element) => (element.selectedPoint = this.selectedPoint.Libelle)
            );
            this.dataSourceTable = new MatTableDataSource(dataSourceFilter);
            this.dataSourceTable.filterPredicate = (
              fdata: any,
              filtersJson: string
            ) => {
              const matchFilter = [];
              const filters = JSON.parse(filtersJson);
              filters.forEach((filter) => {
                const val = fdata[filter.id] === null ? "" : fdata[filter.id];
                if (val === undefined) {
                  return;
                }
                matchFilter.push(
                  val
                    .toString()
                    .toLowerCase()
                    .includes(filter.value.toLowerCase())
                );
              });
              return matchFilter.some(Boolean);
            };
          }
          this.detachOverlay();
        }
      );

    this.workstationCreateObservable =
      this.nodeJsInteractionService.workstationtCreateObservable.subscribe(
        (data) => {
          this.detachOverlay();
          const response = data as any;
          if (response.CodeErreur !== 0) {
            this.snackBar.open(
              "Une erreur s'est produite lors de la création du poste de distribution.",
              null,
              {
                duration: 3000,
                panelClass: "error",
              }
            );
            return false;
          }
          this.snackBar.open(
            "Le poste de distribution à bien été créé.",
            null,
            {
              duration: 3000,
              panelClass: "success",
            }
          );
          this.dataSourceTable.data.push(response);
          this.dataSourceTable._updateChangeSubscription();

          if (this.settingsToSave !== null) {
            this.saveSettingsAfter(response.ID);
          }
        }
      );

    this.workstationUpdateObservable =
      this.nodeJsInteractionService.workstationtUpdateObservable.subscribe(
        (data) => {
          this.detachOverlay();
          const response = data as any;
          if (response.CodeErreur !== 0) {
            this.snackBar.open(
              "Une erreur s'est produite lors de la mise à jour du poste de distribution.",
              null,
              {
                duration: 3000,
                panelClass: "error",
              }
            );
            return;
          }
          this.snackBar.open(
            "Le poste de distribution à bien été mis à jour.",
            null,
            {
              duration: 3000,
              panelClass: "success",
            }
          );

          const updateRow = this.dataSourceTable.data.find(
            (e) => e.ID === response.ID
          );
          const indexOf = this.dataSourceTable.data.indexOf(updateRow);

          if (response.IdPointDistribution === updateRow.IdPointDistribution) {
            this.dataSourceTable.data[indexOf] = response;
          } else {
            this.dataSourceTable.data.splice(indexOf, 1);
          }
          this.dataSourceTable._updateChangeSubscription();
          if (this.settingsToSave !== null) {
            this.saveSettingsAfter(response.ID);
          }
        }
      );

    this.workstationDeleteObservable =
      this.nodeJsInteractionService.workstationtDeleteObservable.subscribe(
        (data) => {
          this.detachOverlay();
        }
      );
  }
  ngOnDestroy() {
    this.getDistributionPointTypeListObservable.unsubscribe();
    this.getDistributionPointListObservable.unsubscribe();
    this.distributionPointTypeCreateObservable.unsubscribe();
    this.distributionPointCreateObservable.unsubscribe();
    this.distributionPointDeleteObservable.unsubscribe();
    this.distributionPointUpdateObservable.unsubscribe();
    this.getWorkstationListObservable.unsubscribe();
    this.workstationCreateObservable.unsubscribe();
    this.workstationUpdateObservable.unsubscribe();
    this.workstationDeleteObservable.unsubscribe();
  }

  saveSettingsAfter(workstatsionId) {
    const nfcReader = this.settingsToSave.find(
      (e) =>
        e !== undefined && e !== null && e.CleParam === "HYBRIDE_LECTEUR_NFC"
    );
    const printer = this.settingsToSave.find(
      (e) =>
        e !== undefined &&
        e !== null &&
        e.CleParam === "HYBRIDE_IMPRIMANTE_TICKET"
    );
    const macAddressnfcReader = this.settingsToSave.find(
      (e) =>
        e !== undefined &&
        e !== null &&
        e.CleParam === "HYBRIDE_ADRESSE_MAC_NFC"
    );

    const nfcReaderParam = {
      ID: nfcReader.ID,
      IdPosteDistribution: workstatsionId,
      CleParam: "HYBRIDE_LECTEUR_NFC",
      Thematique: "PARAM",
      Libelle: "",
      Valeur: nfcReader.Valeur,
      CodeErreur: 0,
    };
    if (nfcReaderParam.ID !== 0) {
      this.nodeJsInteractionService.workstationtSettingUpdate(nfcReaderParam);
    } else {
      this.nodeJsInteractionService.workstationtSettingCreate(nfcReaderParam);
    }

    const printerParam = {
      ID: printer.ID,
      IdPosteDistribution: workstatsionId,
      CleParam: "HYBRIDE_IMPRIMANTE_TICKET",
      Thematique: "PARAM",
      Libelle: "",
      Valeur: printer.Valeur,
      CodeErreur: 0,
    };
    if (printerParam.ID !== 0) {
      this.nodeJsInteractionService.workstationtSettingUpdate(printerParam);
    } else {
      this.nodeJsInteractionService.workstationtSettingCreate(printerParam);
    }

    if (macAddressnfcReader) {
      const macNfcReaderParam = {
        ID: macAddressnfcReader.ID,
        IdPosteDistribution: workstatsionId,
        CleParam: "HYBRIDE_ADRESSE_MAC_NFC",
        Thematique: "PARAM",
        Libelle: "",
        Valeur: macAddressnfcReader.Valeur ? macAddressnfcReader.Valeur : "",
        CodeErreur: 0,
      };
      if (macNfcReaderParam.ID !== 0) {
        this.nodeJsInteractionService.workstationtSettingUpdate(
          macNfcReaderParam
        );
      } else {
        this.nodeJsInteractionService.workstationtSettingCreate(
          macNfcReaderParam
        );
      }
    }
  }
  exportToExcel() {
    this.tableExportService.exportToExcel(
      "workstation-list-content-table",
      "Poste-de-distribution"
    );
  }
  setSelectedList(type) {
    this.selectedList = type;
    this.checklistSelection.clear();
    this.checklistSelectionBackOffice.clear();

    if (this.dataSourceTable !== undefined) {
      this.dataSourceTable.data = [];
      this.dataSourceTable._updateChangeSubscription();
    }
  }
  applyFilter(filterValue: string) {
    const tableFilters = [];
    this.tableDisplayedColumns.forEach((key) => {
      if (key === "actions") {
        return;
      }
      tableFilters.push({
        id: key,
        value: filterValue,
      });
    });
    // custom filter
    tableFilters.push({
      id: "selectedPoint",
      value: filterValue,
    });
    this.dataSourceTable.filter = JSON.stringify(tableFilters);
    if (this.dataSourceTable.paginator) {
      this.dataSourceTable.paginator.firstPage();
    }
  }

  openDistributionPointTypeCreateDialog(node): void {
    const nodeData = node || null;
    let canvas = {
      ID: 0,
      TypePointDistribution: "",
      Libelle: "",
      Description: "",
      UrlSvd: "",
      Canal: "",
      Supprime: false,
      DateDebut: "",
      DateFin: "",
      pointTypeList: {},
    };
    if (nodeData !== null) {
      const nestedNode =
        this.selectedList === "back"
          ? this.databaseBackOffice.getNestedNode(node.ID)
          : this.database.getNestedNode(node.ID);

      canvas = nestedNode;
    }
    canvas.pointTypeList = {
      BackOffice: this.databaseBackOffice.getNestedNodeList(),
      FrontOffice: this.database.getNestedNodeList(),
    };

    const dialogRef = this.dialog.open(
      DistributionPointTypeCreateDialogComponent,
      {
        width: "600px",
        data: canvas,
        disableClose: true,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== false && result !== undefined) {
        result.ID = canvas.ID;
        result.Supprime = result.Supprime ? 1 : 0;
        result.CodeErreur = 0;
        this.attachOverlay();
        this.nodeJsInteractionService.distributionPointTypeCreate(result);
      }
    });
  }

  openDistributionPointCreateDialog(node): void {
    const nodeData = node || null;
    let canvas = {
      ID: 0,
      Idtypepointdistribution: "",
      Libelle: "",
      NomResponsable: "",
      AdresseNumero: "",
      AdresseVoie: "",
      AdresseComplement: "",
      AdresseCodePostal: "",
      AdresseVille: "",
      AdressePays: "",
      CodeAgent: "",
      MotPasseAgent: "",
      SousCompteSumup: "",
      MotPasseSumup: "",
      Systeme: false,
      Supprime: false,
      distributionPointTypeList: "",
    };
    const dataBase =
      this.selectedList === "back" ? this.databaseBackOffice : this.database;
    if (nodeData !== null) {
      const nestedList = dataBase.getFlatNode(node[dataBase.getFlatNodeKey()]);
      canvas = nestedList;
    }

    canvas.distributionPointTypeList = dataBase.getNestedNodeList();
    const dialogRef = this.dialog.open(DistributionPointCreateDialogComponent, {
      width: "600px",
      data: canvas,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== false && result !== undefined) {
        result.ID = canvas.ID;
        result.Supprime = result.Supprime ? 1 : 0;
        result.Systeme = result.Systeme ? 1 : 0;
        result.CodeErreur = 0;
        result.AdresseNumero =
          result.AdresseNumero !== null ? result.AdresseNumero.toString() : "";
        result.AdresseVoie =
          result.AdresseVoie !== null ? result.AdresseVoie : "";
        result.AdresseComplement =
          result.AdresseComplement !== null ? result.AdresseComplement : "";
        result.AdresseCodePostal =
          result.AdresseCodePostal !== null
            ? result.AdresseCodePostal.toString()
            : "";
        result.AdresseVille =
          result.AdresseVille !== null ? result.AdresseVille : "";
        result.AdressePays =
          result.AdressePays !== null ? result.AdressePays : "";

        if (result.ID !== 0) {
          this.nodeJsInteractionService.distributionPointUpdate(result);
        } else {
          this.nodeJsInteractionService.distributionPointCreate(result);
        }
      }
    });
  }

  openDeletePointConfirmationDialog(node: FlatNode): void {
    const dataBase =
      this.selectedList === "back" ? this.databaseBackOffice : this.database;
    const check = dataBase.getFlatNodeList().find((e) => e.ID === node.ID);
    if (check !== undefined && check.Systeme === true) {
      this.snackBar.open(
        "Ce point est de type système, il ne peut être supprimé.",
        null,
        {
          duration: 3000,
          panelClass: "infos",
        }
      );
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data:
        "<h6>Êtes vous sur de vouloir supprimer ce point de distribution ?" +
        "</h6>L'ensemble des postes associés à celui-ci seront également supprimés. <br/>",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.removeNode(node);
      }
    });
  }

  isString(element) {
    return typeof element === "string";
  }

  openWorkstationCreateDialog(element): void {
    if (this.checklistSelection.selected.length === 0) {
      this.snackBar.open(
        "Veuillez sélectionner un point de distribution à gauche de l'écran avant d'ajouter un poste.",
        null,
        {
          duration: 3000,
          panelClass: "infos",
        }
      );
      return;
    }

    let database = this.database;
    if (this.selectedList === "back") {
      database = this.databaseBackOffice;
    }

    const elementData = element || null;
    let canvas = {
      ID: 0,
      IdPointDistribution: this.checklistSelection.selected[0].ID,
      Libelle: "",
      Description: "",
      Etat: "",
      AdresseMac1: "",
      AdresseMac2: "",
      TypeLecteurNFC: "",
      DateDemandeEnrollement: "",
      DateValidationEnrollement: "",
      DateSuspension: "",
      CommentaireSuspension: "",
      Supprime: false,
      distributionPointList: [],
      typeDistributionPointList: [],
    } as any;
    if (elementData !== null) {
      const nestedElement = this.dataSourceTable.data.find(
        (e) => e.ID === element.ID
      );
      canvas = nestedElement;
    }

    canvas.TypeImprimante = "";
    canvas.distributionPointList = [...database.getFlatNodeList()];
    canvas.typeDistributionPointList = [...database.getNestedNodeList()];
    const dialogRef = this.dialog.open(WorkstationCreateDialogComponent, {
      width: "800px",
      data: canvas,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dataToSave) => {
      const result = { ...dataToSave };
      if (dataToSave !== false && dataToSave !== undefined) {
        this.attachOverlay();
        result.ID = canvas.ID;
        const date = new Date();
        const pad = (num) => ("00" + num).slice(-2);
        const now =
          date.getUTCFullYear() +
          "-" +
          pad(date.getUTCMonth() + 1) +
          "-" +
          pad(date.getUTCDate());

        if (result.Etat === "OK" && result.DateValidationEnrollement === "") {
          result.DateValidationEnrollement = now;
        }
        if (result.Etat === "Suspendu" && result.DateSuspension === "") {
          result.DateSuspension = now;
        }
        if (result.Description === null) {
          result.Description = "";
        }
        if (result.CommentaireSuspension === null) {
          result.CommentaireSuspension = "";
        }
        result.Supprime = result.Supprime ? 1 : 0;
        result.CodeErreur = 0;

        if (Array.isArray(result.settings)) {
          this.settingsToSave = result.settings;
        }

        delete result.AddressMacNfcReader;
        delete result.settings;

        if (result.ID !== 0) {
          this.nodeJsInteractionService.workstationtUpdate(result);
        } else {
          this.nodeJsInteractionService.workstationtCreate(result);
        }
      }
    });
  }

  openDeleteWorkstationConfirmationDialog(element): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: `
				<h6>Êtes vous sur de vouloir supprimer ce poste ? </h6>
				L\'ensemble des éléments associés à celui-ci seront également supprimés. <br/>
				`,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.attachOverlay();
        this.dataSourceTable.data = this.dataSourceTable.data.filter(
          (value) => {
            return value.ID !== element.ID;
          }
        );
        this.dataSourceTable._updateChangeSubscription();
        this.nodeJsInteractionService.workstationtDelete(element.ID);
      }
    });
  }

  openSuspendWorkstationConfirmationDialog(element): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      disableClose: true,
      data: {
        message:
          "<h6>Êtes vous sur de vouloir suspendre le fonctionnement de ce poste ? </h6>",
        inputs: [
          {
            id: "motif",
            type: "text",
            placeholder: "Motif",
            required: true,
          },
          {
            id: "suspend_date",
            type: "datepicker",
            placeholder: "Date de suspension",
            required: true,
          },
        ],
      },
    });
    dialogRef.beforeClosed().subscribe((result) => {
      const nestedElement = this.dataSourceTable.data.find(
        (e) => e.ID === element.ID
      );
      if (result === undefined) {
        return false;
      }

      if (result.motif !== undefined && result.suspend_date !== undefined) {
        this.attachOverlay();
        nestedElement.Etat = "Suspendu";
        nestedElement.CommentaireSuspension = result.motif;
        nestedElement.DateSuspension = result.suspend_date.format("YYYY-MM-DD");
        nestedElement.Supprime = nestedElement.Supprime ? 1 : 0;
        nestedElement.CodeErreur = 0;

        this.nodeJsInteractionService.workstationtUpdate(nestedElement);
      }
    });
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: FlatNode): void {
    this.attachOverlay();
    this.dataSourceTable = new MatTableDataSource([]);
    this.nodeJsInteractionService.getWorkstationList(node.ID);
    this.selectedPoint = node;
    this.checklistSelection.clear();
    this.checklistSelectionBackOffice.clear();
    this.checklistSelection.select(node);
  }

  /* Get the parent node of a node */
  getParentNode(node: FlatNode): FlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Remove the node from database */
  removeNode(node: FlatNode) {
    const parentNode = this.flatNodeMap.get(this.getParentNode(node));
    this.database.removeFlatItem(parentNode, node);
    this.nodeJsInteractionService.distributionPointDelete(node.ID);
    this.attachOverlay();
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggleBackOffice(node: FlatNode): void {
    this.attachOverlay();
    this.selectedPoint = node;
    this.checklistSelection.clear();
    this.checklistSelectionBackOffice.clear();
    this.checklistSelectionBackOffice.select(node);
  }

  /* Get the parent node of a node */
  getParentNodeBackOffice(node: FlatNode): FlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControlBackOffice.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControlBackOffice.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Remove the node from database */
  removeNodeBackOffice(node: FlatNode) {
    const parentNode = this.flatNodeMapBackOffice.get(
      this.getParentNodeBackOffice(node)
    );
    this.databaseBackOffice.removeFlatItem(parentNode, node);
    this.nodeJsInteractionService.distributionPointDelete(node.ID);
    this.attachOverlay();
  }

  filterDisplayedNode(node: FlatNode, type: string): boolean {
    let searchString = "";
    switch (type) {
      case "FRONT":
        searchString = this.filterDistributionPointFrontText;
        break;

      case "BACK":
        searchString = this.filterDistributionPointBackText;
        break;

      default:
        break;
    }
    if (!searchString) {
      return false;
    }
    return (
      node.Libelle.toLowerCase().indexOf(searchString.toLowerCase()) === -1
    );
  }

  filterDisplayedParentNode(node: any, type): boolean {
    let searchString;
    let treeControl;
    switch (type) {
      case "FRONT":
        searchString = this.filterDistributionPointFrontText;
        treeControl = this.treeControl;
        break;

      case "BACK":
        searchString = this.filterDistributionPointBackText;
        treeControl = this.treeControlBackOffice;
        break;

      default:
        break;
    }
    if (
      !searchString ||
      node.Libelle.toLowerCase().indexOf(searchString.toLowerCase()) !== -1
    ) {
      return false;
    }
    const descendants = treeControl.getDescendants(node);
    if (
      descendants.some(
        (descendantNode) =>
          descendantNode.Libelle.toLowerCase().indexOf(
            searchString.toLowerCase()
          ) !== -1
      )
    ) {
      return false;
    } else {
      return true;
    }
  }
}
