import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FlatNode, NestedNode, DistributionPointDatabaseService } from '../_service/distribution-point-database.service';
import { SelectionModel } from '@angular/cdk/collections';
import {
    MatTreeFlatDataSource, MatTreeFlattener, MatDialog, MatSnackBar,
    MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource
} from '@angular/material';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NodeJsInteractionService } from '../_service/nodejs-interaction.service';
import { TitleService } from '../_service/title.service';
import { Overlay } from '@angular/cdk/overlay';
import { ProgressContainerComponent } from '../_ui/progress-container/progress-container.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConfigService } from '../_service/app-config.service';


@Component({
    selector: 'general-settings-configuration-create-poste-dialog',
    templateUrl: 'general-settings-configuration-create-poste-dialog.html'
})

export class GeneralSettingsConfigurationCreatePosteDialogComponent {
    selectedPoste;
    detectSupportRedirect = true;
    settings;
    form: FormGroup;
    constructor(
        public fb: FormBuilder,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<GeneralSettingsConfigurationCreatePosteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.selectedPoste = data.selectedPoste;
        this.settings = data.settings;

        const nfcReader = (this.settings.find(element => element.CleParam === 'HYBRIDE_LECTEUR_NFC') !== undefined) ?
            this.settings.find(element => element.CleParam === 'HYBRIDE_LECTEUR_NFC').Valeur : -1;
        const printer = (this.settings.find(element => element.CleParam === 'HYBRIDE_IMPRIMANTE_TICKET') !== undefined) ?
            this.settings.find(element => element.CleParam === 'HYBRIDE_IMPRIMANTE_TICKET').Valeur : -1;
        const macNfcReader = (this.settings.find(element => element.CleParam === 'HYBRIDE_ADRESSE_MAC_NFC') !== undefined) ?
            this.settings.find(element => element.CleParam === 'HYBRIDE_ADRESSE_MAC_NFC').Valeur : '';

        this.form = fb.group({
            HYBRIDE_LECTEUR_NFC: nfcReader,
            HYBRIDE_IMPRIMANTE_TICKET: printer,
            HYBRIDE_ADRESSE_MAC_NFC: macNfcReader
        });
    }
    save() {

        let errorMsg = '';
        Object.keys(this.form.controls).forEach(key => {
            if (this.form.controls[key].invalid) {
                errorMsg += 'Le champs \'' + key + '\' n\'est pas valide. ';
            }
        });

        if (this.form.invalid) {
            this.snackBar.open(errorMsg, null, {
                duration: 4000,
                panelClass: 'error'
            });
            return;
        }

        this.dialogRef.close(this.form.value);
    }
    onNoClick(): void { this.dialogRef.close(); }
}


@Component({
    selector: 'general-settings-configuration-create-point-dialog',
    templateUrl: 'general-settings-configuration-create-point-dialog.html',
    providers: [
    ]
})

export class GeneralSettingsConfigurationCreatePointDialogComponent {
    form: FormGroup;
    selectedPoint;
    settings;
    constructor(
        public fb: FormBuilder,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<GeneralSettingsConfigurationCreatePointDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.selectedPoint = data.selectedPoint;
        this.settings = data.settings;
        const clConfirm = (this.settings.find(element =>
            element !== undefined && element.CleParam === 'CYCLEVENTE_PANIER_CONFIRMATION_SUPPRESSION_PRODUIT') !== undefined
        ) ? this.settings.find(element => element.CleParam === 'CYCLEVENTE_PANIER_CONFIRMATION_SUPPRESSION_PRODUIT').Valeur : 0;
        const clRedirect = (this.settings.find(
            element => element !== undefined && element.CleParam === 'CYCLEVENTE_MENU_TRG_CHARGEMENT') !== undefined
        ) ? this.settings.find(element => element.CleParam === 'CYCLEVENTE_MENU_TRG_CHARGEMENT').Valeur : -1;

        this.form = fb.group({
            CYCLEVENTE_MENU_TRG_CHARGEMENT_TOGGLE: (clRedirect > 0),
            CYCLEVENTE_MENU_TRG_CHARGEMENT: clRedirect,
            CYCLEVENTE_PANIER_CONFIRMATION_SUPPRESSION_PRODUIT: (clConfirm > 0) ? 1 : 0,
        });
    }
    save() {
        this.dialogRef.close(this.form.value);
    }
    onNoClick(): void { this.dialogRef.close(); }
}


@Component({
    selector: 'general-settings-configuration-system-listing-dialog',
    templateUrl: 'general-settings-configuration-system-listing-dialog.html',
    styleUrls: ['./general-settings.component.less'],
    providers: [
    ]
})

export class GeneralSettingsConfigurationSystemListingDialogComponent {
    form: FormGroup;
    getSystemSettingListObservable;
    dataSourceTableSettings;
    tableDisplayedColumnsSettings: string[] = ['CleParam', 'Thematique', 'Libelle', 'Valeur'];
    constructor(
        public fb: FormBuilder,
        public snackBar: MatSnackBar,
        public nodeJsInteractionService: NodeJsInteractionService,
        public dialogRef: MatDialogRef<GeneralSettingsConfigurationSystemListingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {



        this.getSystemSettingListObservable = this.nodeJsInteractionService.getSystemSettingListObservable.subscribe(result => {
            const response = result as any;
            const dataSource = (Array.isArray(response.ParametrageSysteme)) ? response.ParametrageSysteme : [response.ParametrageSysteme];
            this.dataSourceTableSettings = new MatTableDataSource(dataSource);
            this.dataSourceTableSettings._updateChangeSubscription();
        });

    }
    save() {
        if (this.form.invalid) {
            this.snackBar.open('La saisie n\'est pas valide, veuillez recommencer', null, {
                duration: 3000,
                panelClass: 'error'
            });
            this.dialogRef.close(false);
        } else {
            this.dialogRef.close(this.form.value);
        }
    }
    onNoClick(): void { this.dialogRef.close(); }
}

@Component({
    selector: 'general-settings-api-management-form-dialog',
    templateUrl: 'general-settings-api-management-form-dialog.html',
    styleUrls: ['./general-settings.component.less'],
    providers: [
    ]
})

export class GeneralSettingsApiManagementFormDialogComponent {
    form: FormGroup;
    constructor(
        public fb: FormBuilder,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<GeneralSettingsApiManagementFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private appConfigService: AppConfigService,
    ) {
        this.form = fb.group({
            iamUrl: [appConfigService.config.hybrideApiManagement.iamUrl, Validators.required],
            iamRealm: [appConfigService.config.hybrideApiManagement.iamRealm, Validators.required],
            iamClient: [appConfigService.config.hybrideApiManagement.iamClient, Validators.required],
            iamPassword: [appConfigService.config.hybrideApiManagement.iamPassword, Validators.required],
            grantType: [appConfigService.config.hybrideApiManagement.grantType, Validators.required],
        });
    }
    save() {
        if (this.form.invalid) {
            this.snackBar.open('La saisie n\'est pas valide, veuillez recommencer', null, {
                duration: 3000,
                panelClass: 'error'
            });
            this.dialogRef.close(false);
        } else {
            this.dialogRef.close(this.form.value);
        }
    }
    onNoClick(): void { this.dialogRef.close(); }
}

@Component({
    selector: 'app-general-settings',
    templateUrl: './general-settings.component.html',
    styleUrls: ['./general-settings.component.less'],
    providers: [
        { provide: 'instance1', useClass: DistributionPointDatabaseService },
        { provide: 'instance2', useClass: DistributionPointDatabaseService },
    ]
})
export class GeneralSettingsComponent implements OnInit, OnDestroy {
    constructor(
        public titleService: TitleService,
        @Inject('instance1') public database: DistributionPointDatabaseService,
        @Inject('instance2') public databaseBackOffice: DistributionPointDatabaseService,
        public nodeJsInteractionService: NodeJsInteractionService,
        public overlay: Overlay,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public router: Router
    ) {
        this.titleService.setTitle('Paramétrages - Système / Point de distribution / Poste');
        this.titleService.setEcranNumber(12);
        if (!this.nodeJsInteractionService.functionnalityAccess.checkAccess('PARAMETRAGE_SYSTEME')) {
            this.router.navigate(['/home']);
        }

        this.dataSourceTable = new MatTableDataSource([]);
        this.dataSourceTableSettings = new MatTableDataSource([]);

        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren as any);
        this.treeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.treeFlattenerBackOffice = new MatTreeFlattener(
            this.transformerBackOffice, this.getLevel, this.isExpandable, this.getChildren as any
        );
        this.treeControlBackOffice = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.dataSourceBackOffice = new MatTreeFlatDataSource(this.treeControlBackOffice, this.treeFlattenerBackOffice);

        this.database.setNestedNodeKey('ID');
        this.database.setNestedNodeLibelle(this.nestedNodeLibelle);
        this.database.setFlatNodeKey('ID');
        this.database.setFlatNodeNestedKey('Idtypepointdistribution');
        this.database.setFlatNodeLibelle(this.flatNodeLibelle);
        database.dataChange.subscribe(data => {
            this.dataSource.data = data;
        });

        this.databaseBackOffice.setNestedNodeKey('ID');
        this.databaseBackOffice.setNestedNodeLibelle(this.nestedNodeLibelle);
        this.databaseBackOffice.setFlatNodeKey('ID');
        this.databaseBackOffice.setFlatNodeNestedKey('Idtypepointdistribution');
        this.databaseBackOffice.setFlatNodeLibelle(this.flatNodeLibelle);
        databaseBackOffice.dataChange.subscribe(data => {
            this.dataSourceBackOffice.data = data;
        });
    }

    private overlayRef;

    selectedList = 'front';
    nestedNodeLibelle = ['Libelle', 'Canal'];
    flatNodeLibelle = ['Libelle', 'NomResponsable'];
    flatNodeMap = new Map<FlatNode, NestedNode>();
    nestedNodeMap = new Map<NestedNode, FlatNode>();
    selectedParent: FlatNode | null = null;
    newItemName = '';
    treeControl: FlatTreeControl<FlatNode>;
    treeFlattener: MatTreeFlattener<NestedNode, FlatNode>;
    dataSource: MatTreeFlatDataSource<NestedNode, FlatNode>;
    checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);
    checklistSelectionPoste = new SelectionModel<FlatNode>(true, []);

    flatNodeMapBackOffice = new Map<FlatNode, NestedNode>();
    nestedNodeMapBackOffice = new Map<NestedNode, FlatNode>();
    selectedParentBackOffice: FlatNode | null = null;
    treeControlBackOffice: FlatTreeControl<FlatNode>;
    treeFlattenerBackOffice: MatTreeFlattener<NestedNode, FlatNode>;
    dataSourceBackOffice: MatTreeFlatDataSource<NestedNode, FlatNode>;
    checklistSelectionBackOffice = new SelectionModel<FlatNode>(true /* multiple */);

    tableDisplayedColumns: string[] = ['actions', 'Libelle', 'AdresseMac1', 'DateDemandeEnrollement', 'DateValidationEnrollement', 'Etat'];
    dataSourceTable: MatTableDataSource<any>;
    dataSourceTableSettings: MatTableDataSource<any>;
    tableDisplayedColumnsSettings: string[] = ['Nouveau', 'CleParam', 'Thematique', 'Libelle', 'Valeur', 'CodeErreur'];
    private listePoste;
    private selectedPoint;
    private selectedPoste;
    private getDistributionPointTypeListObservable;
    private getDistributionPointListObservable;
    private getWorkstationListObservable;
    private getSystemSettingListObservable;
    private getDistributionPointSettingObservable;
    private getDistributionPointSettingListObservable;
    private getWorkstationSettingListObservable;
    private workstationtSettingCreateObservable;
    private workstationtSettingUpdateObservable;
    private workstationtSettingDeleteObservable;
    private distributionPointSettingCreateObservable;
    private distributionPointSettingUpdateObservable;
    private getApiManagementConfObservable;
    attachOverlay() { this.overlayRef.attach(new ComponentPortal(ProgressContainerComponent)); }
    detachOverlay() { setTimeout(() => { this.overlayRef.detach(); }, 100); }
    isString(element) { return typeof element === 'string'; }
    getLevel = (node: FlatNode) => node.level;
    isExpandable = (node: FlatNode) => node.expandable;
    getChildren = (node: NestedNode): FlatNode[] => node.children;
    hasChild = (_: number, nodeData: FlatNode) => nodeData.expandable;
    hasNoContent = (_: number, nodeData: FlatNode) => nodeData.Libelle === '';
    transformer = (node: NestedNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.Libelle === node.Libelle
            ? existingNode
            : new FlatNode();
        flatNode.Libelle = node.Libelle;
        flatNode.ID = node.ID;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }
    transformerBackOffice = (node: NestedNode, level: number) => {
        const existingNode = this.nestedNodeMapBackOffice.get(node);
        const flatNode = existingNode && existingNode.Libelle === node.Libelle
            ? existingNode
            : new FlatNode();
        flatNode.Libelle = node.Libelle;
        flatNode.ID = node.ID;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMapBackOffice.set(flatNode, node);
        this.nestedNodeMapBackOffice.set(node, flatNode);
        return flatNode;
    }

    ngOnInit() {
        this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });

        this.nodeJsInteractionService.getDistributionPointTypeList();
        this.getDistributionPointTypeListObservable = this.nodeJsInteractionService
            .getDistributionPointTypeListObservable.subscribe(data => {
                const response = data as any;
                const typePointDistributionList = (Array.isArray(response.TypePointDistribution)) ?
                    response.TypePointDistribution : [response.TypePointDistribution];
                this.database.setNestedNodeList(typePointDistributionList.filter(e => e.TypePoint === 'FrontOffice'));
                this.databaseBackOffice.setNestedNodeList(typePointDistributionList.filter(e => e.TypePoint === 'BackOffice'));
                this.nodeJsInteractionService.getDistributionPointList();
            });
        this.getDistributionPointListObservable = this.nodeJsInteractionService.getDistributionPointListObservable.subscribe(data => {
            const response = data as any;
            const pointDistributionList = (Array.isArray(response.PointDistribution)) ?
                response.PointDistribution : [response.PointDistribution];
            const flatNodeList = pointDistributionList.filter(point => this.database.getNestedNodeList().some((type) =>
                type.ID === point.Idtypepointdistribution)
            );
            const flatNodeListBackOffice = pointDistributionList.filter(point =>
                this.databaseBackOffice.getNestedNodeList().some((type) => type.ID === point.Idtypepointdistribution)
            );
            this.database.setFlatNodeList(flatNodeList);
            this.databaseBackOffice.setFlatNodeList(flatNodeListBackOffice);
            this.database.initialize();
            this.databaseBackOffice.initialize();
        });

        this.getWorkstationListObservable = this.nodeJsInteractionService.getWorkstationListObservable.subscribe(data => {
            const response = data as any;
            const dataSource = (Array.isArray(response.PosteDistribution)) ? response.PosteDistribution : [response.PosteDistribution];
            this.dataSourceTable = new MatTableDataSource((dataSource[0] !== undefined) ? dataSource.filter(elem =>
                elem.IdPointDistribution === this.selectedPoint.ID
            ) : []);
            this.dataSourceTable._updateChangeSubscription();
            this.detachOverlay();
        });


        this.getSystemSettingListObservable = this.nodeJsInteractionService.getSystemSettingListObservable.subscribe(data => {
            const response = data as any;
            const dataSource = (Array.isArray(response.ParametrageSysteme)) ? response.ParametrageSysteme : [response.ParametrageSysteme];
            this.dataSourceTableSettings = new MatTableDataSource(dataSource);
            this.dataSourceTableSettings._updateChangeSubscription();
            this.detachOverlay();
        });

        this.getDistributionPointSettingObservable = this.nodeJsInteractionService.getDistributionPointSettingObservable.subscribe(data => {
            const response = data as any;
        });

        this.getDistributionPointSettingListObservable = this.nodeJsInteractionService
            .getDistributionPointSettingListObservable.subscribe(data => {
                const response = data as any;
                const settings = (Array.isArray(response.ParametragePointDistribution)) ?
                    response.ParametragePointDistribution : [response.ParametragePointDistribution];
                this.openCreateConfigurationPointDialog(settings);
            });

        this.workstationtSettingCreateObservable = this.nodeJsInteractionService.workstationtSettingCreateObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            if (response.CodeErreur !== 0) {
                this.snackBar.open('Une erreur est survenue lors de l\'enregistrement des paramètres ', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
                return;
            }

            this.snackBar.open('Les paramètres ont été enregistrés', null, {
                duration: 3000,
                panelClass: 'success'
            });
        });

        this.workstationtSettingUpdateObservable = this.nodeJsInteractionService.workstationtSettingUpdateObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            if (response.CodeErreur !== 0) {
                this.snackBar.open('Une erreur est survenue lors de l\'enregistrement des paramètres ', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
                return;
            }

            this.snackBar.open('Les paramètres ont été enregistrés', null, {
                duration: 3000,
                panelClass: 'success'
            });
        });

        this.workstationtSettingDeleteObservable = this.nodeJsInteractionService.workstationtSettingDeleteObservable.subscribe(data => {
            const response = data as any;
            this.detachOverlay();
        });

        this.getWorkstationSettingListObservable = this.nodeJsInteractionService.getWorkstationSettingListObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            const paramsPoste = Array.isArray(response.ParametragePoste) ?
                response.ParametragePoste : [response.ParametragePoste];

            const settings = paramsPoste.filter(element => element.IdPosteDistribution === this.selectedPoste.ID);
            this.openCreateConfigurationPosteDialog(this.selectedPoste, settings);
        });

        this.distributionPointSettingCreateObservable = this.nodeJsInteractionService
            .distributionPointSettingCreateObservable.subscribe(data => {
                this.detachOverlay();
                const response = data as any;
                if (response.CodeErreur !== 0) {
                    this.snackBar.open('Une erreur est survenue lors de l\'enregistrement des paramètres ', null, {
                        duration: 3000,
                        panelClass: 'error'
                    });
                    return;
                }

                this.snackBar.open('Les paramètres ont été enregistrés', null, {
                    duration: 3000,
                    panelClass: 'success'
                });
            });
        this.distributionPointSettingUpdateObservable = this.nodeJsInteractionService
            .distributionPointSettingUpdateObservable.subscribe(data => {
                this.detachOverlay();
                const response = data as any;
                if (response.CodeErreur !== 0) {
                    this.snackBar.open('Une erreur est survenue lors de l\'enregistrement des paramètres ', null, {
                        duration: 3000,
                        panelClass: 'error'
                    });
                    return;
                }

                this.snackBar.open('Les paramètres ont été enregistrés', null, {
                    duration: 3000,
                    panelClass: 'success'
                });
            });


        this.getApiManagementConfObservable = this.nodeJsInteractionService.getApiManagementConfObservable.subscribe(data => {
            this.detachOverlay();
            const encryptedContent = data as any;
            const element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(encryptedContent));
            element.setAttribute('download', 'ApiManagement.conf');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        });
    }

    ngOnDestroy() {
        if (this.getDistributionPointTypeListObservable) { this.getDistributionPointTypeListObservable.unsubscribe(); }
        if (this.getDistributionPointListObservable) { this.getDistributionPointListObservable.unsubscribe(); }
        if (this.getWorkstationListObservable) { this.getWorkstationListObservable.unsubscribe(); }
        if (this.getSystemSettingListObservable) { this.getSystemSettingListObservable.unsubscribe(); }
        if (this.getDistributionPointSettingObservable) { this.getDistributionPointSettingObservable.unsubscribe(); }
        if (this.getDistributionPointSettingListObservable) { this.getDistributionPointSettingListObservable.unsubscribe(); }
        if (this.workstationtSettingCreateObservable) { this.workstationtSettingCreateObservable.unsubscribe(); }
        if (this.getWorkstationSettingListObservable) { this.getWorkstationSettingListObservable.unsubscribe(); }
        if (this.workstationtSettingUpdateObservable) { this.workstationtSettingUpdateObservable.unsubscribe(); }
        if (this.workstationtSettingDeleteObservable) { this.workstationtSettingDeleteObservable.unsubscribe(); }
        if (this.distributionPointSettingCreateObservable) { this.distributionPointSettingCreateObservable.unsubscribe(); }
        if (this.distributionPointSettingUpdateObservable) { this.distributionPointSettingUpdateObservable.unsubscribe(); }
        if (this.getApiManagementConfObservable) { this.getApiManagementConfObservable.unsubscribe(); }
    }

    setSelectedList(type) {
        this.selectedList = type;
        this.checklistSelection.clear();
        this.checklistSelectionBackOffice.clear();
    }

    openCreateConfigurationPosteDialogBeforeWs(row) {
        this.attachOverlay();
        this.selectedPoste = row;
        this.nodeJsInteractionService.getWorkstationSettingList(row.ID);
    }

    openCreateConfigurationPosteDialog(element, settings): void {
        element.selectedPoste = this.selectedPoste;
        element.settings = settings;
        const dialogRef = this.dialog.open(GeneralSettingsConfigurationCreatePosteDialogComponent, {
            width: '600px',
            data: element,
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== false && result !== undefined) {
                this.attachOverlay();
                const nfcReaderId = (settings.find(elm => elm !== undefined && elm.CleParam === 'HYBRIDE_LECTEUR_NFC') !== undefined) ?
                    settings.find(elm => elm.CleParam === 'HYBRIDE_LECTEUR_NFC').ID : 0;
                const printerId = (settings.find(elm => elm !== undefined && elm.CleParam === 'HYBRIDE_IMPRIMANTE_TICKET') !== undefined) ?
                    settings.find(elm => elm.CleParam === 'HYBRIDE_IMPRIMANTE_TICKET').ID : 0;
                const macNfcReaderId = (settings.find(elm =>
                    elm !== undefined && elm.CleParam === 'HYBRIDE_ADRESSE_MAC_NFC') !== undefined) ?
                    settings.find(elm => elm.CleParam === 'HYBRIDE_ADRESSE_MAC_NFC').ID : 0;

                const nfcReaderParam = {
                    ID: nfcReaderId,
                    IdPosteDistribution: element.ID,
                    CleParam: 'HYBRIDE_LECTEUR_NFC',
                    Thematique: 'PARAM',
                    Libelle: '',
                    Valeur: result.HYBRIDE_LECTEUR_NFC,
                    CodeErreur: 0
                };
                if (nfcReaderParam.ID !== 0) {
                    this.nodeJsInteractionService.workstationtSettingUpdate(nfcReaderParam);
                } else {
                    this.nodeJsInteractionService.workstationtSettingCreate(nfcReaderParam);
                }

                const printParam = {
                    ID: printerId,
                    IdPosteDistribution: element.ID,
                    CleParam: 'HYBRIDE_IMPRIMANTE_TICKET',
                    Thematique: 'PARAM',
                    Libelle: '',
                    Valeur: result.HYBRIDE_IMPRIMANTE_TICKET,
                    CodeErreur: 0
                };
                if (printParam.ID !== 0) {
                    this.nodeJsInteractionService.workstationtSettingUpdate(printParam);
                } else {
                    this.nodeJsInteractionService.workstationtSettingCreate(printParam);
                }

                const macNfcReaderParam = {
                    ID: macNfcReaderId,
                    IdPosteDistribution: element.ID,
                    CleParam: 'HYBRIDE_ADRESSE_MAC_NFC',
                    Thematique: 'PARAM',
                    Libelle: '',
                    Valeur: result.HYBRIDE_ADRESSE_MAC_NFC,
                    CodeErreur: 0
                };
                if (macNfcReaderParam.ID !== 0) {
                    this.nodeJsInteractionService.workstationtSettingUpdate(macNfcReaderParam);
                } else {
                    this.nodeJsInteractionService.workstationtSettingCreate(macNfcReaderParam);
                }
            }
        });
    }

    openCreateConfigurationPointDialogBeforeWs(node) {
        this.attachOverlay();
        this.selectedPoint = node;
        this.nodeJsInteractionService.getDistributionPointSettingList(node.ID);
    }

    openCreateConfigurationPointDialog(settings): void {
        this.detachOverlay();
        const data = {
            selectedPoint: this.selectedPoint,
            settings
        };
        const dialogRef = this.dialog.open(GeneralSettingsConfigurationCreatePointDialogComponent, {
            width: '600px',
            data,
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== false && result !== undefined) {
                this.attachOverlay();
                const confirmParamId = (settings.find(element =>
                    element !== undefined && element.CleParam === 'CYCLEVENTE_PANIER_CONFIRMATION_SUPPRESSION_PRODUIT') !== undefined
                ) ? settings.find(element => element.CleParam === 'CYCLEVENTE_PANIER_CONFIRMATION_SUPPRESSION_PRODUIT').ID : 0;
                const confirmParam = {
                    ID: confirmParamId,
                    IdPointDistribution: this.selectedPoint.ID,
                    CleParam: 'CYCLEVENTE_PANIER_CONFIRMATION_SUPPRESSION_PRODUIT',
                    Thematique: 'CYCLEVENTE',
                    Libelle: '',
                    Valeur: (result.CYCLEVENTE_PANIER_CONFIRMATION_SUPPRESSION_PRODUIT === false) ? 0 : 1,
                    CodeErreur: 0
                };

                if (confirmParam.ID !== 0) {
                    this.nodeJsInteractionService.distributionPointSettingUpdate(confirmParam);
                } else {
                    this.nodeJsInteractionService.distributionPointSettingCreate(confirmParam);
                }

                const clRedirectId = (settings.find(element =>
                    element !== undefined && element.CleParam === 'CYCLEVENTE_MENU_TRG_CHARGEMENT') !== undefined
                ) ? settings.find(element => element.CleParam === 'CYCLEVENTE_MENU_TRG_CHARGEMENT').ID : 0;
                const clRedirectParam = {
                    ID: clRedirectId,
                    IdPointDistribution: this.selectedPoint.ID,
                    CleParam: 'CYCLEVENTE_MENU_TRG_CHARGEMENT',
                    Thematique: 'CYCLEVENTE',
                    Libelle: '',
                    Valeur: result.CYCLEVENTE_MENU_TRG_CHARGEMENT,
                    CodeErreur: 0
                };
                if (clRedirectParam.ID !== 0) {
                    if (result.CYCLEVENTE_MENU_TRG_CHARGEMENT_TOGGLE) {
                        this.nodeJsInteractionService.distributionPointSettingUpdate(clRedirectParam);
                    } else {
                        this.nodeJsInteractionService.distributionPointSettingDelete(clRedirectParam.ID);
                    }
                } else if (result.CYCLEVENTE_MENU_TRG_CHARGEMENT_TOGGLE) {
                    this.nodeJsInteractionService.distributionPointSettingCreate(clRedirectParam);
                }
            }
        });
    }


    openListingConfigurationSystem(): void {
        const dialogRef = this.dialog.open(GeneralSettingsConfigurationSystemListingDialogComponent, {
            width: '1300px',
            height: '600px'
        });

        this.nodeJsInteractionService.getSystemSettingList();
        dialogRef.afterClosed().subscribe(result => {

        });
    }

    openApiManagementConfGenerationForm(): void {
        const dialogRef = this.dialog.open(GeneralSettingsApiManagementFormDialogComponent, {
            width: '600px',
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.attachOverlay();
                this.nodeJsInteractionService.getApiManagementConf(result);
            }
        });
    }

    todoLeafItemSelectionToggle(node: FlatNode): void {
        this.attachOverlay();
        this.nodeJsInteractionService.getWorkstationList(node.ID);
        this.selectedPoint = node;
        this.checklistSelection.clear();
        this.checklistSelection.select(node);
    }
}
