import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FlatNode, NestedNode, DistributionPointDatabaseService } from '../_service/distribution-point-database.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ProgressContainerComponent } from '../_ui/progress-container/progress-container.component';
import { TitleService } from '../_service/title.service';
import { NodeJsInteractionService } from '../_service/nodejs-interaction.service';
import { Overlay } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

@Component({
    selector: 'app-distribution-point-functionnality',
    templateUrl: './distribution-point-functionnality.component.html',
    styleUrls: ['./distribution-point-functionnality.component.less'],
    providers: [
        { provide: 'instance1', useClass: DistributionPointDatabaseService },
        { provide: 'instance2', useClass: DistributionPointDatabaseService },
        { provide: 'instance3', useClass: DistributionPointDatabaseService }
    ]
})
export class DistributionPointFunctionnalityComponent implements OnInit, OnDestroy {

    constructor(
        public titleService: TitleService,
        @Inject('instance1') public database: DistributionPointDatabaseService,
        @Inject('instance2') public databaseBackOffice: DistributionPointDatabaseService,
        @Inject('instance3') public databaseFunctionnality: DistributionPointDatabaseService,
        public nodeJsInteractionService: NodeJsInteractionService,
        public overlay: Overlay,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public router: Router
    ) {
        this.titleService.setTitle('Types de point de distribution - Gestion des fonctionnalités');
        this.titleService.setEcranNumber(4);

        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren as any);
        this.treeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.treeFlattenerBackOffice = new MatTreeFlattener(
            this.transformerBackOffice, this.getLevel, this.isExpandable, this.getChildren as any
        );
        this.treeControlBackOffice = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.dataSourceBackOffice = new MatTreeFlatDataSource(this.treeControlBackOffice, this.treeFlattenerBackOffice);

        this.database.setNestedNodeKey('ID');
        this.database.setNestedNodeLibelle(this.nestedNodeLibelle);
        this.database.setFlatNodeKey('ID');
        this.database.setFlatNodeNestedKey('Idtypepointdistribution');
        this.database.setFlatNodeLibelle(this.flatNodeLibelle);
        database.dataChange.subscribe(data => {
            this.dataSource.data = data;
        });

        this.databaseBackOffice.setNestedNodeKey('ID');
        this.databaseBackOffice.setNestedNodeLibelle(this.nestedNodeLibelle);
        this.databaseBackOffice.setFlatNodeKey('ID');
        this.databaseBackOffice.setFlatNodeNestedKey('Idtypepointdistribution');
        this.databaseBackOffice.setFlatNodeLibelle(this.flatNodeLibelle);
        databaseBackOffice.dataChange.subscribe(data => {
            this.dataSourceBackOffice.data = data;
        });


        this.treeFlattenerFunctionnality = new MatTreeFlattener(
            this.transformerFunctionnality, this.getLevel, this.isExpandable, this.getChildren as any
        );
        this.treeControlFunctionnality = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.dataSourceFunctionnality = new MatTreeFlatDataSource(this.treeControlFunctionnality, this.treeFlattenerFunctionnality);
        this.databaseFunctionnality.setNestedNodeKey('Libelle');
        this.databaseFunctionnality.setNestedNodeLibelle('Libelle');
        this.databaseFunctionnality.setFlatNodeKey('ID');
        this.databaseFunctionnality.setFlatNodeLibelle('Libelle');
        this.databaseFunctionnality.setFlatNodeNestedKey('Groupe');

        databaseFunctionnality.dataChange.subscribe(data => {
            this.dataSourceFunctionnality.data = data;
        });

    }

    private overlayRef;

    selectedList = 'front';
    nestedNodeLibelle = ['Libelle', 'Canal'];
    flatNodeLibelle = ['Libelle', 'NomResponsable'];
    flatNodeMap = new Map<FlatNode, NestedNode>();
    nestedNodeMap = new Map<NestedNode, FlatNode>();
    selectedParent: FlatNode | null = null;
    treeControl: FlatTreeControl<FlatNode>;
    treeFlattener: MatTreeFlattener<NestedNode, FlatNode>;
    dataSource: MatTreeFlatDataSource<NestedNode, FlatNode>;
    checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);

    flatNodeMapBackOffice = new Map<FlatNode, NestedNode>();
    nestedNodeMapBackOffice = new Map<NestedNode, FlatNode>();
    selectedParentBackOffice: FlatNode | null = null;
    treeControlBackOffice: FlatTreeControl<FlatNode>;
    treeFlattenerBackOffice: MatTreeFlattener<NestedNode, FlatNode>;
    dataSourceBackOffice: MatTreeFlatDataSource<NestedNode, FlatNode>;
    checklistSelectionBackOffice = new SelectionModel<FlatNode>(true /* multiple */);

    flatNodeMapFunctionnality = new Map<FlatNode, NestedNode>();
    nestedNodeMapFunctionnality = new Map<NestedNode, FlatNode>();
    treeControlFunctionnality: FlatTreeControl<FlatNode>;
    treeFlattenerFunctionnality: MatTreeFlattener<NestedNode, FlatNode>;
    dataSourceFunctionnality: MatTreeFlatDataSource<NestedNode, FlatNode>;
    checklistSelectionFunctionnality = new SelectionModel<FlatNode>(true, []);

    private cptWsMaj = 0;
    private backOfficeFunction = [];
    private frontOfficeFunction = [];
    private assocPointTypeFunctionnality = [];
    private getDistributionPointTypeListObservable;
    private getFunctionalityListObservable;
    private getDistributionPointTypeFunctionalityAssociationListObservable;
    private distributionPointTypeFunctionalityAssociationCreateObservable;
    private distributionPointTypeFunctionalityAssociationDeleteObservable;
    attachOverlay() { this.overlayRef.attach(new ComponentPortal(ProgressContainerComponent)); }
    detachOverlay() { setTimeout(() => { this.overlayRef.detach(); }, 200); }
    getLevel = (node: FlatNode) => node.level;
    isExpandable = (node: FlatNode) => node.expandable;
    getChildren = (node: NestedNode): FlatNode[] => node.children;
    getChildrenNumber = (node: any): any => this.treeControl.getDescendants(node).length;
    hasChild = (_: number, nodeData: FlatNode) => nodeData.expandable;
    hasNoContent = (_: number, nodeData: FlatNode) => nodeData.Libelle === '';
    transformer = (node: NestedNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.Libelle === node.Libelle
            ? existingNode
            : new FlatNode();
        flatNode.Libelle = node.Libelle;
        flatNode.ID = node.ID;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }
    transformerBackOffice = (node: NestedNode, level: number) => {
        const existingNode = this.nestedNodeMapBackOffice.get(node);
        const flatNode = existingNode && existingNode.Libelle === node.Libelle
            ? existingNode
            : new FlatNode();
        flatNode.Libelle = node.Libelle;
        flatNode.ID = node.ID;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMapBackOffice.set(flatNode, node);
        this.nestedNodeMapBackOffice.set(node, flatNode);
        return flatNode;
    }
    transformerFunctionnality = (node: NestedNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.Libelle === node.Libelle
            ? existingNode
            : new FlatNode();
        flatNode.Libelle = node.Libelle;
        flatNode.ID = node.ID;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMapFunctionnality.set(flatNode, node);
        this.nestedNodeMapFunctionnality.set(node, flatNode);
        return flatNode;
    }
    ngOnInit() {
        if (!this.nodeJsInteractionService.functionnalityAccess.checkAccess('GESTION_FONCTIONNALITE')) {
            this.router.navigate(['/home']);
        }

        this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });

        this.nodeJsInteractionService.getDistributionPointTypeList();
        this.getDistributionPointTypeListObservable = this.nodeJsInteractionService
            .getDistributionPointTypeListObservable.subscribe(data => {
                const response = data as any;
                const typePointDistributionList = (Array.isArray(response.TypePointDistribution)) ?
                    response.TypePointDistribution : [response.TypePointDistribution];

                this.database.setNestedNodeList(typePointDistributionList.filter(e => e.TypePoint === 'FrontOffice'));

                this.database.setFlatNodeList([]);
                this.database.initialize();

                this.databaseBackOffice.setNestedNodeList(typePointDistributionList.filter(e => e.TypePoint === 'BackOffice'));
                this.databaseBackOffice.setFlatNodeList([]);
                this.databaseBackOffice.initialize();
            });

        this.nodeJsInteractionService.getFunctionalityList();
        this.getFunctionalityListObservable = this.nodeJsInteractionService.getFunctionalityListObservable.subscribe(data => {
            const response = data as any;

            const functionList = (Array.isArray(response.Fonctionnalite)) ? response.Fonctionnalite : [response.Fonctionnalite];

            this.frontOfficeFunction = functionList.filter(e => e.Type === 'FrontOffice');
            this.backOfficeFunction = functionList.filter(e => e.Type === 'BackOffice');
            this.databaseFunctionnality.setFlatNodeList(this.frontOfficeFunction);
            this.groupByCtgFunctionnality();
            this.databaseFunctionnality.initialize();
        });


        this.getDistributionPointTypeFunctionalityAssociationListObservable = this.nodeJsInteractionService
            .getDistributionPointTypeFunctionalityAssociationListObservable.subscribe(data => {
                const response = data as any;
                const productListId = (Array.isArray(response.int)) ? response.int : [response.int];
                const flatNodeList = this.databaseFunctionnality.getFlatNodeList();
                flatNodeList.forEach(node => {
                    if (productListId.includes(node[this.databaseFunctionnality.getFlatNodeKey()])) {
                        const assocNode = this.databaseFunctionnality.findNode(
                            this.treeControlFunctionnality.dataNodes, node[this.databaseFunctionnality.getFlatNodeKey()]
                        );
                        this.checklistSelectionFunctionnality.select(assocNode);
                        const parentNode = this.getParentNodeFunctionnality(assocNode);
                        const descendants = this.treeControlFunctionnality.getDescendants(parentNode);
                        if (descendants.every(child => this.checklistSelectionFunctionnality.isSelected(child))) {
                            this.checklistSelectionFunctionnality.select(parentNode);
                        }

                        this.treeControlFunctionnality.expand(parentNode);
                        this.assocPointTypeFunctionnality.push(assocNode.ID);
                    }
                });
                this.detachOverlay();
            });

        this.distributionPointTypeFunctionalityAssociationCreateObservable = this.nodeJsInteractionService
            .distributionPointTypeFunctionalityAssociationCreateObservable.subscribe(data => {
                this.cptWsMaj--;
                if (this.cptWsMaj === 0) {
                    this.detachOverlay();
                    this.snackBar.open('La liste des fonctionnalités disponibles a été correctement mis à jour', null, {
                        duration: 3000,
                        panelClass: 'success'
                    });
                }
            });

        this.distributionPointTypeFunctionalityAssociationDeleteObservable = this.nodeJsInteractionService
            .distributionPointTypeFunctionalityAssociationDeleteObservable.subscribe(data => {
                this.cptWsMaj--;
                if (this.cptWsMaj === 0) {
                    this.detachOverlay();
                    this.snackBar.open('La liste des fonctionnalités disponibles a été correctement mis à jour', null, {
                        duration: 3000,
                        panelClass: 'success'
                    });
                }
            });
    }

    ngOnDestroy() {
        this.getDistributionPointTypeListObservable.unsubscribe();
        this.getFunctionalityListObservable.unsubscribe();
        this.getDistributionPointTypeFunctionalityAssociationListObservable.unsubscribe();
        this.distributionPointTypeFunctionalityAssociationCreateObservable.unsubscribe();
        this.distributionPointTypeFunctionalityAssociationDeleteObservable.unsubscribe();
    }

    groupByCtgFunctionnality() {
        let idCtg = 1;
        const nestedNodeList = [];
        this.databaseFunctionnality.getFlatNodeList().forEach(functionnality => {
            const checkCtg = nestedNodeList.filter(
                element => element.Libelle === functionnality.Groupe
            )[0];
            if (checkCtg === undefined) {
                nestedNodeList.push({
                    IdCtg: idCtg,
                    Libelle: functionnality.Groupe
                });
                idCtg++;
            }
        });
        this.databaseFunctionnality.setNestedNodeList(nestedNodeList);
    }

    setSelectedList(type) {
        this.selectedList = type;
        this.checklistSelection.clear();
        this.checklistSelectionBackOffice.clear();
        this.databaseFunctionnality.setFlatNodeList(type === 'front' ?
            this.frontOfficeFunction : this.backOfficeFunction);
        this.groupByCtgFunctionnality();
        this.databaseFunctionnality.initialize();
    }


    saveDistributionPointTypeFunctionnalityAssociation() {
        const self = this;

        if (this.checklistSelection.selected.length <= 0) {
            this.snackBar.open('Vous devez sélectionner un type de point avant de sauvegarder', null, {
                duration: 3000,
                panelClass: 'infos'
            });
            return false;
        }

        this.attachOverlay();
        const idType = this.checklistSelection.selected[0][this.database.getFlatNodeKey()];
        const checklistSelectionProductSelected = this.checklistSelectionFunctionnality.selected.filter(element =>
            element.expandable === false
        );
        const deltaToDelete = this.assocPointTypeFunctionnality.filter((item) => {
            return !checklistSelectionProductSelected.find(element =>
                element[self.databaseFunctionnality.getFlatNodeKey()] === item
            );
        });

        this.cptWsMaj = checklistSelectionProductSelected.length + deltaToDelete.length;
        if (checklistSelectionProductSelected.length === 0 && deltaToDelete.length === 0) {
            this.detachOverlay();
        }
        checklistSelectionProductSelected.forEach(elem => {
            this.nodeJsInteractionService.distributionPointTypeFunctionalityAssociationCreate({
                pIdTypePointDistribution: idType.toString(),
                pIdFonctionnalite: elem.ID.toString()
            });
        });

        deltaToDelete.forEach(elem => {
            this.nodeJsInteractionService.distributionPointTypeFunctionalityAssociationDelete({
                pIdTypePointDistribution: idType.toString(),
                pIdFonctionnalite: elem.toString()
            });
        });
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: FlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        if (descendants.length === 0) {
            return false;
        }
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: FlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggle(node: FlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        this.checkAllParentsSelection(node);
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggle(node: FlatNode): void {
        const wasSelected = this.checklistSelection.isSelected(node);
        this.assocPointTypeFunctionnality = [];
        this.checklistSelectionFunctionnality.clear();
        this.treeControlFunctionnality.collapseAll();
        this.checklistSelection.clear();
        if (!wasSelected) {
            this.checklistSelection.select(node);
            this.nodeJsInteractionService.getDistributionPointTypeFunctionalityAssociationList(node.ID);
        }
        this.checkAllParentsSelection(node);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: FlatNode): void {
        let parent: FlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: FlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: FlatNode): FlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelectedFunctionnality(node: FlatNode): boolean {
        const descendants = this.treeControlFunctionnality.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelectionFunctionnality.isSelected(child)
        );
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelectedFunctionnality(node: FlatNode): boolean {
        const descendants = this.treeControlFunctionnality.getDescendants(node);
        const result = descendants.some(child => this.checklistSelectionFunctionnality.isSelected(child));
        return result && !this.descendantsAllSelectedFunctionnality(node);
    }


    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggleFunctionnality(node: FlatNode): void {
        this.checklistSelectionFunctionnality.toggle(node);
        const descendants = this.treeControlFunctionnality.getDescendants(node);

        this.checklistSelectionFunctionnality.isSelected(node)
            ? this.checklistSelectionFunctionnality.select(...descendants)
            : this.checklistSelectionFunctionnality.deselect(...descendants);

        // Force update for the parent
        descendants.every(child =>
            this.checklistSelectionFunctionnality.isSelected(child)
        );
        this.checkAllParentsSelectionFunctionnality(node);
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggleFunctionnality(node: FlatNode): void {
        this.checklistSelectionFunctionnality.toggle(node);
        this.checkAllParentsSelectionFunctionnality(node);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelectionFunctionnality(node: FlatNode): void {
        let parent: FlatNode | null = this.getParentNodeFunctionnality(node);
        while (parent) {
            this.checkRootNodeSelectionFunctionnality(parent);
            parent = this.getParentNodeFunctionnality(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelectionFunctionnality(node: FlatNode): void {
        const nodeSelected = this.checklistSelectionFunctionnality.isSelected(node);
        const descendants = this.treeControlFunctionnality.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelectionFunctionnality.isSelected(child)
        );
        if (nodeSelected && !descAllSelected) {
            this.checklistSelectionFunctionnality.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelectionFunctionnality.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNodeFunctionnality(node: FlatNode): FlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControlFunctionnality.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControlFunctionnality.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }
}
