import { Component, OnInit, Inject, ViewChild, OnDestroy } from "@angular/core";
import { TitleService } from "../_service/title.service";
import { NodeJsInteractionService } from "../_service/nodejs-interaction.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSort,
} from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ConfirmationDialogComponent } from "../_ui/confirmation-dialog/confirmation-dialog.component";
import { MatTableDataSource } from "@angular/material/table";
import { DistributionPointDialogComponent } from "../_ui/distribution-point-dialog/distribution-point-dialog.component";
import { Router } from "@angular/router";
import { ComponentPortal } from "@angular/cdk/portal";
import { ProgressContainerComponent } from "../_ui/progress-container/progress-container.component";
import { Overlay } from "@angular/cdk/overlay";

export class Group {
  level = 0;
  parent: Group;
  expanded = false;
  totalCounts = 0;
  totalReads = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

@Component({
  selector: "read-message",
  templateUrl: "messaging-read-message-dialog.html",
  styleUrls: ["./messaging.component.less"],
})
export class ReadMessageComponent {
  constructor(
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ReadMessageComponent>,
    private nodeJsInteractionService: NodeJsInteractionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.data = data;
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (data.Statut !== "Lu") {
      this.nodeJsInteractionService.messageUpdateStatus({
        pIdUtilisateur: currentUser.ID,
        pIdMessage: data.ID,
        pStatut: "Lu",
      });
    }
  }

  sendResponse(data) {
    this.dialogRef.close(data);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}

@Component({
  selector: "app-messaging",
  templateUrl: "./messaging.component.html",
  styleUrls: ["./messaging.component.less"],
})
export class MessagingComponent implements OnInit, OnDestroy {
  constructor(
    public titleService: TitleService,
    public nodeJsInteractionService: NodeJsInteractionService,
    public overlay: Overlay,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public router: Router
  ) {
    this.titleService.setTitle("Messagerie - Gestions des mails");
    this.titleService.setEcranNumber(5);
    this.selectionTable = new SelectionModel<any>(true, []);
    this.displayedColumns = this.columns.map((column) => column.field);
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  private overlayRef;

  currentUser;
  messageObject = { Subject: null, Content: null };
  selectedDraft = null;
  statusToUpdate = null;
  receverId = [];
  receverLabel = "";
  userList = [];
  sendMail = false;
  selectedList = "received";
  sendResponse;
  cptWs = 0;
  userToRespond = null;

  selectionTable: SelectionModel<any>;
  selectionTableSend: SelectionModel<any>;
  // tableDisplayedColumns: string[] = ['select', 'NomExpediteur', 'LibelleThematiqueMessage', 'Objet', 'DateMessage', 'Statut'];
  dataSourceTable = new MatTableDataSource([]);

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "380px",
    maxHeight: "auto",
    width: "auto",
    toolbarHiddenButtons: [
      [],
      ["link", "unlink", "insertVideo", "insertVideo", "toggleEditorMode"],
    ],
  };

  getUserListObservable;
  getMessageListByUserObservable;
  messageCreateWithAssociationObservable;
  messageUpdateObservable;
  messageUpdateStatusObservable;
  messageDeleteObservable;
  messageSendList;
  messageDraftList;
  messageReceiptList;
  getUserObservable;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  allData: any[];
  columns: any[] = [
    { field: "select" },
    { field: "NomExpediteur" },
    { field: "LibelleThematiqueMessage" },
    { field: "Objet" },
    { field: "DateMessage" },
    { field: "Statut" },
  ];
  displayedColumns: string[];
  groupByColumns: string[] = ["GroupByColumn"];
  attachOverlay() {
    this.overlayRef.attach(new ComponentPortal(ProgressContainerComponent));
  }
  detachOverlay() {
    setTimeout(() => {
      this.overlayRef.detach();
    }, 200);
  }
  ngOnInit() {
    if (
      !this.nodeJsInteractionService.functionnalityAccess.checkAccess(
        "MESSAGERIE_ENVOYER_MESSAGE_VERS_POINTS_DISTRIBUTION"
      )
    ) {
      this.router.navigate(["/home"]);
    }
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      hasBackdrop: true,
    });

    this.attachOverlay();
    this.nodeJsInteractionService.getUserList();
    this.getUserListObservable =
      this.nodeJsInteractionService.getUserListObservable.subscribe((data) => {
        const response = data as any;
        this.userList = response.Utilisateur;
        this.nodeJsInteractionService.getMessageListByUser(this.currentUser.ID);
      });

    this.getMessageListByUserObservable =
      this.nodeJsInteractionService.getMessageListByUserObservable.subscribe(
        (data) => {
          this.detachOverlay();
          const response = data as any;
          const messageList = Array.isArray(response.MessageAlt)
            ? response.MessageAlt
            : response.MessageAlt !== undefined && response.MessageAlt !== ""
            ? [response.MessageAlt]
            : [];
          messageList.sort((a, b) => {
            const splitA = a.DateMessage.split("/");
            const splitB = b.DateMessage.split("/");
            return (
              new Date(+splitB[2], splitB[1] - 1, splitB[0]).getTime() +
              b.ID -
              (new Date(+splitA[2], splitA[1] - 1, splitA[0]).getTime() + a.ID)
            );
          });
          messageList.forEach(
            (e) =>
              (e.GroupByColumn =
                e.DateMessage +
                " - " +
                e.NomExpediteur +
                " - " +
                e.LibelleThematiqueMessage +
                " - " +
                e.Objet)
          );
          this.messageSendList = messageList.filter(
            (e) => e.IdExpediteur === this.currentUser.ID && !e.Brouillon
          );
          console.log("this.messageSendList");
          console.log(this.messageSendList);
          this.messageDraftList = messageList.filter(
            (e) => e.IdExpediteur === this.currentUser.ID && e.Brouillon
          );
          console.log("this.messageDraftList");
          console.log(this.messageDraftList);
          this.messageDraftList.forEach((e) => {
            e.Statut = "Aucun";
          });
          console.log("this.messageReceiptList");
          console.log(this.messageReceiptList);
          this.messageReceiptList = messageList.filter(
            (e) => e.IdExpediteur !== this.currentUser.ID
          );

          this.dataSourceTable = new MatTableDataSource(
            this.messageReceiptList
          );
          this.dataSourceTable.sort = this.sort;
        }
      );

    this.messageCreateWithAssociationObservable =
      this.nodeJsInteractionService.messageCreateWithAssociationObservable.subscribe(
        (data) => {
          const response = data as any;
          if (this.selectedDraft !== null) {
            this.nodeJsInteractionService.messageDelete(this.selectedDraft.ID);
          } else {
            this.detachOverlay();
            this.resetMessageContent();
          }
          if (response.CodeErreur !== 0) {
            this.snackBar.open(
              response.Brouillon
                ? "Une erreur s'est produite lors de l'enregistrement du brouillon "
                : "Une erreur s'est produite lors de l'envoi du mail ",
              null,
              {
                duration: 3000,
                panelClass: "error",
              }
            );
            return false;
          }
          response.NomExpediteur =
            this.currentUser.prenom + " " + this.currentUser.nom;
          response.Brouillon
            ? this.messageDraftList.unshift(response)
            : this.messageSendList.unshift(response);
          this.snackBar.open(
            response.Brouillon
              ? "Le brouillon a bien été enregistré"
              : "Le mail a bien été envoyé",
            null,
            {
              duration: 3000,
              panelClass: "success",
            }
          );
          this.setSelectedList(
            response.Brouillon && this.selectedDraft === null
              ? "draft"
              : "received",
            true
          );
        }
      );

    this.messageUpdateObservable =
      this.nodeJsInteractionService.messageUpdateObservable.subscribe(
        (data) => {
          const response = data as any;
          this.detachOverlay();
          if (response.CodeErreur !== 0) {
            this.snackBar.open(
              "Une erreur s'est produite lors de la mise à jour du brouillon ",
              null,
              {
                duration: 3000,
                panelClass: "error",
              }
            );
            return false;
          }
          this.snackBar.open("Le brouillon a bien été mis à jour", null, {
            duration: 3000,
            panelClass: "success",
          });
          response.NomExpediteur =
            this.currentUser.prenom + " " + this.currentUser.nom;
          this.messageDraftList.splice(
            this.messageDraftList.findIndex((e) => e.ID === response.ID),
            1,
            response
          );
          this.setSelectedList("draft", false);
          this.resetMessageContent();
        }
      );

    this.messageUpdateStatusObservable =
      this.nodeJsInteractionService.messageUpdateStatusObservable.subscribe(
        (data) => {
          const response = data as any;
          this.messageReceiptList.find((e) => e.ID === response.ID).Statut =
            this.statusToUpdate;
          this.cptWs--;
          if (this.cptWs === 0) {
            this.dataSourceTable._updateChangeSubscription();
            this.statusToUpdate = null;
            this.detachOverlay();
          }
        }
      );

    this.messageDeleteObservable =
      this.nodeJsInteractionService.messageDeleteObservable.subscribe(
        (data) => {
          // Cas où le brouillion à été supprimé car il à été envoyé:
          if (this.selectedDraft !== null) {
            this.messageDraftList.splice(
              this.messageDraftList.findIndex(
                (e) => e.ID === this.selectedDraft.ID
              ),
              1
            );
            this.setSelectedList("received", true);
            this.resetMessageContent();
          } else {
            this.cptWs--;
            if (this.cptWs === 0) {
              this.snackBar.open("La sélection a bien été supprimée", null, {
                duration: 3000,
                panelClass: "success",
              });
              this.selectionTable.selected.forEach((message) => {
                const messageSendListIDs = this.messageSendList.map(
                  (e) => e.ID
                );
                const messageDraftListIDs = this.messageDraftList.map(
                  (e) => e.ID
                );
                const messageReceiptListIDs = this.messageReceiptList.map(
                  (e) => e.ID
                );
                if (messageSendListIDs.includes(message.ID)) {
                  this.messageSendList.splice(
                    this.messageSendList.findIndex((e) => e.ID === message.ID),
                    1
                  );
                } else if (messageDraftListIDs.includes(message.ID)) {
                  this.messageDraftList.splice(
                    this.messageDraftList.findIndex((e) => e.ID === message.ID),
                    1
                  );
                } else if (messageReceiptListIDs.includes(message.ID)) {
                  this.messageReceiptList.splice(
                    this.messageReceiptList.findIndex(
                      (e) => e.ID === message.ID
                    ),
                    1
                  );
                }
              });
              this.selectionTable.clear();
              this.dataSourceTable._updateChangeSubscription();
              this.detachOverlay();
            }
          }
        }
      );

    this.getUserObservable =
      this.nodeJsInteractionService.getUserObservable.subscribe((data) => {
        const response = data as any;
        this.userToRespond = response;
      });
  }

  setSelectedList(value, refresh) {
    this.selectedList = value;
    switch (this.selectedList) {
      case "received":
        if (refresh) {
          this.nodeJsInteractionService.getMessageListByUser(
            this.currentUser.ID
          );
          if (this.selectedDraft === null) {
            try {
              this.attachOverlay();
            } catch {}
          }
        }
        break;
      case "send":
        this.dataSourceTable.data = this.messageSendList;
        this.dataSourceTable._updateChangeSubscription();
        this.createGroupByRows();
        break;
      case "draft":
        this.dataSourceTable.data = this.messageDraftList;
        this.dataSourceTable._updateChangeSubscription();
        break;
    }
  }

  ngOnDestroy() {
    this.getUserListObservable.unsubscribe();
    this.getMessageListByUserObservable.unsubscribe();
    this.messageCreateWithAssociationObservable.unsubscribe();
    this.messageUpdateObservable.unsubscribe();
    this.messageUpdateStatusObservable.unsubscribe();
    this.messageDeleteObservable.unsubscribe();
    this.getUserObservable.unsubscribe();
    this.detachOverlay();
  }

  unescapeHtml(safe) {
    return safe
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'");
  }
  escapeHtml(unsafe) {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  resetMessageContent() {
    this.sendMail = false;
    this.receverId = null;
    this.receverLabel = null;
    this.messageObject.Subject = "";
    this.messageObject.Content = "";
    this.selectedDraft = null;
    this.sendResponse = false;
    this.userToRespond = null;
  }

  setMessageStatus(status) {
    if (
      this.selectionTable.selected.length > 0 &&
      this.selectionTable.selected[0] !== undefined
    ) {
      this.statusToUpdate = status;
      this.cptWs = this.selectionTable.selected.length;
      this.attachOverlay();
      this.selectionTable.selected.forEach((message) => {
        this.nodeJsInteractionService.messageUpdateStatus({
          pIdUtilisateur: this.currentUser.ID,
          pIdMessage: message.ID,
          pStatut: status,
        });
      });
    }
  }

  deleteSelectedMessage() {
    if (
      this.selectionTable.selected.length > 0 &&
      this.selectionTable.selected[0] !== undefined
    ) {
      this.cptWs = this.selectionTable.selected.length;
      this.attachOverlay();
      this.selectionTable.selected.forEach((message) => {
        this.nodeJsInteractionService.messageDelete(message.ID);
      });
    }
  }

  openDialogPointDistrib(): void {
    const dialogRef = this.dialog.open(DistributionPointDialogComponent, {
      width: "600px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.receverId = [];
      this.receverLabel = "";
      if (result) {
        result.forEach((element) => {
          if (element.expandable === true) {
            return;
          }
          const userList = this.userList.filter(
            (e) => e.id_point_distribution === element.ID
          );
          userList.forEach((user) => {
            this.receverId.push(user.ID);
          });
          this.receverLabel += element.Libelle + ", ";
        });
      }
      this.receverLabel = this.receverLabel.substr(
        0,
        this.receverLabel.length - 2
      );
    });
  }

  openMessageDialog(element): void {
    switch (this.selectedList) {
      case "send":
        element.isSend = true;
        break;
      case "draft":
        this.messageObject.Content = element.Contenu;
        this.messageObject.Subject = element.Objet;
        this.selectedDraft = element;
        this.sendMail = true;
        return;
        break;
    }
    this.nodeJsInteractionService.getUser(element.IdExpediteur);
    this.statusToUpdate = "Lu";
    const dialogRef = this.dialog.open(ReadMessageComponent, {
      width: "auto",
      data: element,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.userToRespond.LibelleThematiqueMessage =
          element.LibelleThematiqueMessage;
        this.userToRespond.IdThematiqueMessage = element.IdThematiqueMessage;
        this.receverLabel =
          this.userToRespond.prenom + " " + this.userToRespond.nom;
        this.receverId = [this.userToRespond.ID];
        this.messageObject.Content =
          "<br/> <br/> ---------------------------------------- <br/> <div class='pl-3'>" +
          result.DateMessage +
          " <br/>" +
          result.Contenu +
          "</div>";
        this.messageObject.Subject = result.Objet;
        this.sendResponse = true;
        this.sendMail = true;
      } else {
        this.userToRespond = null;
      }
    });
  }

  sendMessageToUsers(isDraft) {
    this.messageObject.Content = this.escapeHtml(
      this.messageObject.Content !== null ? this.messageObject.Content : ""
    );
    const pad = (num) => ("00" + num).slice(-2);
    let date;
    date = new Date();
    date =
      date.getUTCFullYear() +
      "-" +
      pad(date.getUTCMonth() + 1) +
      "-" +
      pad(date.getUTCDate()) +
      "T" +
      pad(date.getHours()) +
      ":" +
      pad(date.getUTCMinutes()) +
      ":" +
      pad(date.getUTCSeconds());

    const dataMessage = {
      ID: this.selectedDraft !== null && isDraft ? this.selectedDraft.ID : 0,
      IdExpediteur: this.currentUser.ID,
      DateMessage: date,
      Objet: this.messageObject.Subject,
      Contenu: this.messageObject.Content,
      Statut: isDraft ? "Aucun" : "Non Lu",
      Brouillon: isDraft ? "1" : "0",
      IdThematiqueMessage:
        this.userToRespond !== null
          ? this.userToRespond.IdThematiqueMessage
          : 1,
      LibelleThematiqueMessage:
        this.userToRespond !== null
          ? this.userToRespond.LibelleThematiqueMessage
          : "Communication Ilévia",
      CodeErreur: 0,
      pListDestinataire: this.receverId !== null ? this.receverId : [],
    };

    this.attachOverlay();
    if (dataMessage.ID !== 0) {
      if (this.selectedDraft !== null) {
        this.nodeJsInteractionService.messageUpdate(dataMessage);
      }
    } else {
      this.nodeJsInteractionService.messageCreateWithAssociation(dataMessage);
    }
    this.messageObject.Content = this.unescapeHtml(this.messageObject.Content);
  }

  openSendMessageConfirmationDialog(): void {
    if (this.receverId !== null && this.receverId.length <= 0) {
      this.snackBar.open(
        "Il semblerait qu'il n'y ait aucun utilisateurs rattachés à la sélection de point de distribution",
        null,
        {
          duration: 3000,
          panelClass: "infos",
        }
      );
      return;
    }
    const listLength = this.receverId.length;

    if (
      this.messageObject.Subject === "" ||
      this.messageObject.Subject === null ||
      this.messageObject.Content === "" ||
      this.messageObject.Content === null
    ) {
      this.snackBar.open(
        "Veuillez saisir un objet et un contenu de mail avant de l'envoyer",
        null,
        {
          duration: 3000,
          panelClass: "infos",
        }
      );

      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data:
        "<h6>Vous êtes sur le point d'envoyer le mail vers " +
        listLength +
        " destinataires <br/>",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sendMessageToUsers(false);
      }
    });
  }

  createGroupByRows() {
    this.allData = this.dataSourceTable.data;
    this.dataSourceTable.data = this.addGroups(
      this.allData,
      this.groupByColumns
    );
    this.dataSourceTable.filterPredicate =
      this.customFilterPredicate.bind(this);
    this.dataSourceTable.filter = performance.now().toString();
  }

  isAllSelected() {
    const numSelected = this.selectionTable.selected.length;
    const numRows = this.dataSourceTable.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selectionTable.clear()
      : this.dataSourceTable.data.forEach((row) =>
          this.selectionTable.select(row)
        );
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${
      this.selectionTable.isSelected(row) ? "deselect" : "select"
    } row ${row.position + 1}`;
  }
  customFilterPredicate(data: any | Group, filter: string): boolean {
    return data instanceof Group ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: any): boolean {
    const groupRows = this.dataSourceTable.data.filter((row) => {
      if (!(row instanceof Group)) {
        return false;
      }
      let match = true;
      this.groupByColumns.forEach((column) => {
        if (!row[column] || !data[column] || row[column] !== data[column]) {
          match = false;
        }
      });
      return match;
    });

    if (groupRows.length === 0) {
      return true;
    }

    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.dataSourceTable.filter = performance.now().toString(); // bug here need to fix
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(
    data: any[],
    level: number,
    groupByColumns: string[],
    parent: Group
  ): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map((row) => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach((group) => {
      const rowsInGroup = data.filter(
        (row) => group[currentColumn] === row[currentColumn]
      );
      // A remettre si on veut une ligne normal au lieux d'un groupe quand 1 message
      // if (rowsInGroup.length > 1) {
      group.totalCounts = rowsInGroup.length;
      group.totalReads = rowsInGroup.filter((e) => e.Statut === "Lu").length;
      const subGroup = this.getSublevel(
        rowsInGroup,
        level + 1,
        groupByColumns,
        group
      );
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
      // } else {
      //     subGroups = subGroups.concat(rowsInGroup);
      // }
    });

    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    // return (item.totalCounts > 1 && item.level);
    return item.level;
  }
}
