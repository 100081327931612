import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { Location } from '@angular/common';
import { version  } from '../../../package.json' ;

@Injectable({
  providedIn: 'root'
})
export class TitleService {

    title = 'Extrapro - Back Office';
    version = '';
    currentUserLibelle = '';
    ecranNumber = 1;
    isVisibleMenu = true;

    constructor(
        public location: Location,
        //appConfigService: AppConfigService
    ) {
        this.version = version;;
    }

    setTitle(title) { this.title = title; }
    getTitle() {return this.title; }
    setEcranNumber(num) { this.ecranNumber = num; }
    getEcranNumber() {return this.ecranNumber; }
    setCurrentUserLibelle(label) { this.currentUserLibelle = label; }
    hideMenu() {this.isVisibleMenu = false; }
    showMenu() {this.isVisibleMenu = true; }
    backClicked() {
        this.location.back();
    }
}
