import { Component, OnInit, NgZone, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatDialog, MatSnackBar } from '@angular/material';
import { TitleService } from '../_service/title.service';
import { NodeJsInteractionService } from '../_service/nodejs-interaction.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { ProgressContainerComponent } from '../_ui/progress-container/progress-container.component';
import { Overlay } from '@angular/cdk/overlay';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMAT } from 'src/environments/environment';
import { TableExportService } from '../_service/table-export.service';
import { DistributionPointDialogComponent } from '../_ui/distribution-point-dialog/distribution-point-dialog.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Router } from '@angular/router';
import { UserDialogComponent } from '../_ui/user-dialog/user-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmationDialogComponent } from '../_ui/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'app-history-extracation',
    templateUrl: './history-extracation.component.html',
    styleUrls: ['./history-extracation.component.less'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },

    ]
})
export class HistoryExtracationComponent implements OnInit, OnDestroy {

    constructor(
        public titleService: TitleService,
        public nodeJsInteractionService: NodeJsInteractionService,
        public tableExportService: TableExportService,
        public overlay: Overlay,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public ngZone: NgZone,
        public router: Router
    ) {
        this.titleService.setTitle('Historiques et extractions');
        this.titleService.setEcranNumber(8);
        this.dataSourceTable = new MatTableDataSource([]);
    }
    private overlayRef;

    tableDisplayedColumns: string[] = [
        'select', 'IdRechargement', 'IdPointDistribution', 'LibellePointDistribution', 'IdUtilisateurExtraPro', 'NomUtilisateurExtraPro',
        'InstanceSVD', 'IdTransactionSvd', 'DateHeureRechargement', 'NumeroSupport', 'CodeProduit', 'LibelleTitre', 'TypeOperation',
        'QuantiteTitre', 'NumeroCommande', 'MontantTotalTTC', 'DateDebutValiditeTitre', 'DateFinValiditeTitre', 'NumeroSerieProduit'
    ];
    dataSourceTable: MatTableDataSource<any>;
    selection = new SelectionModel<any>(true, []);

    cptWsCall = 0;
    selectedDistributionPointListId;
    selectedUserListId;
    selectedPointLibelle = '';
    selectedUserLibelle = '';
    searchFilter = {
        ListePointDistribution: [],
        ListeUtilisateur: [],
        NumeroSupport: '',
        DateDebut: '',
        DateFin: '',
        CompteurVente: null
    };

    listToDiscretize = [];
    discretizeSuccessed = [];
    discreztizeFailed = [];

    @ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;

    private rgpdDiscretizationObservable;
    private getReloadHistoryObservable;
    attachOverlay() { this.overlayRef.attach(new ComponentPortal(ProgressContainerComponent)); }
    detachOverlay() { setTimeout(() => { this.overlayRef.detach(); }, 200); }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSourceTable.data.length;
        return numSelected === numRows;
    }
    masterToggle() { this.isAllSelected() ? this.selection.clear() : this.dataSourceTable.data.forEach(row => this.selection.select(row)); }
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }
    triggerResize() {
        this.ngZone.onStable.subscribe(() => this.autosize.resizeToFitContent(true));
    }
    ngOnInit() {

        if (!this.nodeJsInteractionService.functionnalityAccess.checkAccess('HISTORIQUE_ACCEDER_HISTORIQUE_RECHARGEMENT_TOUS')) {
            this.router.navigate(['/home']);
        }

        this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });

        this.getReloadHistoryObservable = this.nodeJsInteractionService.getReloadHistoryObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            if (response !== null && response.HistoriqueRechargement !== '' && response.HistoriqueRechargement !== undefined) {
                this.selection.clear();
                this.dataSourceTable.data = Array.isArray(response.HistoriqueRechargement) ?
                    response.HistoriqueRechargement.sort((a, b) => {
                        return new Date(b.DateHeureRechargement).getTime() - new Date(a.DateHeureRechargement).getTime();
                    }) : [response.HistoriqueRechargement];

                this.dataSourceTable.filterPredicate = (result: any, filtersJson: string) => {
                    const matchFilter = [];
                    const filters = JSON.parse(filtersJson);
                    filters.forEach(filter => {
                        const val = result[filter.id] === null ? '' : result[filter.id];
                        if (val === undefined) {
                            return;
                        }
                        matchFilter.push(val.toString().toLowerCase().includes(filter.value.toLowerCase()));
                    });
                    return matchFilter.some(Boolean);
                };
                this.dataSourceTable._updateChangeSubscription();
            }
        });

        this.rgpdDiscretizationObservable = this.nodeJsInteractionService.rgpdDiscretizationObservable.subscribe(data => {
            const response = data as any;
            (response === true) ?
                this.discretizeSuccessed.push(this.listToDiscretize[0]) :
                this.discreztizeFailed.push(this.listToDiscretize[0]);

            this.listToDiscretize.shift();
            if (this.listToDiscretize.length > 0) {
                this.nodeJsInteractionService.rgpdDiscretization(this.listToDiscretize[0]);
            } else if (this.discreztizeFailed.length) {
                this.detachOverlay();
                const failedList = this.discreztizeFailed.join(',');
                this.snackBar.open('Une erreur s\'est produite lors de le discrétisation des supports suivant : ' + failedList, null, {
                    duration: 5000,
                    panelClass: 'error'
                });
            } else {
                this.launchSearch(false);
                const successedList = this.discretizeSuccessed.join(',');
                this.snackBar.open('La discrétisation des supports suivant a été faite avec succèes : ' + successedList, null, {
                    duration: 3000,
                    panelClass: 'success'
                });
            }
        });
    }

    ngOnDestroy() {
        this.rgpdDiscretizationObservable.unsubscribe();
        this.getReloadHistoryObservable.unsubscribe();
    }

    launchSearch(attachOverlay) {
        if (attachOverlay) {
            this.attachOverlay();
        }
        this.cptWsCall = (this.selectedDistributionPointListId !== undefined) ? this.selectedDistributionPointListId.length : 0;
        this.dataSourceTable.data = [];
        this.dataSourceTable._updateChangeSubscription();

        const searchFilter = { ...this.searchFilter };
        const dateDebut = searchFilter.DateDebut as any;
        const dateFin = searchFilter.DateFin as any;

        searchFilter.DateDebut = (typeof dateDebut === 'string') ?
            (dateDebut === '') ? '' : dateDebut.substring(0, 10) :
            (!dateDebut) ? '' : dateDebut.format('DD/MM/YYYY');
        searchFilter.DateFin = (typeof dateFin === 'string') ?
            (dateFin === '') ? '' : dateFin.substring(0, 10) :
            (!dateFin) ? '' : dateFin.format('DD/MM/YYYY');

        if (Array.isArray(this.selectedDistributionPointListId)) {
            searchFilter.ListePointDistribution = this.selectedDistributionPointListId;
        }
        if (Array.isArray(this.selectedUserListId)) {
            searchFilter.ListeUtilisateur = this.selectedUserListId;
        }
        if(!searchFilter.CompteurVente) {
            searchFilter.CompteurVente = 0
        }
        this.nodeJsInteractionService.getReloadHistory(searchFilter);
    }
    rgpdDiscretization() {
        const supportList = this.selection.selected.map(r => r.NumeroSupport).filter((v, i, e) => e.indexOf(v) === i);
        if (supportList.length) {

            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '500px',
                data: '<h6>Êtes vous sur de vouloir discrétiser la sélection ? </h6> Il y a '
                    + supportList.length + ' supports différents de sélectionnés. <br/>'
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.attachOverlay();
                    this.listToDiscretize = [...supportList];
                    this.nodeJsInteractionService.rgpdDiscretization(this.listToDiscretize[0]);
                }
            });
        }
    }
    exportToExcel() { this.tableExportService.exportToExcel('history-extraction-content-table', 'Poste-de-distribution'); }
    openDistributionPointListDialog(): void {
        const dialogRef = this.dialog.open(DistributionPointDialogComponent, {
            width: '600px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const distributionPointList = result.filter(element => element.expandable === false);
                this.selectedDistributionPointListId = [];
                this.selectedPointLibelle = '';
                distributionPointList.forEach(element => {
                    this.selectedDistributionPointListId.push(element.ID);
                    this.selectedPointLibelle += element.Libelle + ', ';
                });
            }
        });
    }

    openUserListDialog() {
        const dialogRef = this.dialog.open(UserDialogComponent, {
            width: '800px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.selectedUserListId = [];
                this.selectedUserLibelle = '';
                result.forEach(element => {
                    this.selectedUserListId.push(element.ID);
                    this.selectedUserLibelle += element.Libelle + ', ';
                });
            }
        });
    }

    isString(value) {
        return typeof value === 'string';
    }

    applyFilter(filterValue: string) {
        const tableFilters = [];
        this.tableDisplayedColumns.forEach(key => {
            if (key === 'actions') {
                return;
            }
            tableFilters.push({
                id: key,
                value: filterValue
            });
        });
        this.dataSourceTable.filter = JSON.stringify(tableFilters);
        if (this.dataSourceTable.paginator) {
            this.dataSourceTable.paginator.firstPage();
        }
    }
}
