import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material";
@Component({
  selector: "rightless-dialog",
  templateUrl: "./rightless-dialog.component.html",
  styleUrls: ["./rightless-dialog.component.less"],
})
export class RightlessDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RightlessDialogComponent>,
  ) {}

  disconnect() {
    this.dialogRef.close("close");
  }
}
