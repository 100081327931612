import { Component, AfterViewInit, ElementRef, OnDestroy } from "@angular/core";
import { TitleService } from "./_service/title.service";
import { NodeJsInteractionService } from "./_service/nodejs-interaction.service";
import { Router } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { RightlessDialogComponent } from "./_ui/rightless-dialog/rightless-dialog.component";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  title = "ilevia-back-office";
  constructor(
    public elementRef: ElementRef,
    public titleService: TitleService,
    private router: Router,
    public nodeJsInteractionService: NodeJsInteractionService,
    private keycloakService: KeycloakService,
    public dialog: MatDialog
  ) {
    this.titleService.setTitle("Extrapro");
    
    this.keycloakService
      .isLoggedIn()
      .then(async (isLoggedIn) => {
        if (isLoggedIn) {
          try {
            /* Method 1 - get from keycloak angular built in Method */
            const iamUserExtraproIdFromRole1 = this.keycloakService
              .getUserRoles()
              .filter((role) => role.startsWith("extrapro_id_"))[0]
              .split("extrapro_id_")[1];

            console.log("iamUserExtraproIdFromRole1");
            console.log(iamUserExtraproIdFromRole1);

            /* Method 2 - get from token parsed */
            const iamUserExtraproRole = this.keycloakService
              .getKeycloakInstance()
              .tokenParsed
              .resource_access["keolis-ng-extrapro-bo"]
              .roles[0];

            const iamUserExtraproIdFromRole2 = iamUserExtraproRole.split("extrapro_id_")[1];
            console.log("iamUserExtraproIdFromRole2");
            console.log(iamUserExtraproIdFromRole2);
            if(iamUserExtraproIdFromRole1) {
              localStorage.setItem("userId", iamUserExtraproIdFromRole1);
            }
            if(!iamUserExtraproIdFromRole1 && iamUserExtraproIdFromRole2) {
              localStorage.setItem("userId", iamUserExtraproIdFromRole2);
            }
          } catch (e) {
            console.log(
              "Failed to load user details atttributes extrapro_id, ERROR:", e);
            const dialogRef = this.dialog.open(RightlessDialogComponent, {
              width: "500px",
            });
            dialogRef.afterClosed().subscribe(() => {
              this.router.navigate(['/']).then(()=>{
              this.keycloakService.logout()});
            })
          }
        }
      })
      .catch((err) => {
        console.log("keycloakService err", err);
      });
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      "#eeeeee";
  }

  ngOnDestroy() {
    localStorage.clear();
  }
}
