import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource, MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { NestedNode, DistributionPointDatabaseService, FlatNode } from '../_service/distribution-point-database.service';
import { NodeJsInteractionService } from '../_service/nodejs-interaction.service';
import { TitleService } from '../_service/title.service';
import { ProgressContainerComponent } from '../_ui/progress-container/progress-container.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import { ConfirmationDialogComponent } from '../_ui/confirmation-dialog/confirmation-dialog.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'message-thematic-create-dialog',
    templateUrl: 'message-thematic-create-dialog.html',
    styleUrls: ['./mail-theme-correspondence.component.less'],
})
export class MessageThematicCreateDialogComponent {
    messageThematicList;
    form: FormGroup;
    constructor(
        private fb: FormBuilder,
        private snackBar: MatSnackBar,
        private dialogRef: MatDialogRef<MessageThematicCreateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.messageThematicList = data.messageThematicList;
        this.form = fb.group({
            IdParent: data.IdParent,
            Libelle: [data.Libelle, [Validators.required, Validators.maxLength(150)]]
        });
    }
    save() {
        if (this.form.invalid) {
            this.snackBar.open('La saisie n\'est pas valide, veuillez recommencer', null, {
                duration: 3000,
                panelClass: 'error'
            });
        } else {
            this.dialogRef.close(this.form.value);
        }
    }
    onNoClick(): void { this.dialogRef.close(); }
}

@Component({
    selector: 'app-mail-theme-correspondence',
    templateUrl: './mail-theme-correspondence.component.html',
    styleUrls: ['./mail-theme-correspondence.component.less'],
    providers: [
        { provide: 'instance1', useClass: DistributionPointDatabaseService },
        { provide: 'instance2', useClass: DistributionPointDatabaseService }
    ]
})
export class MailThemeCorrespondenceComponent implements OnInit, OnDestroy {

    constructor(
        public titleService: TitleService,
        public nodeJsInteractionService: NodeJsInteractionService,
        @Inject('instance1') public thematicDatabase: DistributionPointDatabaseService,
        @Inject('instance2') public userDatabase: DistributionPointDatabaseService,
        public overlay: Overlay,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public router: Router
    ) {
        this.titleService.setTitle('Messagerie - Gestion des thématiques / Utilisateurs ilévia');
        this.titleService.setEcranNumber(6);


        this.thematicTreeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren as any);
        this.thematicTreeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.thematicDataSource = new MatTreeFlatDataSource(this.thematicTreeControl, this.thematicTreeFlattener);

        this.UserTreeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren as any);
        this.UserTreeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.UserDataSource = new MatTreeFlatDataSource(this.UserTreeControl, this.UserTreeFlattener);


        this.thematicDatabase.setNestedNodeKey('ID');
        this.thematicDatabase.setNestedNodeLibelle('Libelle');
        this.thematicDatabase.setFlatNodeNestedKey('IdParent');
        this.userDatabase.setNestedNodeKey('ID');
        this.userDatabase.setFlatNodeKey('ID');
        this.userDatabase.setNestedNodeLibelle(['nom', 'prenom']);


        thematicDatabase.dataChange.subscribe(data => {
            this.thematicDataSource.data = data;
        });

        userDatabase.dataChange.subscribe(data => {
            this.UserDataSource.data = data;
        });

    }
    private overlayRef;
    thematicFlatNodeMap = new Map<FlatNode, NestedNode>();
    thematicNestedNodeMap = new Map<NestedNode, FlatNode>();
    thematicSelectedParent: FlatNode | null = null;
    thematicTreeControl: FlatTreeControl<FlatNode>;
    thematicTreeFlattener: MatTreeFlattener<NestedNode, FlatNode>;
    thematicDataSource: MatTreeFlatDataSource<NestedNode, FlatNode>;
    thematicChecklistSelection = new SelectionModel<FlatNode>(true /* multiple */);

    UserFlatNodeMap = new Map<FlatNode, NestedNode>();
    UserNestedNodeMap = new Map<NestedNode, FlatNode>();
    UserSelectedParent: FlatNode | null = null;
    UserTreeControl: FlatTreeControl<FlatNode>;
    UserTreeFlattener: MatTreeFlattener<NestedNode, FlatNode>;
    UserDataSource: MatTreeFlatDataSource<NestedNode, FlatNode>;
    userChecklistSelection = new SelectionModel<FlatNode>(true /* multiple */);
    private cptWsMaj = 0;
    private selectedThematic = false;
    private assocThematicUser = [];
    private getThematicMessageList;
    private getUsers;
    private getUserThematicMessageAssociationListObservable;
    private getUserThematicMessageAssociationListOfUserObservable;
    private getUserThematicMessageAssociationList;
    private thematicMessageCreateObservable;
    private thematicMessageUpdateObservable;
    private thematicMessageDeleteObservable;
    private userThematicMessageAssociationCreateObservable;
    private userThematicMesasgeAssociationDeleteObservable;

    flatNodeMap = new Map<FlatNode, NestedNode>();
    attachOverlay() { this.overlayRef.attach(new ComponentPortal(ProgressContainerComponent)); }
    detachOverlay() { setTimeout(() => { this.overlayRef.detach(); }, 200); }

    ngOnInit() {
        if (!this.nodeJsInteractionService.functionnalityAccess.checkAccess('GESTION_THEMATIQUE')) {
            this.router.navigate(['/home']);
        }

        this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });

        this.nodeJsInteractionService.getThematicMessageList();
        this.getThematicMessageList = this.nodeJsInteractionService.getThematicMessageListObservable.subscribe(data => {
            const response = data as any;
            this.thematicDatabase.setNestedNodeList(response.ThematiqueMessage);
            this.thematicDatabase.setFlatNodeList([]);
            this.thematicDatabase.initializeNested();
        });

        this.nodeJsInteractionService.getUserList();
        this.getUsers = this.nodeJsInteractionService.getUserListObservable.subscribe(data => {
            const response = data as any;
            let listAdminstrators = [];
            if (response.Utilisateur !== undefined) {
                listAdminstrators = response.Utilisateur.filter(elem =>
                    elem.type_utilisateur === 'AdministrateurExtrapro' || elem.type_utilisateur === 'Backoffice'
                );
            }
            this.userDatabase.setNestedNodeList((Array.isArray(listAdminstrators)) ? listAdminstrators : [listAdminstrators]);
            this.userDatabase.setFlatNodeList([]);
            this.userDatabase.initialize();
        });


        this.getUserThematicMessageAssociationListOfUserObservable = this.nodeJsInteractionService
            .getUserThematicMessageAssociationListOfUserObservable.subscribe(data => {
                const response = data as any;
                if (response === '') {
                    return;
                }
                const userList = (Array.isArray(response.Utilisateur)) ? response.Utilisateur : [response.Utilisateur];
                const userListId = [];
                userList.forEach(user => {
                    const assocNode = this.userDatabase.findNode(
                        this.UserTreeControl.dataNodes,
                        user[this.userDatabase.getFlatNodeKey()]
                    );
                    this.userChecklistSelection.select(assocNode);
                    userListId.push(user.ID);
                });

                // let userListId = (Array.isArray(response.Utilisateur.ID)) ? response.Utilisateur.ID : [response.Utilisateur.ID];
                const nestedNodeList = this.userDatabase.getNestedNodeList();
                nestedNodeList.forEach(node => {
                    if (userListId.includes(node[this.userDatabase.getNestedNodeKey()])) {
                        const assocNode = this.userDatabase.findNode(
                            this.UserTreeControl.dataNodes,
                            node[this.userDatabase.getNestedNodeKey()]
                        );
                        this.userChecklistSelection.select(assocNode);
                        const parentNode = this.getParentNode(assocNode);
                        this.UserTreeControl.expand(parentNode);
                        if (!this.assocThematicUser.includes(assocNode.ID)) {
                            this.assocThematicUser.push(assocNode.ID);
                        }
                    }
                });
            });

        this.getUserThematicMessageAssociationListObservable = this.nodeJsInteractionService
            .getUserThematicMessageAssociationListObservable.subscribe(data => {
            });

        this.thematicMessageCreateObservable = this.nodeJsInteractionService.thematicMessageCreateObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            if (response.CodeErreur !== 0) {
                this.snackBar.open('Une erreur s\'est produite lors de la création de la thématique.', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
                return false;
            }
            this.snackBar.open('La thématique a bien été créée.', null, {
                duration: 3000,
                panelClass: 'success'
            });

            const nestedNodeList = this.thematicDatabase.getNestedNodeList();
            nestedNodeList.push(response);
            this.thematicDatabase.setNestedNodeList(nestedNodeList);
            this.thematicDatabase.initializeNested();
            const flatNode = this.thematicTreeControl.dataNodes.find(e => e.ID === response.ID);
            this.thematicTreeControl.expand(this.getParentNode(flatNode));
            this.detachOverlay();
        });


        this.thematicMessageUpdateObservable = this.nodeJsInteractionService.thematicMessageUpdateObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            if (response.CodeErreur !== 0) {
                this.snackBar.open('Une erreur s\'est produite lors de la mise à jour de la thématique.', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
                return false;
            }
            this.snackBar.open('La thématique à bien été mis à jour.', null, {
                duration: 3000,
                panelClass: 'success'
            });

            const libelle = this.thematicDatabase.generateFlatNodeLibelle(response);
            const nestedNodeList = this.thematicDatabase.getNestedNodeList();
            const indexOf = nestedNodeList.findIndex(element =>
                element[this.thematicDatabase.getNestedNodeKey()] === response[this.thematicDatabase.getNestedNodeKey()]
            );
            nestedNodeList[indexOf] = response;
            this.thematicDatabase.setNestedNodeList(nestedNodeList);
            this.thematicDatabase.initializeNested();
            const flatNode = this.thematicTreeControl.dataNodes.find(e => e.ID === response.ID);
            this.thematicTreeControl.expand(this.getParentNode(flatNode));
            this.detachOverlay();
        });

        this.thematicMessageDeleteObservable = this.nodeJsInteractionService.thematicMessageDeleteObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            if (response !== true) {
                this.snackBar.open('Une erreur s\'est produite lors de la suppression à de la thématique.', null, {
                    duration: 3000,
                    panelClass: 'error'
                });
                return false;
            }
            this.snackBar.open('La thématique à bien été mis à supprimée.', null, {
                duration: 3000,
                panelClass: 'success'
            });
        });

        this.userThematicMessageAssociationCreateObservable = this.nodeJsInteractionService
            .userThematicMessageAssociationCreateObservable.subscribe(data => {
                this.detachOverlay();
                const response = data as any;
                if (response !== true) {
                    this.snackBar.open('Une erreur s\'est produite lors de l\'enregistrement des informations', null, {
                        duration: 3000,
                        panelClass: 'error'
                    });
                    return false;
                }
                this.snackBar.open('Les données ont bien enregistrées.', null, {
                    duration: 3000,
                    panelClass: 'success'
                });
            });


        this.userThematicMesasgeAssociationDeleteObservable = this.nodeJsInteractionService
            .userThematicMesasgeAssociationDeleteObservable.subscribe(data => {
                this.detachOverlay();
            });

    }

    ngOnDestroy() {
        this.getThematicMessageList.unsubscribe();
        this.getUsers.unsubscribe();
        this.getUserThematicMessageAssociationListObservable.unsubscribe();
        this.getUserThematicMessageAssociationListOfUserObservable.unsubscribe();
        this.thematicMessageCreateObservable.unsubscribe();
        this.thematicMessageUpdateObservable.unsubscribe();
        this.thematicMessageDeleteObservable.unsubscribe();
        this.userThematicMessageAssociationCreateObservable.unsubscribe();
        this.userThematicMesasgeAssociationDeleteObservable.unsubscribe();
    }
    getLevel = (node: FlatNode) => node.level;
    isExpandable = (node: FlatNode) => node.expandable;
    getChildren = (node: NestedNode): FlatNode[] => node.children;
    hasChild = (_: number, nodeData: FlatNode) => nodeData.expandable;
    hasNoContent = (_: number, nodeData: FlatNode) => nodeData.Libelle === '';
    transformer = (node: NestedNode, level: number) => {
        const existingNode = this.thematicNestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.Libelle === node.Libelle
            ? existingNode
            : new FlatNode();
        flatNode.Libelle = node.Libelle;
        flatNode.ID = node.ID;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.thematicFlatNodeMap.set(flatNode, node);
        this.thematicNestedNodeMap.set(node, flatNode);
        return flatNode;
    }

    /** Save the node to database */
    saveNodeProduct() {
        this.cptWsMaj = 0;
        if (this.thematicChecklistSelection.selected[0] === undefined) {
            return false;
        }
        const checklistUserSelected = this.userChecklistSelection.selected.filter(e => e as any !== false);
        const deltaToDelete = this.assocThematicUser.filter((item) => {
            return !checklistUserSelected.find(element => element.ID === item);
        });

        this.cptWsMaj = checklistUserSelected.length + deltaToDelete.length;
        if (this.cptWsMaj === 0) {
            return false;
        }

        this.attachOverlay();
        const IdThematique = this.thematicChecklistSelection.selected[0].ID;
        checklistUserSelected.forEach(user => {
            this.nodeJsInteractionService.userThematicMessageAssociationCreate({
                pIdUtilisateur: user.ID.toString(),
                pIdThematiqueMessage: IdThematique.toString()
            });
        });

        deltaToDelete.forEach(userId => {
            this.nodeJsInteractionService.userThematicMessageAssociationDelete({
                pIdUtilisateur: userId.toString(),
                pIdThematiqueMessage: IdThematique.toString()
            });
        });
    }

    openMessageThematicCreateDialog(node) {
        const nodeData = node || null;
        let canvas = { ID: 0, IdParent: 0, Libelle: '', messageThematicList: [] };
        if (nodeData !== null) {
            const nestedList = this.thematicDatabase.getNestedNode(node[this.thematicDatabase.getNestedNodeKey()]);
            canvas = nestedList;
        }

        canvas.messageThematicList = this.thematicDatabase.getNestedNodeList();
        const dialogRef = this.dialog.open(MessageThematicCreateDialogComponent, {
            width: '600px',
            data: canvas,
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== false && result !== undefined) {
                result.ID = canvas.ID;
                result.CodePicto = '';
                result.CodeErreur = 0;
                if (result.ID !== 0) {
                    this.nodeJsInteractionService.thematicMessageUpdate(result);
                } else {
                    this.nodeJsInteractionService.thematicMessageCreate(result);
                }
            }
        });
    }

    openDeleteMessageThematicDialog(node) {

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: '<h6>Êtes vous sur de vouloir supprimer la thématique ? </h6> '
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.removeMessageThematicNode(node);
            }
        });
    }

    /** Remove the node from database */
    removeMessageThematicNode(node: FlatNode) {
        if (node.level === 0) {
            const parentNode = this.thematicFlatNodeMap.get(node);
            this.thematicDatabase.removeNestedItem(parentNode);
            this.nodeJsInteractionService.thematicMessageDelete(parentNode.ID);
        } else {
            const parentNode = this.thematicFlatNodeMap.get(this.getParentNode(node));
            this.thematicDatabase.removeFlatItem(parentNode, node);
            this.nodeJsInteractionService.thematicMessageDelete(node.ID);
        }

        const nestedNode = this.thematicDatabase.getNestedNodeList();
        const indexOf = nestedNode.indexOf(this.thematicDatabase.findNode(node, node.ID));
        nestedNode.splice(indexOf, 1);
        this.thematicDatabase.setNestedNodeList(nestedNode);
    }


    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: FlatNode): boolean {
        const descendants = this.thematicTreeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.thematicChecklistSelection.isSelected(child)
        );
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: FlatNode): boolean {
        const descendants = this.thematicTreeControl.getDescendants(node);
        const result = descendants.some(child => this.thematicChecklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: FlatNode): void {
        let parent: FlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: FlatNode): void {
        const nodeSelected = this.thematicChecklistSelection.isSelected(node);
        const descendants = this.thematicTreeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.thematicChecklistSelection.isSelected(child)
        );
        if (nodeSelected && !descAllSelected) {
            this.thematicChecklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.thematicChecklistSelection.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: FlatNode): FlatNode | null {
        const currentLevel = this.getLevel(node);
        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.thematicTreeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.thematicTreeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelectedThematic(node: FlatNode): boolean {
        const descendants = this.thematicTreeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.thematicChecklistSelection.isSelected(child)
        );
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelectedThematic(node: FlatNode): boolean {
        const descendants = this.thematicTreeControl.getDescendants(node);
        const result = descendants.some(child => this.thematicChecklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggleThematic(node: FlatNode): void {
        this.userChecklistSelection.clear();
        if (this.thematicChecklistSelection.selected.includes(node)) {
            this.thematicChecklistSelection.toggle(node);
            this.thematicChecklistSelection.clear();
        } else {
            this.thematicChecklistSelection.clear();
            this.thematicChecklistSelection.toggle(node);
            this.nodeJsInteractionService.getUserThematicMessageAssociationListOfUser(node.ID);
        }
        const descendants = this.thematicTreeControl.getDescendants(node);
        const parentNode = this.getParentNode(node);
        this.thematicChecklistSelection.deselect(parentNode);
        this.thematicChecklistSelection.deselect(...descendants);
        descendants.every(child =>
            this.thematicChecklistSelection.deselect(child),
        );
    }


    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggleUser(node: FlatNode): void {
        this.nodeJsInteractionService.getUserThematicMessageAssociationList(node.ID);
        this.userChecklistSelection.toggle(node);
    }
}
