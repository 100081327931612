import { Component, OnInit, NgZone, ViewChild, OnDestroy, } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ProgressContainerComponent } from '../_ui/progress-container/progress-container.component';
import { MY_FORMAT } from 'src/environments/environment';
import { TitleService } from '../_service/title.service';
import { NodeJsInteractionService } from '../_service/nodejs-interaction.service';
import { TableExportService } from '../_service/table-export.service';
import { DistributionPointDialogComponent } from '../_ui/distribution-point-dialog/distribution-point-dialog.component';



@Component({
    selector: 'app-sales-journal',
    templateUrl: './sales-journal.component.html',
    styleUrls: ['./sales-journal.component.less'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT }
    ]
})
export class SalesJournalComponent implements OnInit, OnDestroy {
    constructor(
        public titleService: TitleService,
        public nodeJsInteractionService: NodeJsInteractionService,
        public tableExportService: TableExportService,
        public overlay: Overlay,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public ngZone: NgZone,
        public router: Router
    ) {
        this.titleService.setTitle('Journal des ventes');
        this.titleService.setEcranNumber(15);

        this.backClickedInitState = this.titleService.backClicked;
        this.titleService.backClicked = () => {
            if (this.displayDetailJournal) {
                this.displayDetailJournal = false;
                this.displaySearch = true;
            } else if (this.displaySearch) {
                this.displaySearch = false;
            } else {
                this.titleService.location.back();
            }
        };
    }
    private overlayRef;
    tableDisplayedColumns: string[] = ['LibellePointDistribution', 'UitlisateurExtrapro', 'date_debut', 'date_fin'];
    dataSourceTable: MatTableDataSource<any>;

    displaySearch = false;
    displayJournal = false;
    displayDetailJournal = false;
    displayDetailCurrentElement;
    detailJournal = null;
    selectedDistributionPointListId = [];
    selectedPointLibelle = '';
    backClickedInitState;
    heureModel = {
        HeureDebut: '08:00',
        HeureFin: '20:00'
    };

    searchFilter = {
        ListePointDistribution: [],
        DateDebut: '',
        DateFin: ''
    };

    getSessionListSummaryObservable;
    getSalesJournalObservable;
    getWorkstationObservable;
    attachOverlay() { this.overlayRef.attach(new ComponentPortal(ProgressContainerComponent)); }
    detachOverlay() { setTimeout(() => { this.overlayRef.detach(); }, 200); }
    ngOnInit() {
        this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });

        this.getSessionListSummaryObservable = this.nodeJsInteractionService.getSessionListSummaryObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            if (response !== null && response['diffgr:diffgram'] !== undefined &&
                response['diffgr:diffgram'].DocumentElement !== undefined) {
                const responseDataContent = response['diffgr:diffgram'].DocumentElement;
                if (responseDataContent.Data !== '') {
                    const list = (Array.isArray(responseDataContent.Data)) ? responseDataContent.Data : [responseDataContent.Data];
                    list.sort((a, b) => new Date(b.date_fin).getTime() - new Date(a.date_fin).getTime());
                    this.dataSourceTable = new MatTableDataSource(list);
                    this.displaySearch = true;
                }
            } else {
                this.snackBar.open('Aucun résultat à afficher', null, {
                    duration: 3000,
                    panelClass: 'infos'
                });
            }
        });

        this.getSalesJournalObservable = this.nodeJsInteractionService.getSalesJournalObservable.subscribe(data => {
            const response = data as any;
            if (response === null) {
                this.detachOverlay();
                return null;
            }
            this.nodeJsInteractionService.getWorkstation(response.IdPoste);
            this.detailJournal = response;
            if (this.detailJournal.Ventes !== undefined) {
                this.detailJournal.Ventes = (Array.isArray(this.detailJournal.Ventes.Vente)) ? this.detailJournal.Ventes.Vente :
                    (this.detailJournal.Ventes.Vente !== undefined) ? [this.detailJournal.Ventes.Vente] : [];
            }
            this.displayDetailJournal = true;
        });

        this.getWorkstationObservable = this.nodeJsInteractionService.getWorkstationObservable.subscribe(data => {
            this.detachOverlay();
            const response = data as any;
            this.displayDetailCurrentElement.LibellePoste = response.Libelle;
        });
    }

    ngOnDestroy() {
        this.getSessionListSummaryObservable.unsubscribe();
        this.getSalesJournalObservable.unsubscribe();
        this.getWorkstationObservable.unsubscribe();
        this.titleService.backClicked = this.backClickedInitState;
    }

    openDistributionPointListDialog(): void {
        const dialogRef = this.dialog.open(DistributionPointDialogComponent, {
            width: '600px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const distributionPointList = result.filter(element => element.expandable === false);
                this.selectedDistributionPointListId = [];
                this.selectedPointLibelle = '';
                distributionPointList.forEach(element => {
                    this.selectedDistributionPointListId.push(element.ID);
                    this.selectedPointLibelle += element.Libelle + ', ';
                });
            }
        });
    }

    launchSearch() {

        if (!this.searchFilter.DateDebut && !this.searchFilter.DateFin) {
            this.snackBar.open('Veuillez renseigner une date de début et une date de fin.', null, {
                duration: 3000,
                panelClass: 'infos'
            });
            return false;
        }

        if (!this.searchFilter.DateDebut) {
            this.snackBar.open('Veuillez renseigner une date de début.', null, {
                duration: 3000,
                panelClass: 'infos'
            });
        } else if (!this.searchFilter.DateFin) {
            this.snackBar.open('Veuillez renseigner une date de fin.', null, {
                duration: 3000,
                panelClass: 'infos'
            });
        } else {

            const dateFin = this.searchFilter.DateFin as any;
            const dateDebut = this.searchFilter.DateDebut as any;
            this.searchFilter.DateFin = (typeof dateFin === 'string') ?
                (dateFin === '') ? '' : dateFin.substring(0, 10) :
                (!dateFin) ? '' : dateFin.format('YYYY-MM-DD');
            this.searchFilter.DateDebut = (typeof dateDebut === 'string') ?
                (dateDebut === '') ? '' : dateDebut.substring(0, 10) :
                (!dateDebut) ? '' : dateDebut.format('YYYY-MM-DD');

            this.searchFilter.ListePointDistribution = this.selectedDistributionPointListId;

            if (this.heureModel.HeureDebut && this.heureModel.HeureFin) {
                this.searchFilter.DateDebut = this.searchFilter.DateDebut.substring(0, 10) + 'T' + this.heureModel.HeureDebut + ':00';
                this.searchFilter.DateFin = this.searchFilter.DateFin.substring(0, 10) + 'T' + this.heureModel.HeureFin + ':00';
            }

            this.attachOverlay();
            this.nodeJsInteractionService.getSessionListSummary(this.searchFilter);
        }
    }

    displayDetail(element) {
        this.attachOverlay();
        this.displayDetailCurrentElement = element;
        this.nodeJsInteractionService.getSalesJournal(element.IdSession.toString());
    }
    leaveDisplayDetail() {
        this.displayJournal = false;
        this.displayDetailJournal = false;
        this.detailJournal = null;
    }

    exportToExcel() { this.tableExportService.exportToExcel('sales-journal-content-table', 'Journal de vente'); }

}
