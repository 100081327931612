import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AppConfigService } from './_service/app-config.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard implements CanActivate {

    constructor(protected keycloakAngular: KeycloakService,
                protected router: Router,
                private appConfig: AppConfigService) {
        super(router, keycloakAngular);
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return new Promise((resolve, reject) => {
            if (!this.authenticated && !this.appConfig.config.iamBypass) {
                this.keycloakAngular.login().catch(e => console.error(e));
                return reject(false);
            }

            const requiredRoles: string[] = route.data.roles;

            if (!requiredRoles || requiredRoles.length === 0) {
                return resolve(true);
            } else {
                const hasRequiredRoles = this.roles && this.roles.length > 0 &&
                    requiredRoles.every(role => this.keycloakAngular.isUserInRole(role));

                resolve(hasRequiredRoles);

                if (!hasRequiredRoles) {
                    this.redirectTo404();
                }
            }
        });
    }

    redirectTo404() {
        this.router.navigateByUrl('/404');
        return false;
    }

}
