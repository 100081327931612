import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

import { AppConfigService } from './_service/app-config.service';

@Injectable({
    providedIn: 'root'
})
export class SocketService extends Socket {
    constructor(private appConfig: AppConfigService) {
        super({ url: appConfig.config.socketUrl });
    }
}
